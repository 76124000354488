<template>
<article>
    <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowDialog }">
        <div class="modal-background"></div>
        <div class="modal-card scrollable" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Shift Lottery Scan
                    <b-tooltip position="is-bottom" class="is-info" multilined>
                        <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>  
                        <template v-slot:content>
                            <div>
                                Scan barcode like below
                                <br>
                                <img src="/images/lottery-barcode-sample.png">
                            </div>
                        </template>
                    </b-tooltip>
                </p>                 
                <button class="delete is-danger" type="button" @click="closeDialog">Cancel</button>
            </header>
            <section class="modal-card-body">
                <form @submit.prevent="submitThisForm">
                    <div class="columns is-mobile">
                        <div class="column is-8">
                            <div v-show="inputMethod == 'Scan Book Bar Code'">                                    
                                <b-field>
                                    <b-input class="scanInput"
                                        label="Scan Bar Code"
                                        v-model="scannedBookBarCode" 
                                        ref="scannedBookBarCode"
                                        v-focus
                                        icon-pack="fas"
                                        icon="barcode"
                                        @keyup.native.enter="processScannedInput"
                                        @focus="$event.target.select()"
                                        placeholder="Scan Instant Ticket Bar Code">
                                        >
                                    </b-input>
                                </b-field>                                     
                            </div>
                        </div>

                        <div class="column is-2">
                            <button class="button is-success" type="button" @click="closeDialog">Close</button>
                        </div>                           
                            
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="has-text-centered has-text-info"><i class="fas fa-bell has-text-info"></i>
                             Remember to close completed books<br>
                                ({{ storeState.countryCode }}-{{ storeState.code }})
                                {{ this.stateBarCodeFormat.Game }}/{{ this.stateBarCodeFormat.Book }}/{{ this.stateBarCodeFormat.Ticket }}
                             </div> 
                        </div>
                    </div>
                </form>                                                     

                <div class="columns">
                    <div class="column">
                        <b-table class="control is-mobile"
                            :row-class="(row, index) => row.lotteryBookSale.ticketsSold > 0 ? 'is-hidden' : ''"
                            :data='lotteryBooksOnSale'
                            :paginated="isPaginated"
                            :per-page="perPage"
                            :sticky-header="stickyHeaders"
                            paginationPosition="both"
                            sortIcon="arrow-up"
                            sortIconSize="is-small"
                            default-sort="saleRackLoc"
                            defaultSortDirection='asc'
                            >
                            <b-table-column
                                class="is-1"
                                v-slot="props"
                                field="saleRackLoc" 
                                label="Bin#" 
                                sortable sorticon>
                                <span :class="{'has-text-grey-light': props.row.status == 'RETURNED'}">
                                    {{ props.row.saleRackLoc }}
                                </span>      
                            </b-table-column>
                            <b-table-column 
                                v-slot="props"
                                field="gameNumber" 
                                label="Game & Book" 
                                sortable sorticon>
                                <span :class="{'has-text-grey-light': props.row.status == 'RETURNED'}">
                                    {{ props.row.gameNumber }}-{{ props.row.bookNumber }}
                                </span>      
                            </b-table-column>
                            <b-table-column
                                numeric
                                v-slot="props"
                                field="lotteryBookSale.startingSeq" 
                                label="Start #" 
                                sortable sorticon>
                                <span :class="{'has-text-grey-light': props.row.status == 'RETURNED'}">
                                    {{ props.row.lotteryBookSale.startingSeq }}
                                </span>      
                            </b-table-column>
                            <b-table-column
                                numeric
                                v-slot="props"
                                field="lotteryBookSale.endingSeq" 
                                label="End #" 
                                sortable sorticon>
                                <span :class="{'has-text-grey-light': props.row.status == 'RETURNED'}">
                                    {{ props.row.lotteryBookSale.endingSeq }}
                                </span>      
                            </b-table-column>                            
                            <b-table-column 
                            numeric class="is-2" field="gameNumber" label="Sold" v-slot="props" sortable sorticon>
                            <span :class="highlightClass(props.row)">
                            {{ props.row.lotteryBookSale.ticketsSold }} 
                            </span>
                            </b-table-column>
                            <b-table-column class="is-2" label="" v-slot="props" sortable sorticon>
                                <b-field  v-if="props.row.status != 'RETURNED'">
                                    <b-switch :class="highlightClass(props.row)"
                                        @input="closeBook(lotteryBooksOnSale.indexOf(props.row))" 
                                        true-value="CLOSED"
                                        false-value="ONSALE"
                                        size="is-small"
                                        :disabled="props.row.lastTicketSold > props.row.lotteryBookSale.endingSeq || (props.row.status != 'ONSALE' && props.row.status != 'CLOSED')"
                                        v-model="props.row.status"
                                        type="is-success">
                                            Closed
                                    </b-switch>
                                </b-field>
                                <span class='has-text-grey-light' v-else>{{ props.row.status }}</span>
                            </b-table-column>                            
                        </b-table>
                    </div>
                </div>

                <shift-lottery-scan-new
                    :book="book"
                    :lotteryBooksInStock="lotteryBooksInStock"
                    :lotteryBooksOnSale="lotteryBooksOnSale"
                    :lotteryBins="lotteryBins"
                    :salesId="salesId"
                    :action="action"
                    :isShowDialog="isShowNewTicketForm"  
                    @hide-dialog="toggleNewTicketForm"
                    @save-record="saveNewTicketForm">
                </shift-lottery-scan-new>

            </section>
        </div>
    </div>
</article>    
</template>

<script>

import ShiftLotteryScanNew from './ShiftLotteryScanNew'

export default {

    components: {
        ShiftLotteryScanNew
    },

    props: {
        canCancel: String, 
        isShowDialog: Boolean,
        storeId: Number,
        salesId: Number,
        id: Number,
        action: String,
        lotteryBins: [],
        lotteryBooksOnSale: [],
        lotteryBooksInStock: [],
        stateBarCodeFormat: {},
        storeState: {},             
    },

    data() {
        return {
            inputMethod: "Scan Book Bar Code",
            scannedBookBarCode: "",
            isClosable: false,
            book: {},
            gameNumber: "",
            bookNumber: "",
            ticketNumber: "",
            isPaginated: false,
            perPage: 10,
            stickyHeaders: true,
            notificationDuration: 2000,
            isShowNewTicketForm: false,
            lotterySaleDirection: (this.getSetting('lotteryInsideSaleDirection') == 1 ? "Last to Zero" : "Zero to Last"),
            soundBookFoundOnSale: new Audio('/sounds/ScanBookFoundOnSale.mp3'),
            soundBookFoundInSafe: new Audio('/sounds/ScanBookFoundInSafe.mp3'),
            soundBookNotFoundOnSaleOrInSafe: new Audio('/sounds/ScanBookNotFoundOnSaleOrInSafe.mp3'),
            soundBookEndingSequenceLess: new Audio('/sounds/ScanBookEndingSequenceLess.mp3'),
            scannedBooks: []
        }
    },

    directives: {
        focus: {
            // directive definition
            inserted: function (el) {
                el.focus()
            }
        }
    },

    methods: {
        closeDialog() {

            this.$emit('hide-dialog')

        },

        closeBook(row) {

            this.$emit('close-book',row)

        },

        submitLotteryTicketsForm() {
            // submit
            this.$emit('save-record')
        },
        
        toggleNewTicketForm() {     
                  
            this.isShowNewTicketForm = !this.isShowNewTicketForm
        },

        saveNewTicketForm() {
            this.$emit('add-book-record',this.book) 
            this.isShowNewTicketForm = !this.isShowNewTicketForm

        },

        findBookOnSale() {
            return this.lotteryBooksOnSale.find(book => {
                if (book.gameNumber == this.gameNumber &&
                    book.bookNumber == this.bookNumber 
                    ) {
                        return book
                    } else {
                        return null
                    }
            })
        },

        findBookInSafe() {

            return this.lotteryBooksInStock.find(book => {
                if (book.gameNumber == this.gameNumber &&
                    book.bookNumber == this.bookNumber 
                    ) {
                        return book
                    } else {
                        return null
                    }
            })
        },

        processScannedInput() {
            // var ticketDigitCount = this.stateBarCodeFormat.Game + this.stateBarCodeFormat.Book + 3

            // if (this.scannedBookBarCode.length !== ticketDigitCount ) {
            if (this.scannedBookBarCode.length < 13) {
                this.soundBookEndingSequenceLess.play()
                this.$buefy.toast.open({
                    message: "Invalid Scan " + this.scannedBookBarCode + '. Please try again.',
                            type: 'is-danger'
                })
                this.scannedBookBarCode = ""
            }

            if (this.scannedBookBarCode != "") {

                this.scannedBookBarCode = this.scannedBookBarCode.replace('-', '')

                this.gameNumber = this.scannedBookBarCode.substring(0,this.stateBarCodeFormat.Game)
                this.bookNumber = this.scannedBookBarCode.substring(this.stateBarCodeFormat.Game,this.stateBarCodeFormat.Game+this.stateBarCodeFormat.Book)
                this.ticketNumber = this.scannedBookBarCode.substring(this.stateBarCodeFormat.Game+this.stateBarCodeFormat.Book,this.stateBarCodeFormat.Game+this.stateBarCodeFormat.Book+3)

                if (isNaN(parseFloat(this.ticketNumber))) {
                    this.bookScanNotAllowed("Invalid Ticket Scanned")
                    this.ticketNumber = this.book.lotteryBookSale.startingSeq
                }

                this.book = this.findBookOnSale()
                
                if (this.book) {

                    if (this.book.lastTicketSold > this.book.lotteryBookSale.endingSeq || (this.book.status != 'ONSALE' && this.book.status != 'CLOSED')) {
                        this.bookScanNotAllowed("<p class='has-text-white'>Sale of ticket from this book found in future Shift.</p><p class='has-text-white is-4'>Scan not allowed for this book in this shift.</p>")
                        return
                    }

                    if (this.lotterySaleDirection == "Last to Zero") {
                        if (parseInt(this.ticketNumber) > this.book.lotteryBookSale.startingSeq) {
                            this.bookScanNotAllowed("Scanned ticket was sold on a previous shift.")
                            return
                        }  
                    } else {
                        if (parseInt(this.ticketNumber) < this.book.lotteryBookSale.startingSeq) {
                            this.bookScanNotAllowed("Scanned ticket was sold on a previous shift.")
                            return
                        }                         
                    }

                    // if (parseInt(this.ticketNumber) < this.book.lotteryBookSale.startingSeq) {
                    //     this.bookScanNotAllowed("Scanned ticket was sold on a previous shift.")
                    //     // this.ticketNumber = this.book.lotteryBookSale.startingSeq     
                    // } else {
                        if (this.book.status == "ONSALE") {
                            this.soundBookFoundOnSale.play()
                            this.$buefy.toast.open({
                                message: 'Processing: Game: ' + this.gameNumber + ' Book: ' + this.bookNumber + ' Next Ticket: '+ this.ticketNumber,
                                type: 'is-success'
                            })

                            if (this.lotterySaleDirection == "Last to Zero") {
                                this.book.lotteryBookSale.endingSeq = parseInt(this.ticketNumber)
                                this.book.lotteryBookSale.ticketsSold = this.book.lotteryBookSale.startingSeq - this.book.lotteryBookSale.endingSeq
                            } else {
                                this.book.lotteryBookSale.endingSeq = parseInt(this.ticketNumber)
                                this.book.lotteryBookSale.ticketsSold = this.book.lotteryBookSale.endingSeq - this.book.lotteryBookSale.startingSeq
                            }
                            

                            // if (this.lotterySaleDirection == "Last to Zero") {
                            //     this.book.lotteryBookSale.ticketsSold = this.book.lotteryBookSale.startingSeq - this.book.lotteryBookSale.endingSeq
                            // } else {
                            //     this.book.lotteryBookSale.ticketsSold = this.book.lotteryBookSale.endingSeq - this.book.lotteryBookSale.startingSeq
                            // }

                            // this.book.lastTicketSold = parseInt(this.ticketNumber)
                        } else {
                            this.bookScanNotAllowed("<p class='has-text-white'>Closed book cannot be scanned.</p><p class='has-text-white is-4'>Scan not allowed for this book in this shift.</p>")
                            return                            
                        }

                    // }

                } else {

                    this.book = this.findBookInSafe()
                    
                    if (this.book) {
                        if (this.book.lastTicketSold == null) {
                            this.book.lastTicketSold = 0
                        }
                        // this.book.lastTicketSold = parseInt(this.ticketNumber)
                        this.book.startSalesId = this.salesId
                        this.book.status = "ONSALE"
                        this.book.location = "R"
                        this.book.lotteryBookSale = {}
                        this.book.lotteryBookSale.salesId = this.salesId
                        this.book.lotteryBookSale.bookId = this.book.id
                        this.book.lotteryBookSale.startingSeq = 0
                        // this.book.lotteryBookSale.endingSeq = parseInt(this.ticketNumber)
                        if (this.lotterySaleDirection == "Last to Zero") {
                            this.book.lotteryBookSale.endingSeq = this.book.ticketCount - parseInt(this.ticketNumber)
                        } else {
                            this.book.lotteryBookSale.endingSeq = parseInt(this.ticketNumber)
                        }

                        if (this.lotterySaleDirection == "Last to Zero") {
                            this.book.lotteryBookSale.ticketsSold = this.book.lotteryBookSale.startingSeq - this.book.lotteryBookSale.endingSeq
                        } else {
                            this.book.lotteryBookSale.ticketsSold = this.book.lotteryBookSale.endingSeq - this.book.lotteryBookSale.startingSeq
                        }

                        // this.book.lotteryBookSale.ticketsSold = this.book.lotteryBookSale.endingSeq - this.book.lotteryBookSale.startingSeq
                        this.soundBookFoundInSafe.play()
                        this.toggleNewTicketForm()                      
                    } else {

                        this.bookScanNotAllowed("<p class='has-text-white'>Book not found in Inside Sale or In Safe Inventory.</p><p class='has-text-white is-4'>Please confirm all books delivered are scanned to inventory first.</p>")


                    }
                }
                this.scannedBookBarCode=''
                this.$refs.scannedBookBarCode.focus()

            }

        },

        bookScanInvalidTicket(message) {
            this.soundBookEndingSequenceLess.play()
            this.book = {}
            this.scannedBookBarCode='' 
            if (message != "") {
                //
            }         
            this.$buefy.toast.open({
                message: '<p class="has-text-weight-bold">Scanned: ' + this.gameNumber + '-' + this.bookNumber + '-'+ this.ticketNumber + "</p><br>" + message ,
                        type: 'is-danger'
            })

        },

        bookScanNotAllowed(message) {
            this.soundBookNotFoundOnSaleOrInSafe.play()
            this.book = {}
            this.scannedBookBarCode=''
            if (message != "") {
                //
            }                 
            this.$buefy.toast.open({
                duration: 7000,
                message: 'BOOK NOT ALLOWED!<br>Game: ' + this.gameNumber + ' Book: ' + this.bookNumber + ' Next Ticket: '+ this.ticketNumber + '<hr>' + message ,
                    type: 'is-danger'
            })                      
        },

        setFocusForScan() {
            this.$refs.scannedBookBarCode.focus()
        },

        submitThisForm() {
            // Submit Form
        },

        highlightClass2(row) {

            if (row.lotteryBookSale.ticketsSold > 0) {
                return "is-hidden"
            }
            if (row.lotteryBookSale.ticketsSold < 0) {
                return "danger"
            }
            if (row.status == "RETURNED") {
                return 'has-text-grey-light'
            }            
            return "default"

        },

        highlightClass(row) {
            if (row.lotteryBookSale.ticketsSold > 0) {
                return "success"
            }
            if (row.lotteryBookSale.ticketsSold < 0) {
                return "danger"
            }            
            return "default"
        },

    },

    computed: {
        isSaveDisabled() {
            return false
        },

        isUpdateAllowed() {
            // :disabled="props.row.lastTicketSold > props.row.lotteryBookSale.endingSeq || (props.row.status != 'ONSALE' && props.row.status != 'CLOSED')"
            return false
        }       
    },

    updated: function() {
        this.$refs.scannedBookBarCode.focus()
    },    

    mount() {
        
    }

}

</script>

<style>
.success {
    color: #00ad00;
}
.danger {
    color: rgb(223, 20, 20);
}
.default {
    color: black;
}

.scanInput  input[type=text] {
    border: 2px solid red;
    border-radius: 4px;
    background: lightgoldenrodyellow;
}

.scan-head {
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
    height: fit-content;
    width: -moz-fit-content;
}
</style>

