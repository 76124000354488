<template>
<div>
<div class="panel" ref="lotteryContent">
    <div class="is-size-5 has-text-centered has-text-weight-bold" v-if="isPrinting">
        DATE: &nbsp;&nbsp;{{ shiftDate | formatDate }} - SHIFT:  {{ shiftNumber(index) }}
    </div> 
    <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
        <div class="columns is-mobile">

            <div class="level-left has-text-centered" @click="showLotteryTable=!showLotteryTable">
                &nbsp;&nbsp;&nbsp;&nbsp;
                <i class="fas fa-plus-square has-text-primary" v-if="!showLotteryTable && !isPrinting"></i>
                <i class="fas fa-minus-square has-text-danger" v-if="showLotteryTable && !isPrinting"></i>
            </div>           
            <div class="column is-size-6 has-text-centered"> INSTANT &nbsp;&nbsp;LOTTERY </div>
                <div class="level-right has-text-centered">
                    <div class="columns">
                        <div class="column">
                            <div class="button is-primary has-text-white is-small"  @click="doAction('scan',index)" v-if="allowEdit && !isPrinting">
                                <i class="fas fa-barcode"></i>&nbsp;&nbsp;Scan
                            </div>
                        </div>
                        <div class="column">
                            <div class="button is-primary has-text-white is-small"  @click="doAction('add',index)" v-if="allowEdit && !isPrinting">
                                <i class="fas fa-file-invoice-dollar"></i>&nbsp;&nbsp;New
                            </div>
                        </div>
                        <div class="column  is-hidden-mobile">
                            <b-button  title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="togglePDFDialog">
                                <i class="fas fa-print"></i>
                            </b-button>
                            <!-- <b-button class="is-dark is-small" v-if="!isPrinting" @click="togglePDFDialog">Print</b-button> -->
                        </div>                    
                    </div>
                </div> 
            </div>
    </div>
    <div class="panel-block">

        <b-table class="control" v-if="shift.lotteryBooksOnSale && (showLotteryTable || isPrinting)"
            :visible="!isLoading"
            :striped="isStriped"
            :data='shift.lotteryBooksOnSale'
            :scrollable="!isPrinting"
            :paginated="isPaginated && !isPrinting"
            :per-page="perPage"
            :sticky-header="stickyHeaders"
            :height="tableHeight"
            paginationPosition="both"
            default-sort="saleRackLoc"
            default-sort-direction="asc"
            :show-detail-icon="showDetailIcon"          
            >

            <template>

                <b-table-column field="saleRackLoc" label="Bin" v-slot="props" sortable sorticon>
                <span :class="{'has-text-grey-light': props.row.status == 'RETURNED'}">{{ props.row.saleRackLoc }}</span>
                </b-table-column>

                <b-table-column field="gameNumber" label="Game" v-slot="props" sortable sorticon :searchable="!isPrinting">
                <span :class="{'has-text-grey-light': props.row.status == 'RETURNED'}">{{ props.row.gameNumber }}</span>
                </b-table-column>

                <b-table-column field="bookNumber" label="Book" v-slot="props" sortable sorticon :searchable="!isPrinting" >
                <span :class="{'has-text-grey-light': props.row.status == 'RETURNED'}">{{ props.row.bookNumber }}</span>
                </b-table-column>

                <b-table-column numeric field="lastTicketSold" label="Last Sold#" v-slot="props" v-if="lotterySaleDirection == 'Zero to Last'">
                <span :class="{'has-text-grey-light': props.row.status == 'RETURNED'}">{{ props.row.lastTicketSold }}</span>
                </b-table-column>

                <b-table-column numeric field="lastTicketSold" label="Last Sold#" v-slot="props" v-if="lotterySaleDirection == 'Last to Zero'">
                <span :class="{'has-text-grey-light': props.row.status == 'RETURNED'}">{{ (props.row.lastTicketSold ? props.row.lastTicketSold : props.row.ticketEndNum) }}</span>
                </b-table-column>                

                <b-table-column numeric field="startingSeq" label="Start#" v-slot="props" v-if="lotterySaleDirection == 'Zero to Last'">
                <span :class="{'has-text-grey-light': props.row.status == 'RETURNED'}">
                    {{  props.row.lotteryBookSale.startingSeq }}
                </span>
                </b-table-column>

                <b-table-column numeric field="startingSeq" label="Start#" v-slot="props" v-if="lotterySaleDirection == 'Last to Zero'">
                <span :class="{'has-text-grey-light': props.row.status == 'RETURNED'}">
                    {{ props.row.lotteryBookSale.startingSeq }}
                </span>
                </b-table-column>    

                <!-- <b-table-column numeric field="saleRackLoc" label="Start#" v-slot="props">
                <span :class="{'has-text-grey-light': props.row.status == 'RETURNED'}">{{ props.row.lotteryBookSale.startingSeq }}</span>
                </b-table-column> -->

                <b-table-column  field="endingSeq" label="Next#" v-slot="props" v-if="lotterySaleDirection == 'Zero to Last' && allowEdit">

                    <b-field v-if="!isPrinting">
                        <b-numberinput placeholder="Seq#" compact
                            v-model="props.row.lotteryBookSale.endingSeq"
                            class="lottery-seq-input"
                            size="is-small"
                            :disabled="isEndingSeqDisabled(shift.lotteryBooksOnSale.indexOf(props.row))"
                            :min="props.row.lotteryBookSale.startingSeq"
                            :max="props.row.ticketEndNum"
                            :controls="showNumberControls"
                            controls-alignment="center"
                            controls-position="compact"
                            controls-rounded
                            @focus="$event.target.select()"
                            @wheel.native="$event.target.blur()" 
                            @input="reCalculateLottery(shift.lotteryBooksOnSale.indexOf(props.row))" 
                            >
                        </b-numberinput>
                    </b-field>
                    <div class="has-text-right" v-if="isPrinting">
                        {{ props.row.lotteryBookSale.endingSeq }}
                    </div>
                </b-table-column>

                <b-table-column  field="endingSeq" label="Next#" v-slot="props" v-if="lotterySaleDirection == 'Last to Zero' && allowEdit">

                    <b-field v-if="!isPrinting">
                        <!-- :disabled="isEndingSeqDisabled(shift.lotteryBooksOnSale.indexOf(props.row))" -->
                        <!-- :min="props.row.ticketEndNum"
                            :max="props.row.lotteryBookSale.startingSeq" -->
                            <!-- :disabled="isBeginSeqDisabled(shift.lotteryBooksOnSale.indexOf(props.row))"
                            :min="0"
                            :max="(props.row.savedTicketEndNum ? props.row.savedTicketEndNum : props.row.ticketEndNum)" -->
                        <b-numberinput placeholder="Seq#" compact
                            v-model="props.row.lotteryBookSale.endingSeq"
                            class="lottery-seq-input"
                            size="is-small"
                            :min="0"
                            :max="(props.row.savedTicketEndNum > 0 ? props.row.savedTicketEndNum : props.row.ticketEndNum)"
                            :controls="showNumberControls"
                            controls-alignment="center"
                            controls-position="compact"
                            controls-rounded
                            @focus="$event.target.select()"
                            @wheel.native="$event.target.blur()" 
                            @input="reCalculateLottery(shift.lotteryBooksOnSale.indexOf(props.row))" 
                            >
                        </b-numberinput>                        
                    </b-field>
                    <div class="has-text-right" v-if="isPrinting">
                        {{ props.row.lotteryBookSale.endingSeq }}
                    </div>
                </b-table-column>

                <b-table-column numeric field="saleRackLoc" label="Start#" v-slot="props" v-if="lotterySaleDirection == 'Zero to Last' && !allowEdit">
                    <span :class="{'has-text-grey-light': props.row.status == 'RETURNED'}">{{ props.row.lotteryBookSale.endingSeq }}</span>
                </b-table-column>                

                <b-table-column numeric field="saleRackLoc" label="Start#" v-slot="props" v-if="lotterySaleDirection == 'Last to Zero' && !allowEdit">
                    <span :class="{'has-text-grey-light': props.row.status == 'RETURNED'}">{{ props.row.lotteryBookSale.endingSeq }}</span>
                </b-table-column>

                <b-table-column numeric class="has-text-right" field="amount" label="Tickets" v-slot="props" sortable sorticon>
                    <span :class="{'has-text-grey-light': props.row.status == 'RETURNED'}">{{ props.row.lotteryBookSale.ticketsSold }}</span>
                </b-table-column>
                
                <b-table-column numeric class="has-text-right" field="amount" label="Price" v-slot="props" sortable sorticon>
                <span :class="{'has-text-grey-light': props.row.status == 'RETURNED'}">{{ props.row.pricePoint | formatCurrency }}</span>

                </b-table-column>

                <b-table-column numeric class="has-text-right" field="amount" label="Amount" v-slot="props" sortable sorticon>
                <span :class="{'has-text-grey-light': props.row.status == 'RETURNED'}">{{ props.row.pricePoint * props.row.lotteryBookSale.ticketsSold  | formatCurrency}}</span>
                </b-table-column>
                            

                <b-table-column label="" v-slot="props" sortable sorticon v-if="allowEdit">
                    <b-field v-if="props.row.status != 'RETURNED' && !isPrinting">
                        <b-switch 
                            @input="closeBook(shift.lotteryBooksOnSale.indexOf(props.row))" 
                            true-value="CLOSED"
                            false-value="ONSALE"
                            size="is-small"
                            :disabled="props.row.lastTicketSold > props.row.lotteryBookSale.endingSeq || (props.row.status != 'ONSALE' && props.row.status != 'CLOSED')"
                            v-model="props.row.status"
                            type="is-success">
                                Closed
                        </b-switch>
                        <!-- {{ props.row.lastTicketSold }} -->
                    </b-field>
                    <span  v-else>{{ props.row.status }}</span>
                </b-table-column>
            </template>

            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"
                    message="No Instant Lottery for this shift">
                </data-loading>
            </template>

        </b-table> 
        
    </div>
    <!-- {{  lotterySaleDirection.value }} -->
    <div class="panel-heading has-text-weight-bold">
        <div class="columns">
            <div class="column has-text-centered is-3">          
                <div class="is-size-7">
                    <p class="is-size-7">Ticket Sale Direction</p>
                    <p class="is-size-6"> {{ lotterySaleDirection }}</p>
                </div>
            </div>
            <div class="column has-text-centered is-3">          
                <div>
                    <p class="is-size-7">Book Count</p>
                    <p class="is-size-6"> {{ shift.lotteryBooksOnSale.length  }}</p>
                </div>
            </div>        
            <div class="column has-text-centered is-3">          
                <div>
                    <p class="is-size-7">Tickets Sold</p>
                    <p class="is-size-6"> {{ shift.instantLottoTicketsSold  }}</p>
                </div>
            </div>                
            <div class="column has-text-centered is-3">          
                <div>
                    <p class="is-size-7">Instant Lottery Sales</p>
                    <p class="is-size-6"> {{ shift.instantLottoSales | formatCurrency }}</p>
                </div>
            </div>
        </div>
    </div>
</div>  
   
    <shift-lottery-form
            :shiftNum="shiftNum"
            :shiftId="shift.id"
            :stateBarCodeFormat="stateBarCodeFormat"
            :lotteryBooksInStock="lotteryBooksInStock"
            :shiftDate="shiftDate"
            :lottery="lotteryTemp"
            :action="action"
            :isShowDialog="isShowLotteryDialog"  
            @hide-dialog="toggleLotteryDialog"
            @save-record="saveLotteryRecord"
            >
    </shift-lottery-form>

    <shift-lottery-scan-form
        :storeId="storeId"
        :salesId="shift.id"
        :stateBarCodeFormat="stateBarCodeFormat"
        :storeState="storeState"
        :lotteryBins="lotteryBins"
        :lotteryBooksOnSale="shift.lotteryBooksOnSale"
        :lotteryBooksInStock="lotteryBooksInStock"
        :action="action"
        :isShowDialog="isShowLotteryScanFormDialog"  
        @hide-dialog="toggleLotteryScanFormDialog"
        @save-record="saveLotteryScanRecords"
        @close-book="closeBook"
        @add-book-record="addScannedLotteryToRack">
    </shift-lottery-scan-form> 
    <lottery-PDF-report
        ref="pdfReport"
        :isShowPDFDialog="isShowPDFDialog"
        :fetchStatus="fetchStatusPDF"
        :filteredRecords="shift.lotteryBooksOnSale"
        :shiftDate="shiftDate"
        :shiftNumber="shiftNumber(index)"
        @close-pdf-dialog="togglePDFDialog"
    >
    </lottery-PDF-report>
</div>
</template>

<script>

import axios from 'axios'
import UserStore from "../../../../store/UserStore"
import ShiftLotteryForm from './ShiftLotteryForm'
import ShiftLotteryScanForm from './ShiftLotteryScanForm.vue'
import DataLoading from '../../../../components/app/DataLoading'
import jspdf from "jspdf"
import LotteryPDFReport from './ShiftLotteryPDFReport'

export default {

    components: {
        ShiftLotteryForm,
        ShiftLotteryScanForm,
        DataLoading,
        LotteryPDFReport
    },

    props: {
        shift: {},
        shiftBeforeUpdate: {},
        index: Number,
        shiftDate: null,
        user: null,
        allowEdit: Boolean,
        isPrinting: Boolean
    },

    data() {
        return {
            isShowLotteryDialog: false,
            isShowLotteryScanFormDialog: false,
            isScanLotteryOnSale: true,
            isShowPDFDialog: false,
            showLotteryTable: false,
            showNumberControls: true,
            lotterySaleDirection: (this.getSetting('lotteryInsideSaleDirection') == 1 ? "Last to Zero" : "Zero to Last"),
            lottery: {},
            shiftNum: 0,
            action: "add",
            isLoading: false,
            isStriped: true,
            isPaginated: false,
            perPage: 10,
            stickyHeaders: true,
            showDetailIcon: true,
            isFullPage: true,
            lotteryBooksInStock: [],
            instantLottoSales: 0.00,
            scanLotteryLeftTable: [],
            scanLotteryRightTable: [],
            booksNotScanned: [],
            storeId: 0,
            states: [],
            lotteryBins: [],
            fetchStatusPDF: "ONSALE",
            lotteryTemp: {
                "id": 0,
                "shiftId": 0,
                "date": this.shift.date,
                "description": "",
                "amount": "0.00",
                "creditTerm": "Cash",
                "row": -1,
                "referenceNumber": ""
            },
            lotteryBooks: [],
            storeState: UserStore.getters.getStoreState,
            stateBarCodeFormat: UserStore.getters.getStateBarCodeFormat,

        }
    },

    methods: {

        fetchLotteryBooksInStock() {
            if (UserStore.getters.getActiveStore) {

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/lottery/books?status=RECEIVED" 

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.lotteryBooksInStock = response.data.data
                        // this.lotteryBooksInStock = this.filterReturnedBooks(response.data.data)
                        this.removeBooksOnSale()                    
                    })
                    .catch( (err) => {
                        this.dailyShifts = []
                        this.isLoading = false
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err)
                    })
            }
        },

        togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                this.$refs.pdfReport.loadData()
            }
        },

        filterReturnedBooks(books) {
            return books.filter(item => (item.status == 'RETURNED'))
        },

        doAction(action, shiftIndex) {
            this.isEditing = true
            this.action = action
            this.storeId = this.user.activeStore
            this.shiftNum = this.shiftNumber(shiftIndex)
            this.fetchLotteryBooksInStock()

            switch(this.action) {
                case 'add':
                        
                    this.lotteryTemp = {
                        "id": 0,
                        "receivedSalesId": 0,
                        "startSalesId": null,
                        "completedSalesId": null,
                        "gameId": 0,
                        "gameNumber": 0,
                        "defaultSeqBeg": 0,
                        "defaultSeqEnd": 0,
                        "ticketsInBook": 0,
                        "gameName": null,
                        "pricePoint": 0,
                        "stateCode": null,
                        "storeId": 0,
                        "bookNumber": null,
                        "status": null,
                        "location": 'R',
                        "saleRackLoc": null,
                        "loadedInMachine": false,
                        "ticketStartNum": 0,
                        "ticketEndNum": 0,
                        "ticketCount": 0,
                        "lastTicketSold": null,
                        "totalValue": 0,
                        "launchDate": "2020-01-01",
                        "receiveEmployeeId": 0,
                        "employeeName": this.shift.employeeId,
                        "bookSettlementDate": null,
                        "closedDate": null,
                        "activatedDate": null,
                        "lotteryBookSale": []
                    },
                    this.toggleLotteryDialog()
                    break

                case 'scan':
      
                        this.scanBooksOnSale()
                  
                    break

            }
        },

        scanBooksOnSale() {

            this.scanLotteryLeftTable = JSON.parse(JSON.stringify(this.shift.lotteryBooksOnSale));
            this.booksNotScanned = this.shift.lotteryBooksOnSale.filter(item => (item.lastTicketSold == item.savedTicketEndNum))
            this.isShowLotteryScanFormDialog = true
                       
        },

        dialogCanceled() {
            this.isScanLotteryOnSale =false
        },

        cancelLotteryDialog(shiftid) {
            this.toggleLotteryDialog(shiftid)
        },

        toggleLotteryDialog(shiftid) {
            this.shiftId = shiftid
            this.isShowLotteryDialog = ! this.isShowLotteryDialog
        },

        toggleLotteryScanFormDialog() {
            this.reCalculateLottery(0)
            this.isShowLotteryScanFormDialog = ! this.isShowLotteryScanFormDialog
        },

        removeBooksOnSale() {

            this.shift.lotteryBooksOnSale.forEach((book)=> {
                var ubook = this.lotteryBooksInStock.filter(item => (item.id == book.id))
    
                if (ubook.length > 0) {
                    this.lotteryBooksInStock.splice(this.lotteryBooksInStock.indexOf(ubook[0]),1)
                } else {
                    // console.log("Not found")
                }
            })
            
        },

        addScannedLotteryToRack(book) {
            this.shift.lotteryBooksOnSale.push(book)
        },

        saveLotteryRecord() {
            this.isLoading = false
            if (process.env.VUE_APP_DEBUG == 'true') console.log("Saving Lottery Record", this.lotteryTemp)

            this.shift.lotteryBooksOnSale.push(this.lotteryTemp)
            this.reCalculateLottery(0)
        },

        saveLotteryScanRecords() {
            this.reCalculateLottery(0)
            this.toggleLotteryScanFormDialog()
        },

        updateLotteryRecord() {
            this.shift.lotteryBooksOnSale.push(this.lotteryTemp)
        },

        closeBook(index) {

            if (this.shift.lotteryBooksOnSale[index].status == "CLOSED") {
                this.shift.lotteryBooksOnSale[index].lotteryBookSale.endingSeq = this.shift.lotteryBooksOnSale[index].ticketEndNum+1
                this.shift.lotteryBooksOnSale[index].completedSalesId = this.shift.id
                this.shift.lotteryBooksOnSale[index].closedDate = this.shift.closingDate
            } else {
                this.shift.lotteryBooksOnSale[index].completedSalesId = null
                this.shift.lotteryBooksOnSale[index].closedDate = null
                if (this.shiftBeforeUpdate.lotteryBooksOnSale.length > 0) {
                    if (this.shiftBeforeUpdate.lotteryBooksOnSale[index].lotteryBookSale.endingSeq == null) {
                        this.shift.lotteryBooksOnSale[index].lotteryBookSale.endingSeq = this.shiftBeforeUpdate.lotteryBooksOnSale[index].lotteryBookSale.startingSeq
                    } else {
                        this.shift.lotteryBooksOnSale[index].lotteryBookSale.endingSeq = this.shiftBeforeUpdate.lotteryBooksOnSale[index].lotteryBookSale.endingSeq
                    }
                } else {
                    this.shift.lotteryBooksOnSale[index].lotteryBookSale.endingSeq = 0
                }
            }
            this.reCalculateLottery(index)
        },

        returnedBook(index) {
            // whatever

            // this.shift.lotteryBooksOnSale[index].status='INMACHINE'
            if (index) {
                this.shift.lotteryBooksOnSale[index].startSalesId = null
            }
            this.reCalculateLottery(index)
            
        },

        isEndingSeqDisabled(index) {

            var row = this.shift.lotteryBooksOnSale[index]

            switch (row.status) {
                case "CLOSED":
                    return true
                case "RETURNED":
                    return true
                case "RECEIVED":
                    return true                    
                case "ONSALE":
                    if (row.lastTicketSold > row.savedTicketEndNum) {
                        return true
                    }
            }

            return false
            // return this.shift.lotteryBooksOnSale[index].status != 'ONSALE' || this.shift.lotteryBooksOnSale[index].status.lastTicketSold > this.shift.lotteryBooksOnSale[index].status.lotteryBookSale.endingSeq
        },

        isBeginSeqDisabled(index) {

            var row = this.shift.lotteryBooksOnSale[index]

            switch (row.status) {
                case "CLOSED":
                    return true
                case "RETURNED":
                    return true
                case "RECEIVED":
                    return true                    
                case "ONSALE":
                    var begNum = (row.savedTicketEndNum ? row.savedTicketEndNum : row.ticketEndNum)
                     
                    if (row.lastTicketSold < begNum) {
                        return true
                    }
            }

            return false
            // return this.shift.lotteryBooksOnSale[index].status != 'ONSALE' || this.shift.lotteryBooksOnSale[index].status.lastTicketSold > this.shift.lotteryBooksOnSale[index].status.lotteryBookSale.endingSeq
        },
        
        shiftNumber(index) {
            return index + 1
        },

        getNumber(inp) {
            if (Number(inp)) {
                return parseFloat(inp)
            }
            return 0
        },

        reCalculateLottery(index) {
            
            if (this.shift.lotteryBooksOnSale.length > 0) {
                this.shift.lotteryBooksOnSale[index].lotteryBookSale.bookId = this.shift.lotteryBooksOnSale[index].id
                this.shift.lotteryBooksOnSale[index].lotteryBookSale.salesId = this.shift.id

                if (this.lotterySaleDirection == "Last to Zero") {
                    if (!this.shift.lotteryBooksOnSale[index].savedTicketEndNum) {
                        // this.shift.lotteryBooksOnSale[index].savedTicketEndNum = this.shift.lotteryBooksOnSale[index].ticketEndNum
                    } 
                    this.shift.lotteryBooksOnSale[index].lotteryBookSale.ticketsSold = 
                        parseFloat(this.shift.lotteryBooksOnSale[index].savedTicketEndNum) -
                        parseFloat(this.shift.lotteryBooksOnSale[index].lotteryBookSale.endingSeq)

                    this.shift.lotteryBooksOnSale[index].lastTicketSold = this.shift.lotteryBooksOnSale[index].lotteryBookSale.endingSeq                          
                } else {
                    this.shift.lotteryBooksOnSale[index].lotteryBookSale.ticketsSold = 
                        parseFloat(this.shift.lotteryBooksOnSale[index].lotteryBookSale.endingSeq) - 
                        parseFloat(this.shift.lotteryBooksOnSale[index].lotteryBookSale.startingSeq)
                }

                this.shift.instantLottoTicketsSold = 
                    this.shift.lotteryBooksOnSale.reduce((accum,item) => accum + item.lotteryBookSale.ticketsSold, 0)

                this.shift.instantLottoSales = this.shift.lotteryBooksOnSale.reduce((accum,item) => accum + (item.lotteryBookSale.ticketsSold * item.pricePoint), 0)

            }

            this.$emit("save",this.index)

        },

        processScannedInput() {
            if (this.scannedBookBarCode != "") {

                this.scannedBookBarCode = this.scannedBookBarCode.replace('-', '')

                this.gameNumber = this.scannedBookBarCode.substring(0,this.stateBarCodeFormat.Game)
                this.bookNumber = this.scannedBookBarCode.substring(this.stateBarCodeFormat.Game,this.stateBarCodeFormat.Game+this.saveLotteryScanRecords.Book)

                this.$refs.scannedBookBarCode.focus()

            }

        },

        countryStates() {
            
            var url = process.env.VUE_APP_API_ROOT_URL + "/global/states/" + UserStore.getters.getStore.country

            axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken,
                        'correlation-id': this.$uuid.v1()
                    },                   
                })
                .then( (response) => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                    this.states = response.data.data.sort(function(a,b) {

                            return (a["code"] > b["code"]) ? 1 : (a["code"] < b["code"]) ? -1 : 0;

                    });
                    // var storeObj = this.states.find(o => o.code === UserStore.getters.getStore.state)
                    
                    // switch (storeObj.code) {
                    //     case "IL":
                    //         this.stateBarCodeFormat.game={"Game": 4, "Book": 7, "Ticket": 3}
                    //         break
                    //     case "IN":
                    //         this.stateBarCodeFormat.game={"Game": 4, "Book": 6, "Ticket": 3}
                    //         break
                    //     case "MD":
                    //         this.stateBarCodeFormat={"Game": 4, "Book": 6, "Ticket": 3}   
                    //         break                                                        
                    //     case "MI":
                    //         this.stateBarCodeFormat={"Game": 4, "Book": 6, "Ticket": 3}   
                    //         break
                    //     case "PA":
                    //         this.stateBarCodeFormat={"Game": 4, "Book": 7, "Ticket": 3}   
                    //         break                              
                    //     case "TX":
                    //         this.stateBarCodeFormat={"Game": 4, "Book": 7, "Ticket": 3}   
                    //         break                            
                    //     default:
                    //         this.stateBarCodeFormat.game={"Game": 4, "Book": 7, "Ticket": 3}                        
                    // }

                    // this.storeStateId = storeObj.id

                    this.isLoading = false
                })
                .catch( (err) => {
                    this.records = []
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                    this.isLoading = false
                    if (err.response.status === 403) {
                        alert('Session Expired. Please log in again!')
                        UserStore.commit('userLogout')
                    }
                })            
            

        },

        binsArray() {
            var ans = [];
            for (let i = 1; i <= 99; i++) {
                if (i < 10) {
                    ans.push("0"+i.toString())
                } else {
                    ans.push(i.toString())
                }                
            }
            return ans;
        },
        
        generatePDF() {
            this.$emit("print",this.index)

            const html = this.$refs.lotteryContent

            let srcwidth = html.scrollWidth
            // let srcwidth = 800

            const doc = new jspdf('p','pt','a4')

            doc.html(html, {
                html2canvas: {
                    scale: 595.26 /srcwidth,
                    scrollY: 0
                },
                filename: 'pandlreport',
                x: 0,
                y: 10,
                callback: function(pdf) {
                    window.open(pdf.output("bloburl"))
                }
            })

        },       
    },

    computed: {


        // shiftDate() {
        //     return this.shift.closingDate
        // },

        totalInstantLottoTicketsSold() {
            return this.shift.lotteryBooksOnSale.reduce((accum,item) => accum + parseFloat(item.pricePoint), 0)
        },

        totalInstantLottoSalesAmount() {
            return this.shift.lotteryBooksOnSale.reduce((accum,item) => accum + parseFloat(item.pricePoint), 0)
        },

        tableHeight() {
            if (this.isPrinting) {
                return this.shiftBeforeUpdate.lotteryBooksOnSale.length * 44
            } else {
                return 300
            }
        }
    },

    mounted() {
        this.lotteryBins = this.binsArray()
    }

}
</script>

<style>
.margin-7-all {
   margin: 7px;
}
.is-veecli-heading {
    padding: 40px;
    max-height: 50px;
}
.lottery-seq-input input[type=number]  {

    background-color: white;
    text-align: center;
    font-weight: 900;
    font-size-adjust: calc(500%);
    color: rgb(0, 0, 0);
    width: 50px;
    border-color: gold;
    border-width: 2px;
}
.lottery-seq-input input[type=number][disabled] {
    background-color: whitesmoke;
    font-weight:normal;
    color: gray;
    border-color: gray;
    border-width: 1px;
}

</style>