<template>
    <div class="box">

        <div class="columns is-fullwidth is-mobile veecli-table-heading">
            <div class="column is-fullwidth">
                SHIFT &nbsp;&nbsp;{{ shiftNumber(index) }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-one-quarter">
                Start:
            </div>
            <div class="column is-three-quarters has-text-right">
                {{ shift.periodBeginDate | formatDateTime }}
            </div>
        </div>
        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-one-quarter">
                End:
            </div>
            <div class="column is-three-quarters has-text-right">
                {{ shift.periodEndDate | formatDateTime }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-fullwidth">
                
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table-subhead">
            <div class="column is-fullwidth">
                SALES &nbsp;&nbsp;SUMMARY
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-two-quarters">
                Merchandise Sales:
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.merchSales | formatCurrency }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-two-quarters">
                Fuel Sales:
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ totalFuelVolume }} gal
            </div>            
            <div class="column is-two-quarters has-text-right">
                {{ shift.fuelSales | formatCurrency }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-two-quarters">
                Total Taxes:
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.totalTaxes | formatCurrency }}
            </div>
        </div>


        <div class="columns is-fullwidth is-mobile veecli-table-footer">
            <div class="column is-two-quarters">
                Total Sales:
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.overallSales | formatCurrency }}
            </div>
        </div>    

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-fullwidth">
                
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table-subhead">
            <div class="column is-fullwidth">
                PAYMENT &nbsp;&nbsp;METHOD
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-two-quarters">
                Cash
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.cashAmount | formatCurrency }}
            </div>
        </div>
        <div class="columns is-fullwidth is-mobile veecli-table" v-if="shift.creditAmount != null && shift.creditAmount != 0">
            <div class="column is-two-quarters">
                Credit Card
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.creditAmount | formatCurrency }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table" v-if="shift.debitAmount != null && shift.debitAmount != 0">
            <div class="column is-two-quarters">
                Debit Card
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.debitAmount | formatCurrency }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table" v-if="shift.foodstampAmount != null && shift.foodstampAmount != 0">
            <div class="column is-two-quarters">
                Food Stamp
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.foodstampAmount | formatCurrency }}
            </div>
        </div>
        <div class="columns is-fullwidth is-mobile has-text-danger veecli-table" v-if="shift.foodstampRefundAmount != null && shift.foodstampRefundAmount != 0">
            <div class="column is-two-quarters">
                Foodstamp Refund
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ Math.abs(shift.foodstampRefundAmount) * -1 | formatCurrency }}
            </div>
        </div>
        <div class="columns is-fullwidth is-mobile veecli-table" v-if="shift.linkCardAmount != null && shift.linkCardAmount != 0">
            <div class="column is-two-quarters">
                Link Card
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.linkCardAmount | formatCurrency }}
            </div>
        </div>
        <div class="columns is-fullwidth is-mobile has-text-danger veecli-table" v-if="shift.linkCardRefundAmount != null && shift.linkCardRefundAmount != 0">
            <div class="column is-two-quarters">
                Link Card Refund
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ Math.abs(shift.linkCardRefundAmount) * -1 | formatCurrency }}
            </div>
        </div>
        <div class="columns is-fullwidth is-mobile veecli-table" v-if="shift.inHouseAmount != null && shift.inHouseAmount != 0">
            <div class="column is-two-quarters">
                House Account
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.inHouseAmount | formatCurrency }}
            </div>
        </div>
        <div class="columns is-fullwidth is-mobile has-text-danger veecli-table" v-if="shift.inHouseRefundAmount != null && shift.inHouseRefundAmount != 0">
            <div class="column is-two-quarters">
                House Account Refund
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ Math.abs(shift.inHouseRefundAmount) * -1 | formatCurrency }}
            </div>
        </div>
        <div class="columns is-fullwidth is-mobile veecli-table" v-if="shift.couponAmount != null && shift.couponAmount != 0">
            <div class="column is-two-quarters">
                Coupon
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.couponAmount | formatCurrency }}
            </div>
        </div>
        
        <div class="columns is-fullwidth is-mobile veecli-table" v-if="otherPayments.toFixed(2) != 0.00">
            <div class="column is-two-quarters">
                Other Payment
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ otherPayments | formatCurrency}}
                <!-- {{ shift.netSales - shift.cashAmount - shift.creditAmount - 
                   shift.debitAmount - shift.linkCardAmount - 
                   shift.foodstampAmount  + shift.cancelRefund | formatCurrency }} -->
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile has-text-danger veecli-table" v-if="shift.cancelRefund != null && shift.cancelRefund != 0">
            <div class="column is-two-quarters">
                Cancel/Refund
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ (shift.cancelRefund > 0.00 ? shift.cancelRefund * -1 : shift.cancelRefund) | formatCurrency }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table-footer">
            <div class="column is-two-quarters">
                Total Sales
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.netSales | formatCurrency }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile has-text-danger veecli-table"  v-if="shift.creditCardFees != null">
            <div class="column is-two-quarters">
                Debit/Credit Card Fees
            </div>
            <div class="column is-two-quarters has-text-danger has-text-right">
                {{ shift.creditCardFees * -1 | formatCurrency }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-fullwidth">
                
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table-subhead">
            <div class="column is-fullwidth">
                CASH &nbsp;&nbsp;BALANCE
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-two-quarters">
                Cash Sales
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.cashAmount | formatCurrency }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table" v-if="shift.totalPaymentIn != 0">
            <div class="column is-two-quarters">
                Payment In
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.totalPaymentIn | formatCurrency }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-two-quarters">
                Cash Payout
            </div>
            <div class="column is-two-quarters has-text-right" :class="{'has-text-danger': shift.payOut > 0.00 }">
                {{ (shift.payOut > 0 ? shift.payOut * -1 : shift.payOut)| formatCurrency }}
            </div>
        </div>
        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-two-quarters">
                Cash Refund
            </div>
            <div class="column is-two-quarters has-text-right" :class="{'has-text-danger': shift.cashRefundAmount > 0.00 }">
                {{ (shift.cashRefundAmount > 0 ? shift.cashRefundAmount * -1 : shift.cashRefundAmount)| formatCurrency }}
            </div>
        </div>                
        <div class="columns is-fullwidth is-mobile veecli-table-footer" v-if="shift.totalPaymentIn != 0 || shift.payOut != 0">
            <div class="column is-two-quarters">
                Cash Balance
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.cashAmount + shift.totalPaymentIn - shift.payOut - shift.cashRefundAmount | formatCurrency }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-two-quarters">
                Cash Safe Drop
            </div>
            <div class="column is-two-quarters has-text-right" :class="{'has-text-danger': shift.safedropCashAmount > 0.00 }">
                {{ (shift.safedropCashAmount > 0.00) ? shift.safedropCashAmount * -1 : shift.safedropCashAmount | formatCurrency}}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table-footer">
            <div class="column is-two-quarters">
                Net Cash on Hand
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.cashAmount + shift.totalPaymentIn - shift.cashRefundAmount - shift.payOut - shift.safedropCashAmount | formatCurrency }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-fullwidth">
                
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table-subhead">
            <div class="column is-fullwidth">
                CIGARETTE
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-two-quarters">
                Sales Count:
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.cigaretteSaleCount }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-two-quarters">
                Sales Amount:
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.cigaretteSaleAmount | formatCurrency }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-two-quarters">
                Price per Pack:
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ ( shift.cigaretteSaleCount ? (shift.cigaretteSaleAmount / shift.cigaretteSaleCount) : 0)| formatCurrency }}
            </div>
        </div>        
        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-fullwidth">
                
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table-subhead">
            <div class="column is-fullwidth">
                FUEL PRODUCT SALES
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table" v-bind:key="fuel.product" v-for="fuel in shift.storeFuelSales">
            <div class="column is-two-quarters">
                {{ toTitleCase(fuel.fuelProduct) }}
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ fuel.volume.toFixed(3) }} gal
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ fuel.amount | formatCurrency }}
            </div>
        </div>


        <!-- <div class="columns is-mobile bottom-bordered-row" v-bind:key="fuel.product" v-for="fuel in shift.storeFuelSales">
            <div class="column is-3 has-text-left">
                {{ toTitleCase(fuel.fuelProduct) }}
            </div>
            <div class="column is-4 has-text-right">
                {{ fuel.volume.toFixed(0) }} gal
            </div>

            <div class="column is-5 has-text-right">
                {{ fuel.amount | formatCurrency}}
            </div>                               
        </div>  -->

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-fullwidth">
                
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table-subhead">
            <div class="column is-fullwidth">
                OTHER
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-two-quarters">
                Inside Sales:
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.insideSales | formatCurrency }}
            </div>
        </div>


        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-two-quarters">
                Outside Sales:
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.outsideSales | formatCurrency }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-two-quarters">
                Refund Taxes:
            </div>
            <div class="column is-two-quarters has-text-right" :class="{'has-text-danger': shift.totalRefundTaxes < 0.00 }">
                {{ shift.totalRefundTaxes | formatCurrency }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-two-quarters">
                Fuel Discounts:
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.fuelDiscounts | formatCurrency }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-two-quarters">
                Discounts:
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.totalDiscounts | formatCurrency }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table" v-if="shift.voidLineAmount != null || shift.voidLineAmount != 0">
            <div class="column is-two-quarters">
                Void Line:
            </div>
            <div class="column is-two-quarters has-text-right" :class="{'has-text-danger': shift.voidLineAmount > 0.00 }">
                {{ (shift.voidLineAmount > 0.00) ? shift.voidLineAmount * -1 : shift.voidLineAmount | formatCurrency}}
            </div>
        </div>
        <div class="columns is-fullwidth is-mobile veecli-table" v-if="shift.voidLineAmount != null || shift.voidLineAmount != 0">
            <div class="column is-two-quarters">
                Void Line Count:
            </div>
            <div class="column is-two-quarters has-text-right" :class="{'has-text-danger': shift.voidLineAmount > 0.00 }">
                {{ shift.voidLineCount }}
            </div>
        </div>
        <div class="columns is-fullwidth is-mobile veecli-table" v-if="shift.voidTicketAmount != null || shift.voidTicketAmount != 0">
            <div class="column is-two-quarters">
                Void Ticket:
            </div>
            <div class="column is-two-quarters has-text-right" :class="{'has-text-danger': shift.voidTicketAmount > 0.00 }">
                {{ (shift.voidTicketAmount > 0.00) ? shift.voidTicketAmount * -1 : shift.voidTicketAmount | formatCurrency}}
            </div>
        </div>
        <div class="columns is-fullwidth is-mobile veecli-table" v-if="shift.voidTicketAmount != null || shift.voidTicketAmount != 0">
            <div class="column is-two-quarters">
                Void Ticket Count:
            </div>
            <div class="column is-two-quarters has-text-right" :class="{'has-text-danger': shift.voidTicketAmount > 0.00 }">
                {{ shift.voidTicketCount }}
            </div>
        </div>

        <div class="columns is-fullwidth is-mobile veecli-table" v-if="(shift.totalPaymentOut - shift.payOut - shift.totalSafeDrop) != 0">
            <div class="column is-two-quarters">
                Non-cash Payout
            </div>
            <div class="column is-two-quarters has-text-right" :class="{'has-text-danger': otherPaymentOut > 0.00 }">
                {{ (otherPaymentOut > 0.00 ? otherPaymentOut* -1 : otherPaymentOut) | formatCurrency}}
            </div>
        </div> 

        <div class="columns is-fullwidth is-mobile veecli-table">
            <div class="column is-two-quarters">
                Customer Count:
            </div>
            <div class="column is-two-quarters has-text-right">
                {{ shift.customerCount }}
            </div>
        </div>        
        <div class="columns is-fullwidth is-mobile ">
            <div class="column is-fullwidth">
                
            </div>
        </div>

</div>


</template>

<script>

export default {

    props: {
        shift: {},
        index: Number,
        date: null,
        user: null
    },

    methods: {
        shiftNumber(index) {
            return index + 1
        },
    },

    computed: {
        otherPayments() {
            return  this.shift.netSales -
                    this.shift.cashAmount -
                    this.shift.creditAmount - 
                   this.shift.debitAmount - 
                   this.shift.linkCardAmount - 
                   this.shift.inHouseAmount -
                   this.shift.couponAmount -
                   this.shift.foodstampAmount  +
                    this.shift.cancelRefund
        },
        otherPaymentOut() {
            return  (this.shift.totalPaymentOut - 
                    this.shift.payOut - 
                    this.shift.totalSafeDrop).toFixed(2)
        },
        
        totalFuelVolume() {
            // this.shift.storeFuelSales.forEach(fuel => {
                
            // });
            return (this.shift.storeFuelSales.reduce((accum, fuel) => accum + parseFloat(fuel.volume), 0)).toFixed(3)
        }
    }

}
</script>