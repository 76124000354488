<template>
<article class="columns">
  <section class="column">
    <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowDialog }">
    <div class="modal-background"></div>
    <div class="modal-card scrollable" style="width: auto">
        <!-- <form action="" class="box" @submit.prevent="submitForm"> -->
        <header class="modal-card-head">
        <p class="modal-card-title has-text-centered"><span v-if="income.id == 0">ADD </span><span v-else>MODIFY </span>INCOME</p>
        <button class="delete is-danger" type="button" @click="closeDialog">Cancel</button>
        </header>
        <section class="modal-card-body">

                <div class="columns">
                    <div class="column">
                            <span class="has-text-weight-bold">Date: {{ date | formatDate }}</span>
                    </div>
                    <div class="column">
                            <span  class="has-text-weight-bold">Shift: {{ shiftNum }}</span>
                    </div>
                
                </div>

                <!-- <b-field required label="Vendor:" label-for="vendor"> -->
                    Vendor: 
                    <tool-tip v-if="!income.vendorId > 0">
                        <div slot="title" class="help-tooltip-header">
                        Vendor:
                        </div>
                        <div slot="content" class="help-tooltip-content">
                            Select the Vendor. <p>Cannot be empty.</p>
                        </div>
                        <span>&nbsp;<i class="fas fa-info-circle has-text-warning"></i></span>
                    </tool-tip>
                    <b-select class="veecli-select" v-model="income.vendorId">
                        <option v-for="vendor in vendors" :key="vendor.id + Math.random()" :value="vendor.id">{{ vendor.name }}</option>
                    </b-select>               
                <!-- </b-field> -->
                <br>

                <!-- <b-field label="Description" label-for="income.description"> -->

                    <!-- <b-input required type=textarea v-model="income.description"></b-input> -->

                    <!-- Income Type/Description: <span class="has-text-danger" v-if="income.description==''" title="Must be minimum 2 characters">*</span> -->
                    Description:
                    <tool-tip v-if="income.description == ''">
                        <div slot="title" class="help-tooltip-header">
                        Income Description
                        </div>
                        <div slot="content" class="help-tooltip-content">
                            Enter Income Type, Name or Description. <p>Cannot be empty.</p>
                        </div>
                        <span>&nbsp;<i class="fas fa-info-circle has-text-warning"></i></span>
                    </tool-tip>
                    <VeeInput
                        type="textarea" 
                        v-model="income.description"
                        name="incomedescription"
                        :required="income.description == ''"
                        :value="income.description"
                        :editable="trueValue"
                        />

                <!-- </b-field> -->

                <!-- <b-field label="Amount" label-for="income.amount"> -->

                    <!-- <b-input 
                        custom-class="has-text-right"
                        @focus="$event.target.select()"
                        type="text" step="any" 
                        v-model="income.amount">
                    </b-input> -->

                    <!-- Amount: <span class="has-text-danger is-size-7" v-if="income.amount==0" title="Amount cannot be 0">*</span> -->
                    Amount:
                    <tool-tip v-if="income.amount == 0">
                        <div slot="title" class="help-tooltip-header">
                        Income Amount
                        </div>
                        <div slot="content" class="help-tooltip-content">
                            Income Amount cannot be 0.
                        </div>
                        <span>&nbsp;<i class="fas fa-info-circle has-text-warning"></i></span>
                    </tool-tip>
                    <VeeInput
                        type="currency" 
                        v-model="income.amount"
                        name="incomeAmount"
                        :required="income.amount == 0"
                        :value="income.amount"
                        :countryCurrencyCode="countryCurrencyCode"
                        :editable="trueValue"
                        />
                <!-- </b-field> -->
                 
                <br>
                Paid by: <span class="has-text-danger is-size-7" v-if="!income.paymentMethod" title="Must select an option">*</span>
                <div class="block">
                    <b-radio v-model="income.paymentMethod"
                        name="paymentMethod"
                        native-value="Cash">
                        Received Cash
                    </b-radio>
                    <b-radio v-model="income.paymentMethod"
                        name="paymentMethod"
                        native-value="Check">
                        Received Check
                    </b-radio>
                    <b-radio v-model="income.paymentMethod"
                        name="paymentMethod"
                        native-value="EFT">
                        EFT Credit
                    </b-radio>
                </div>

        </section>

        <footer class="modal-card-foot">
        <button class="button is-success" type="button" @click="submitForm" :disabled='isDisabled'>Save</button>
        <button class="button" type="button" @click="cancelForm">Cancel</button>
        <span class='is-danger'></span>
        </footer>
    <!-- </form> -->

    </div>
    </div>
  </section>
</article>
</template>


<script>

import UserStore from "../../../../store/UserStore"
import VeeInput from "../../../../components/app/VeeInput.vue"
import ToolTip from '../../../../components/app/ToolTip'

export default {
    components: {
        VeeInput,
        ToolTip
    },

    props: {
         shiftNum: Number,
         date: null,
         income: {},
         incomeBefore: {},
         vendors: [],
         action: String,
         isShowDialog: Boolean
    },

    data() {
        return {
            trueValue: true,
            falseValue: false,
            email: '',
            role: '',
            openOnFocus: true,
            keepFirst: true,
            clearable: false,
            data: [],
            isFetching: false,
            vendorName: '',
            employees: [],
            employeeName: '',
            employeeFirstName:'',
            name: '',
            page: 1,
            totalPages: 1,
            paymentMethod: "Cash",
            countryCurrencyCode: UserStore.getters.getCountryCurrencyCode,
            user: []
        }
    },

    methods: {

        closeDialog() {

            this.$emit('hide-dialog')

        },

        cancelForm() {
            this.income.paymentMethod = this.incomeBefore.paymentMethod
            this.income.description = this.incomeBefore.description
            this.income.amount = this.incomeBefore.amount
            this.closeDialog()
        },

        submitForm() {
            if (process.env.VUE_APP_DEBUG == 'true')  console.log("Vendors", this.vendors)
            this.income.vendor = this.vendors.find(o => o.id === this.income.vendorId)
            this.income.userId = this.user.id
            this.income.verndorName  = this.selectedVendorName
            this.$emit('save-record')
            this.closeDialog()
        },

        alertMessage(message) {
            alert(message)
        },

        forceUpdate() {
            // console.log("Reloading Income Window")
            this.$forceUpdate()
        },

        setVendorName(name) {
            this.income.vendorName = name
        }

    },

    computed: {

        isDisabled() {

            if (
                // this.income.date && 
                this.income.vendorId > 0 &&
                this.income.description &&
                this.income.paymentMethod &&
                this.income.amount > 0.00 
                ) 
                {
                    return false
                } else {
                    return true
                }

        },

        selectedVendorName() {
            var vendorName = ""
            var vendor = {}

            if (this.income.vendorId > 0) {
                vendor = this.vendors.find(element => element.id === this.income.vendorId)
                vendorName = vendor.name
            }
            // console.log(vendor.name)
            return vendorName

        },

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },        

        filteredVendors() {
            return this.vendors.filter(option => {
                return (
                    option.name
                        .toString()
                        .toLowerCase()
                        .indexOf(this.income.vendor.name.toLowerCase()) >= 0
                )
            })
        },       

        filteredEmployees() {

            return this.employees.filter(option => {
                var fullName = option.firstName + " " + option.lastName
                return (
                    (fullName)
                        .toString()
                        .toLowerCase()
                        .indexOf(this.employeeName.toLowerCase()) >= 0
                )
            })
        }, 

        fullName(employee) {
            return employee.firstName + ' ' + employee.lastName
        },

    },

    mounted() {
        this.user = this.getUser()
    }

}
</script>

<style scoped>

/* .scrollable {
    max-height: 200vw;
    width: 400px;
    overflow: auto; 
} */
.veecli-select select { 
    /* Whatever color  you want */
    background-color: rgb(250, 250, 242);
}


</style>