<template>
<div class="panel">

        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">

                <div class="level-left has-text-centered" @click="showIncomeTable=!showIncomeTable">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <i class="fas fa-plus-square has-text-primary" v-if="!showIncomeTable && !isPrinting"></i>
                    <i class="fas fa-minus-square has-text-danger" v-if="showIncomeTable && !isPrinting"></i>
                </div>
                <div class="column is-size-6 is-family-sans-serif has-text-centered"> ADDITIONAL &nbsp;&nbsp;INCOME </div>
                    <div class="level-right has-text-centered">
                        <div class="button is-primary has-text-white is-small" @click="doIncomeAction('add',index)" v-if="allowEdit && !isPrinting">
                            <i class="fas fa-file-invoice-dollar"></i>&nbsp;&nbsp;New</div>&nbsp;&nbsp;&nbsp;&nbsp;
                    </div> 
                </div>
        </div>
        <div class="panel-block">


        <b-table class="control" v-if="shift.additionalIncomes && (showIncomeTable || isPrinting)"
            :visible="!isLoading"
            :striped="isStriped"
            :data='shift.additionalIncomes'
            :scrollable="isScrollable"
            :paginated="isPaginated"
            :per-page="perPage"
            paginationPosition="both"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort="dueOn"
            default-sort-direction="desc"
            :show-detail-icon="showDetailIcon"          
            >

            <template>

                <b-table-column class="is-2" field="vendorName" label="Income from" v-slot="props" sortable sorticon>
                {{ props.row.verndorName }} 
                </b-table-column>

                <b-table-column class="is-2" field="description" label="Desc" v-slot="props" sortable sorticon>
                {{ props.row.description }}
                </b-table-column>

                <b-table-column class="is-2" field="paymentMethod" label="Method" v-slot="props" sortable sorticon>
                {{ props.row.paymentMethod }}
                </b-table-column>

                <b-table-column numeric class="is-2 has-text-right" field="amount" label="Amount" v-slot="props" sortable sorticon>
                {{ props.row.amount | formatCurrency }}
                </b-table-column>
                

                <b-table-column class="is-2" label="" v-slot="props" v-if="allowEdit && !isPrinting">

                <!-- <div class="button has-text-danger is-small"   @click="doIncomeAction('delete',index, props.row)">
                    <i class="fas fa-trash-alt"></i>&nbsp;&nbsp;Delete
                </div>
                &nbsp;
                <div class="button has-text-info is-small" @click="doIncomeAction('edit',index, props.row)">
                    <i class="fas fa-pen-square"></i>&nbsp;&nbsp;&nbsp;Edit&nbsp;&nbsp;
                </div> -->
                    <b-button  class="is-danger is-small" title="Delete" 
                        v-on:click="doIncomeAction('delete',index, props.row)" outlined rounded>
                        <i class="fas fa-trash-alt"></i>
                    </b-button>
                    &nbsp;
                    <b-button class="is-primary is-small" title="Edit"  
                        v-on:click="doIncomeAction('edit',index, props.row)" outlined rounded>
                        <i class="far fa-edit"></i>
                    </b-button>
                </b-table-column>                                  

            </template>

            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"
                    message="No Income for this shift">
                </data-loading>
            </template>

        </b-table>
        </div>

        <div class="panel-heading has-text-weight-bold">
            <div class="columns">
                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total</p>
                    <p class="is-size-6"> {{ totalAdditionalIncome | formatCurrency }}</p>
                    </div>
                </div>
                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Non-Cash</p>
                    <p class="is-size-6"> {{ nonCashAdditionalIncome | formatCurrency }}</p>
                    </div>
                </div>
                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Cash</p>
                    <p class="is-size-6">{{ cashAdditionalIncome | formatCurrency }}</p>
                    </div>
                </div>
                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Register Paid In</p>
                    <p class="is-size-6"> {{ shift.totalPaymentIn | formatCurrency }}</p>
                    </div>
                </div>   

            </div>
        </div>

        <shift-income-form
            :shiftNum="shiftNum"
            :date="date"
            :income="incomeTemp"
            :vendors="vendors"
            :incomeBefore="incomeBefore"
            :action="action"
            :isShowDialog="isShowIncomeDialog"  
            @hide-dialog="toggleIncomeDialog"
            @save-record="saveIncomeRecord"
            >
        </shift-income-form>

    </div>
     
</template>

<script>

import ShiftIncomeForm from './ShiftIncomeForm'
import DataLoading from '../../../../components/app/DataLoading'

export default {

    components: {
        ShiftIncomeForm,
        DataLoading
    },

    props: {
        shift: {},
        index: Number,
        vendors: [],
        date: null,
        user: null,
        allowEdit: Boolean,
        isPrinting: Boolean
    },


    data() {
        return {
            isShowIncomeDialog: false,
            showIncomeTable: false,
            income: {},
            shiftNum: 0,
            action: "add",
            isLoading: false,
            isStriped: true,
            isScrollable: false,
            isPaginated: false,
            perPage: 20,
            showDetailIcon: true,
            isFullPage: true,
            incomeTemp: {
                "id": null,
                "salesId": this.shift.id,
                "userId": this.user.id,
                "date": this.shift.date,
                "description": "",
                "amount": "0.00",
                "paymentMethod": "Cash",
                "row": -1,
                "vendor": {"id": 0, "name": ""},
                "referenceNumber": ""
            },
            incomeBefore: {}
        }
    },

    methods: {

        doIncomeAction(action, shiftIndex, income) {
            this.isEditing = true
            this.action = action
            this.storeId = this.user.activeStore
            this.shiftNum = this.shiftNumber(shiftIndex)

            switch(this.action) {
                case 'add':
                    this.incomeTemp = {
                        "id": null,
                        "salesId": this.shift.id,
                        "shiftIndex": shiftIndex,
                        "date": this.shift.date,
                        "description": "",
                        "amount": "0.00",
                        "paymentMethod": "Cash",
                        "row": -1,
                        "vendor": {"id": null, "name": ""},
                        "referenceNumber": ""
                    }
                    this.toggleIncomeDialog()
                    break

                case 'edit':
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(income)
                    this.incomeTemp = income
                    this.incomeTemp.row = this.shift.additionalIncomes.findIndex((element) => element == income)
                    this.incomeBefore = JSON.parse(JSON.stringify(this.incomeTemp))
                    this.toggleIncomeDialog()
                    break

                case 'delete':
                    if (process.env.VUE_APP_DEBUG == 'true')  console.log("Deleting", income)
                    this.$buefy.dialog.confirm({
                        title: 'Deleting Income',
                        message: 'Are you sure you want to <b>delete</b> income from <b>' + income.verndorName + '</b> for <b>' + parseFloat(income.amount).toLocaleString('en-US', { style: 'currency',  currency: 'USD',}) + '</b>?',
                        confirmText: 'Delete Income',
                        type: 'is-danger',
                        hasIcon: true,
                        onConfirm: () => {
                            var row = this.shift.additionalIncomes.findIndex((element) => element == income)
                            this.shift.additionalIncomes.splice(row, 1)
                            this.$buefy.toast.open('Income from ' + income.verndorName +' deleted!')
                            this.$emit("save",this.index)
                        }
                    })
                    break
            }
        },

        cancelIncomeDialog(shiftid) {
            // this.incomeTemp = {}
            this.toggleIncomeDialog(shiftid)
        },

        toggleIncomeDialog(shiftid) {
            this.shiftId = shiftid
            this.isShowIncomeDialog = ! this.isShowIncomeDialog
        },

        saveIncomeRecord() {
            this.isLoading = false
            if (process.env.VUE_APP_DEBUG == 'true') console.log("Saving Income Record", this.incomeTemp)

            if (this.incomeTemp.row == -1) {
                this.incomeTemp.row = null
                this.shift.additionalIncomes.push(this.incomeTemp)
            }

            if (this.incomeTemp.row > -1) {
                this.incomeTemp.row = null
            }
            this.$emit("save",this.index)
        },

        updateIncomeRecord() {
            this.shift.additionalIncomes.push(this.incomeTemp)
        },

        shiftNumber(index) {
            return index + 1
        },

    },

    computed: {
        cashAdditionalIncome() {

            var filteredArray = this.shift.additionalIncomes.filter(item => (item.paymentMethod == "Cash"));
            var total = filteredArray.reduce((accum,item) => accum + parseFloat(item.amount), 0)
            return total

        },

        nonCashAdditionalIncome() {

            var filteredArray = this.shift.additionalIncomes.filter(item => (item.paymentMethod != "Cash"));
            var total = filteredArray.reduce((accum,item) => accum + parseFloat(item.amount), 0)
            return total

        },

        totalAdditionalIncome() {
            return this.nonCashAdditionalIncome + this.cashAdditionalIncome
        },
    }

    
}
</script>

<style>
.margin-7-all {
   margin: 7px;
}
.is-veecli-heading {
    padding: 40px;
    max-height: 50px;
}
</style>