<template>
    <div>
        <span for="textbx" class="add-required-icon" v-if="type=='text'">
            <input 
                type="text"
                id="textbx"
                class="text-input has-text-left"
                :ref="refname"
                :maxlength="maxlength"            
                v-model="displayTextValue"
                :disabled="!editable"
                :required="required"
                :name="name"
                @focus="onTextFocus"/>
        </span>
        
        <input  
            v-if="type=='currency'"
            type="text" 
            class="currency-input has-text-right"
            :class="{ 'negetive': displayCurrencyValue < 0 }"
            :ref="refname"
            v-model="displayCurrencyValue"
            :disabled="!editable"
            :required="required"
            :name="name"
            @blur="onBlur" 
            @keyup="onKeyUp"
            @focus="onFocus"/>  
            
        <input 
            v-if="type=='number'"
            type="text"  
            class="currency-input has-text-right"
            :class="{ 'negetive': displayNumberValue < 0 }"
            :ref="refname"
            v-model="displayNumberValue"
            :disabled="!editable"
            :required="required"
            :name="name"
            @blur="onBlur" 
            @keyup="onKeyUp"
            @focus="onFocus"/>

            <input 
            v-if="type=='dateUS'"
            type="text"  
            class="text-input has-text-left"
            :ref="refname"
            v-model="displayDateUSValue"
            :disabled="!editable"
            :required="required"
            :name="name"
            @blur="onBlur" 
            @keyup="onKeyUp"
            @focus="onFocus"/>

        <textarea 
            v-if="type=='textarea'"
            class="textarea-input has-text-left"
            :ref="refname"
            v-model="displayTextValue"
            :type="type"
            :name="name"
            :maxlength="maxlength"
            :disabled="!editable"
            :required="required"
            @focus="onTextFocus">
        </textarea>
        <div v-if="type=='textarea'" class="is-size-7 has-text-right">{{ valueLength }} / {{ maxlength }}</div>
    </div>

</template>

<script>

export default {
    props: {
        editable: {
            required: false,
            type: Boolean,
            default: false
        },
        name: {
            required: false,
            type: String,
            default: "textfield"
        },
        value: {
            type: [String, Number, Boolean],
            default: null,
            validator: (p) => {
                return ['string', 'number', 'boolean'].indexOf(typeof p) !== -1 || p === null;
            },
        },
        countryCurrencyCode: {
            required: false,
            type: String,
            default: 'USD'
        },
        type: {
            required: false,
            type: String,
            default: 'number'
        },
        prefix: {
            required: false,
            type: String,
            default: ''
        },
        postfix: {
            required: false,
            type: String,
            default: ''
        },
        maxlength: {
            required: false,
            type: [String, Number],
            default: "200"
        },
        decimals: {
            required: false,
            type: [String, Number],
            default: "2"
        },
        width: {
            required: false,
            type: String,
            default: "100%"
        },
        required: {
            required: false,
            type: Boolean,
            default: false
        },
        refname: {
            required: false,
            type: String,
            default: "node"
        }
    },

    directives: {
        focus: {
            // directive definition
            inserted: function (el) {
                el.focus()
            }
        }
    },

    data() {
        return {
            isInputActive: false,
            isInvalid: false,
            previousValue: this.value,
            minFractionDigits: 0,
            maxFractionDigits: 2,
            currencyCode: this.countryCurrencyCode
        }
    },

    methods: {
        onBlur(event) {
            var currentValue = this.removeNonNumeric(event.target.value)
            
            if (this.previousValue !== currentValue) {
                if (isNaN(currentValue)) {
                    currentValue = this.previousValue
                    event.target.value = this.previousValue
                }          
            }
            if (this.type=="number") {
                event.target.value = this.formatNumber(currentValue)
            } else {
                event.target.value = this.formatCurrency(currentValue)                
            }
            this.previousValue = currentValue

        },

        onTextFocus(event) {
            event.target.select()            
        },

        onFocus(event) {
            this.isInputActive = true
            event.target.value = this.removeNonNumeric(event.target.value)
            event.target.select()            
        },
        
        onKeyUp(event) {
            event.target.value = event.target.value.replace(/[^\d.-]/g,'')
        },

        formatCurrency(number) {
            if (isNaN(number)) {
                number = 0
            }

            if ( this.currencyCode === undefined || !isNaN(this.currencyCode)) {
                this.currencyCode = 'USD'
            }

            if (this.decimals) {
                this.minimumFractionDigits = parseInt(this.decimals)
                this.maximumFractionDigits = parseInt(this.decimals)
            }

            const currency = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.currencyCode,
                minimumFractionDigits: this.minimumFractionDigits,
                maximumFractionDigits: this.maximumFractionDigits,

                // These options are needed to round to whole numbers if that's what you want.
                //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            })
            if (this.isNarrowScreen()) {
                return currency.format(number)
            } else {
                return (this.prefix !== undefined ? this.prefix : "") + currency.format(number) + (this.postfix !== undefined ? this.postfix : "")
            }
        },

        formatNumber(number) {
            if (isNaN(number)) {
                number = 0
            }

            // Override Decimals
            if (this.decimals) {
                this.maximumFractionDigits = this.decimals
            }

            const formatter = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: parseInt(this.minFractionDigits),      
                maximumFractionDigits: parseInt(this.maxFractionDigits),
            });

            return this.prefix + formatter.format(number) + (this.postfix !== undefined ? this.postfix : "")
        },

        removeNonNumeric(numberWithString) {
            return Number(numberWithString.toString().replace(/[^\d.-]/g,''))
        },

    },

    computed: {
        displayTextValue: {
            get: function() {
                if (this.value === null || this.value === 0) {
                    return ""
                } else {
                    return this.value
                }
            },
            set: function(modifiedValue) {
                this.$emit('input', modifiedValue)
            }
               
        },

        displayCurrencyValue: {
            get: function() {
                if (this.value === null) {
                    return 0
                }
                if (this.isInputActive) {
                    // Cursor is inside the input field. unformat display value for user
                    return this.removeNonNumeric(this.value)
                } else {
                    // User is not modifying now. Format display value for user interface
                    return this.value.toString()
                }
            },
            set: function(modifiedValue) {
                // // Recalculate value after ignoring "$" and "," in user input
                // let newValue = parseFloat(modifiedValue.replace(/[^\d\]/g, ""))
                // // Ensure that it is not NaN

                // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
                // $emit the event so that parent component gets it

                let newValue = parseFloat(modifiedValue.toString().replace(/[$,]/g, ""))
                
                if (isNaN(newValue)) {
                    this.isInvalid = true
                    newValue = 0
                    this.$emit('input', newValue)
                } else {
                    this.$emit('input', newValue)
                    this.isInvalid = false
                }

            }
        },

        displayNumberValue: {
            get: function() {
                if (this.value === null) {
                    return 0
                }
                if (this.isInputActive) {
                    // Cursor is inside the input field. unformat display value for user
                    return this.removeNonNumeric(this.value)
                } else {
                    // User is not modifying now. Format display value for user interface
                    return this.value.toString()
                }
            },
            set: function(modifiedValue) {
                // // Recalculate value after ignoring "$" and "," in user input
                // let newValue = parseFloat(modifiedValue.replace(/[^\d\]/g, ""))
                // // Ensure that it is not NaN

                // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
                // $emit the event so that parent component gets it

                let newValue = parseFloat(modifiedValue.toString().replace(/[$,]/g, ""))
                
                if (isNaN(newValue)) {
                    this.isInvalid = true
                    newValue = 0
                    this.$emit('input', newValue)
                } else {
                    this.$emit('input', newValue)
                    this.isInvalid = false
                }

            },
            
        },

        displayDateUSValue: {
            get: function() {
                if (this.value === null || this.value === 0) {
                    return ""
                } else {
                    return new Date(this.value).toLocaleDateString()
                }
            },
            set: function(modifiedValue) {
                this.$emit('input', modifiedValue)
            }
        },

        computedValue: {
            get() {
                return this.newValue
            },
            set(value) {
                this.newValue = value
                this.$emit('input', value)
            }
        },

        valueLength() {
            if (typeof this.displayTextValue === 'string') {
                return Array.from(this.displayTextValue).length
            } else if (typeof this.displayTextValue === 'number') {
                return this.displayTextValue.toString().length
            }
            return 0
        }

    },

    mounted() {

        var node = this.$refs[this.refname]
        if (node) {

            if (this.type === 'currency') {
                node.value = this.formatCurrency(node.value)
            }
            if (this.type === 'number') {
                node.value = this.formatNumber(node.value)
            }

            if (this.width !== undefined && !isNaN(this.width)) {
                node.style.setProperty('--input-text-width', this.width);                
            }
        }
    }


}
</script>

<style scoped>
:deep() {
    /* --input-background-color: rgb(250, 250, 242); */
    --input-background-color: rgb(250, 249, 246);
    /* --input-background-color: rgb(242, 249, 250); */
    --input-active-background-color: #e6f1f9;
    --input-active-border-color: #9ecaed;
    --input-active-box-shadow: #9ecaed;
    --input-disabled-background-color: rgb(241, 239, 239);
    --input-required-background-color: red;
    --input-box-shadow: 1px 1px 6px rgb(128, 127, 127);
    --input-border-color:rgb(37, 37, 36);
    --input-required-border-color: rgb(255, 73, 7);
    --input-required-box-shadow: 1px 1px 6px rgb(234, 210, 199);
    --input-text-width: 100%;
}

:deep(.currency-input)  {
    /* background-color: rgb(236, 246, 235); */
    background-color: var(--input-background-color);
    font-weight:bold;
    text-align: right;
    font-size: 1.0em;
    font-size-adjust: calc(500%);
    color: rgb(0, 0, 0);
    width: 7em;
    max-width: 100%;
    height: 30px;
    border-color: var(--input-border-color) ;
    border-width: 1px;
    padding-right: 5px;
    padding-left: 5px;
    /* box-shadow: var(--input-box-shadow); */
}
:deep(.text-input) {
    /* background-color: rgb(237, 246, 235); */
    background-color:var(--input-background-color);
    font-weight:bold;
    text-align: right;
    font-size: 1.0em;
    font-size-adjust: calc(500%);
    color: rgb(0, 0, 0);
    width: var(--input-text-width);
    max-width: 100%;
    height: 30px;
    border-color: var(--input-border-color);
    border-width: 1px;
    padding-right: 5px;
    padding-left: 5px;
    /* box-shadow: var(--input-box-shadow); */
}

:deep(.textarea-input) {
    /* background-color: rgb(237, 246, 235); */
    background-color:var(--input-background-color);
    font-weight:bold;
    text-align: right;
    font-size: 1.0em;
    font-size-adjust: calc(500%);
    color: rgb(0, 0, 0);
    width: var(--input-text-width);
    max-width: 100%;
    border-width: 1px;
    /* box-shadow: var(--input-box-shadow); */
    border-color: var(--input-border-color);
    box-sizing: border-box;
    padding-right: 5px;
    padding-left: 5px;
}

:deep(.text-input:disabled:required) , :deep(.textarea-input:disabled), :deep(.text-input:disabled) {
    background-color: var(--input-disabled-background-color);
    border: none;
    box-shadow: none;
}

:deep(.currency-input:disabled) , :deep(.textarea-input:disabled), :deep(.text-input:disabled) {
    background-color: var(--input-disabled-background-color);
    border: none;
    box-shadow: none;
}

:deep(.currency-input:focus), :deep(.textarea-input:focus), :deep(.text-input:focus) {
    outline: none;
    background-color: var(--input-active-background-color);
    border-color: var(--input-active-border-color);
    box-shadow: 0 0 10px var(--input-active-box-shadow);
}

:deep(.currency-input:required), :deep(.textarea-input:required), :deep(.text-input:required) {
    border-color: var(--input-required-border-color);
    border-style:solid;
    box-shadow: var(--input-required-box-shadow);
}

@media only screen and (max-width: 600px) {
    .currency-input  {
        width: 100%;
        font-size: 1.0em;
        max-width: 100px;
        font-size-adjust: calc(200%);
    }
}

.negetive {
    /* color: rgb(241, 76, 76); */
    color: rgb(255, 55, 0)
}

</style>