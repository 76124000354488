<template>
<article>
  <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowDialog }">
    <div class="modal-background"></div>
    <div class="modal-card scrollable" style="width: auto">
        <header class="modal-card-head">
        <p class="modal-card-title has-text-centered"><span v-if="lottery.id == 0">Activate </span><span v-else>MODIFY </span>Book</p>
        <button class="delete is-danger" type="button" @click="closeDialog">Cancel</button>
        </header>
        <section class="modal-card-body">
                <div class="columns">
                    <div class="column">
                            <span class="has-text-weight-bold">Date: {{ shiftDate | formatDate }}</span>
                    </div>
                    <div class="column">
                            <span  class="has-text-weight-bold">Shift: {{ shiftNum }}</span>
                    </div>
                
                </div>

                <b-field required label="Instant Games In Inventory" label-for="lotteryGames" v-if="lotteryBooksInStock.length > 0">

                    <b-select v-model="lottery.gameId" @input="gameChanged">
                        <option v-for="game in uniqueGames" 
                            :key="game.gameId"
                            :value="game.gameId">{{game.gameNumber }} {{ game.gameName }}
                        </option>
                    </b-select> 
                </b-field>

                <b-field required label="Books In Inventory" label-for="lotteryBooksInStock"  v-if="lotteryBooksInStock.length > 0">
                    <b-select v-model="lottery.id"  @input="bookChanged">
                        <option v-for="book in booksForGame" :key="book.bookNumber + Math.random()" :value="book.id">{{book.bookNumber }}</option>
                    </b-select> 
                </b-field>

                <b-field label="Starting Sequence" label-for="lottery.startSeq" v-if="lotteryBooksInStock.length > 0">
                    <b-numberinput compact        
                        v-model="lottery.startingSeq"
                        size="is-default"
                        width="2"
                        :min="lottery.ticketStartNum"
                        :max="lottery.ticketEndNum"
                        :controls="showNumberControls"
                        @focus="$event.target.select()"
                        icon="account">
                    </b-numberinput>
                </b-field>

                <div class="columns">
                    <div class="column">
                        <b-field  label="Bin #" label-for="lottery.saleRackLoc" v-if="lotteryBooksInStock.length > 0">
                            <b-select placeholder="Select a Bin"
                                v-model="lottery.saleRackLoc"
                                ref="binLoc"
                                :disabled="lottery.loadedInMachine"
                                :required="!lottery.loadedInMachine"
                                >
                                <option
                                    v-for="bin in bins"
                                    :value="bin"                                
                                    :key="bin">
                                    {{ bin }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                </div>
                <b-message type="is-danger" v-if="lotteryBooksInStock.length == 0">
                    No Instant Lottery books found in inventory
                </b-message>
        </section>

        <footer class="modal-card-foot">
        <button class="button is-success" type="button" @click="submitForm" :disabled='isSaveDisabled' v-if="lotteryBooksInStock.length > 0">Save</button>
        <button class="button" type="button" @click="closeDialog">Cancel</button>
        <span class='is-danger'></span>
        </footer>        
    </div>
  </div>
</article>
</template>

<script>


export default {
    props: {
         shiftNum: Number,
         shiftId: Number,
         shiftDate: null,
         lottery: {},
         action: String,
         isShowDialog: Boolean,
         lotteryBooksInStock: [],
    },

    data() {
        return {
            isDisabled: false,
            showNumberControls: false,
            loadedInMachine: false,
            bins: [],
        }
    },

    methods: {

        gameChanged() {
            this.lottery.id = 0
        },

        bookChanged() {
            var book = {}
            var self = this
            if (this.lottery.id == 0) {
                book.bookId = 0

            } else {

                var id = this.lottery.id

                book = self.lotteryBooksInStock.filter(function(elem) {
                        if(elem.id == id) {
                            return elem
                        }
                })
                if (typeof book[0] !== "undefined") {
                    Object.keys(book[0]).forEach(function(key) {

                        self.lottery[key] = book[0][key]

                    })

                    if (this.lottery.lastTicketSold) {
                        this.lottery.startingSeq = this.lottery.lastTicketSold
                        this.lottery.ticketStartNum = this.lottery.lastTicketSold
                    } else {
                        this.lottery.startingSeq = this.lottery.ticketStartNum
                    }

                    this.$refs.binLoc.focus()
                }

                
            }
        },

        closeDialog() {

            this.$emit('hide-dialog')

        },

        submitForm() {
            if (process.env.VUE_APP_DEBUG == 'true')  console.log("Lottery Saved", this.lottery)

            if (this.lottery.startingSeq == null) this.lottery.startingSeq = 0
            this.lottery.endingSeq = this.lottery.startingSeq
            this.lottery.startSalesId = this.shiftId
            this.lottery.status = "ONSALE"
            this.lottery.location = 'R'
            this.lottery.userId = 1
            this.lottery.lotteryBookSale = {
                            "id": null,
                            "bookId": this.lottery.id,
                            "salesId": this.shiftId,
                            "startingSeq": this.lottery.startingSeq,
                            "endingSeq": this.lottery.endingSeq,
                            "ticketsSold": this.lottery.endingSeq-this.lottery.startingSeq
                            }
            if (this.lottery.activatedDate == null) {
                this.lottery.activatedDate = this.shiftDate.toISOString().split('T')[0]
            } else {
                if (Array.isArray(this.lottery.activatedDate)) {
                    var temp = new Date(this.lottery.activatedDate[0]+"-"+this.lottery.activatedDate[1]+"-"+this.lottery.activatedDate[2])
                    this.lottery.activatedDate = temp.toISOString().split('T')[0]
                }
                
            }
            console.log("Activation Date",this.lottery.activatedDate)
            this.$emit('save-record')
            this.closeDialog()
        },

        binsArray() {
            var ans = [];
            for (let i = 1; i <= 99; i++) {
                if (i < 10) {
                    ans.push("0"+i.toString())
                } else {
                    ans.push(i.toString())
                }                
            }
            return ans;
        },
    },

    computed: {

        uniqueGames() {

            var ugames =  [...new Map(this.lotteryBooksInStock.map(item => [item['gameId'], item])).values()]
            ugames.sort((a, b) => (a.gameNumber > b.gameNumber) ? 1 : -1)
            return ugames

        },

        booksForGame() {

            var ubooks =  this.lotteryBooksInStock.filter(item => (item.gameId== this.lottery.gameId))
            ubooks.sort((a, b) => (a.bookNumber > b.bookNumber) ? 1 : -1)
            return ubooks
        },

        isSaveDisabled() {
            if (
                this.lottery.gameId > 0 && 
                this.lottery.id > 0 &&
                (this.lottery.saleRackLoc || this.lottery.loadedInMachine) &&
                this.lottery.startingSeq >= 0
                ) 
                {
                    return false
                } else {
                    return true
                }
                
        }

    },

    mounted() {

        this.bins = this.binsArray()

    }

}
</script>