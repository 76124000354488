<template>
    <!-- <div class="save-bottons-div has-background-info-light" v-if="isEditing">
        <div class="save-div-item has-text-white has-background-info">
            <span class="has-text-centered">{{ title }}</span>
        </div>
        <div class="save-div-item is-size-7 has-text-centered">
            {{  row1 }}
        </div>
        <div class="save-div-item has-text-weight-bold is-size-7 has-text-centered">
            {{ row2 }}
        </div>
        <div class="save-div-item has-text-centered">
            <button class="button is-default" type="button" @click="cancelEdit()" :disabled='! cancelEnable'>Cancel</button>
            &nbsp;&nbsp;
            <button class="button is-success" type="button" @click="saveEdit()" :disabled='! saveEnable'>Save</button>
        </div>
    </div> -->
    <div class="panel save-bottons-div is-small has-background-info-light" v-if="isEditing">
        <div class="panel-heading save-div-title">{{ title }}</div>
        <div class="save-div-row1" v-if="row1 != ''">{{  row1 }}</div>
        <div class="save-div-row2">{{  row2 }}</div>  
        <div class="save-div-row3">
            <button class="button is-default" type="button" @click="cancelEdit()" :disabled='! cancelEnable'>Cancel</button>
            &nbsp;&nbsp;
            <button class="button is-success" type="button" @click="saveEdit()" :disabled='! saveEnable'>Save</button>
        </div>
    </div>
</template>

<script>

import UserStore from '../../store/UserStore'

export default {
    props: {
        title: {
            required: false,
            type: String,
            default: UserStore.getters.getActiveStore.storeName
        },
        row1: {
            required: false,
            type: String,
            default: null
        },
        row2: {
            required: false,
            type: String,
            default: "UPDATES NOT SAVED"
        },
        cancelEnable: {
            required: true,
            type: Boolean,
            default: false
        },
        saveEnable: {
            required: true,
            type: Boolean,
            default: false
        },
        isEditing: {
            required: true,
            type: Boolean,
            default: false
        },

    },

    methods: {
        cancelEdit() {
            this.$buefy.dialog.confirm({
                title: 'Discard changes!',
                message: 'All changes made will be lost.  Are you sure?',
                type: 'is-danger',
                hasIcon: true,
                cancelText: "Keep Changes",
                confirmText: "Discard Changes",
                onConfirm: () => {
                    this.$emit('cancel-edit')
                    this.$buefy.toast.open({
                        message: 'Changes have been discarded and loaded saved data', 
                        type: 'is-info'
                    })
                }
            })
        },

        saveEdit() {
            this.$emit('save-edit')
        }
    }
}

</script>

<style>
.save-bottons-div {
    font-size: small;
    font-weight: bold;
    position: fixed;
    top: 4em;
    right: 3em;
    z-index: 20;
    background-color: #edf0f2;
    border: 1px solid rgb(213, 211, 211);
    border-width: 3px;
    margin: 10px;
    padding: 10px;
    border-radius: 25px;
    box-shadow: 1px 1px 1px rgb(145, 144, 144);
    text-align: center;
}

.save-div-title {
    text-align: center;
    margin: 0px;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 10px;
    border-radius: 25px;
    background-color: rgb(183, 213, 247);
}

.save-div-row1 {
    font-size: small;
    font-weight: bold;
    margin: 0px;
    padding-top: 3px;
    padding-right: 10px;
    padding-bottom: 3px;
    padding-left: 10px;
}

.save-div-row2 {
    font-size: small;
    font-weight: bold;
    margin: 0px;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 3px;
    padding-left: 10px;
    border-radius: 25px;
}

.save-div-row3 {
    margin: 0px;
    font-size: small;
    font-weight: bold;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 10px;
    border-radius: 25px;
}
</style>