<template>
<article class="columns">
  <section class="column">
    <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowDialog }">
    <div class="modal-background"></div>
    <div class="modal-card scrollable" style="width: auto">
        
        <header class="modal-card-head">
            <p class="modal-card-title">Activating new book</p>&nbsp;&nbsp;
            <button class="delete is-danger" type="button" @click="closeDialog">Cancel</button>
        </header>
        <section class="modal-card-body">
            <div class="box">
                <form>
                <div class="columns is-mobile">
                    <div class="column">
                        Game
                    </div>
                    <div class="column">
                        {{ book.gameNumber }} {{ book.gameName }}
                    </div>
                </div>
                <div class="columns is-mobile">
                    <div class="column">
                        Book Number
                    </div>
                    <div class="column">
                        {{ book.bookNumber }}
                    </div>
                </div>
                <div class="columns is-mobile">
                    <div class="column">
                        Ticket Number
                    </div>
                    <div class="column">
                        <div v-if="book.lotteryBookSale">{{ book.lotteryBookSale.endingSeq }}</div>
                    </div>
                </div>                
                <div class="columns is-mobile">
                    <div class="column">
                        Bin #
                    </div>
                    <div class="column">
                        <b-field>
                            <b-select placeholder="Select a Bin"
                                v-model="saleRackLoc"
                                ref="binLoc"
                                @input="getBooksInBin"
                                >
                                <option
                                    v-for="bin in lotteryBins"
                                    :value="bin"                                
                                    :key="bin"
                                    >
                                    {{ bin }}
                                </option>
                            </b-select>
                        </b-field>                    
                    </div>
                </div>                
                </form>
            </div>
            <footer class="modal-card-foot">
            <button class="button is-success" type="button" :disabled="saleRackLoc ==''"  @click="addBookToSale">Activate Book</button>
            <button class="button" type="button" @click="closeDialog">Cancel</button>
            <span class='is-danger'></span>
            </footer> 

            <section class="modal-card-body is-warning" v-if="booksInBin.length > 0">
                <div class="title">
                    Other Books in the selected Bin
                </div>
                <b-table class="control"
                    :visible="!isLoading"
                    :striped="isStriped"
                    :data='booksInBin'
                    :scrollable="isScrollable"
                    :paginated="isPaginated"
                    :per-page="perPage"
                    paginationPosition="both"
                    default-sort="lotteryBookSale.ticketsSold"
                    default-sort-direction="asc"          
                    >
                    <template>

                        <b-table-column class="is-2" field="saleRackLoc" label="Bin #" v-slot="props" sortable sorticon>
                        {{ props.row.saleRackLoc }}
                        </b-table-column>
                        <b-table-column class="is-2" field="Book" label="Book" v-slot="props" sortable sorticon>
                        {{ props.row.gameNumber }}-{{ props.row.bookNumber }}
                        </b-table-column>                
                        <b-table-column class="is-2" field="endingSeq" label="Ending #" v-slot="props" sortable sorticon>
                            {{ props.row.lastTicketSold }}
                        </b-table-column>
                        <b-table-column class="is-2" field="endingSeq" label="Ending #" v-slot="props" sortable sorticon>
                            {{ props.row.lotteryBookSale.endingSeq }}
                        </b-table-column>
                        <b-table-column class="is-2" field="saleRackLoc" label="Sold" v-slot="props" sortable sorticon>
                            {{ props.row.lotteryBookSale.ticketsSold }}
                        </b-table-column>
                        <b-table-column class="is-2" label="" v-slot="props" sortable sorticon>
                            <b-field>
                                <b-switch 
                                    @input="closeBook(booksInBin.indexOf(props.row))" 
                                    true-value="CLOSED"
                                    false-value="ONSALE"
                                    size="is-small"
                                    v-model="props.row.status"
                                    type="is-success">
                                        Closed
                                </b-switch>
                            </b-field>
                        </b-table-column>                                
                    </template>
                    
                </b-table>
            </section>                 
        </section>

          
        
    </div>
    </div>
  </section>
</article>
</template>


<script>

export default {
    props: {
        canCancel: String, 
        isShowDialog: Boolean,
        storeId: Number,
        salesId: Number,
        id: Number,
        action: String,
        book: {},
        lotteryBooksOnSale: [],
        lotteryBooksInStock: [],
        lotteryBins: [],                 
    },

    data() {
        return {
            saleRackLoc: '',
            booksInBin: [],
            isScrollable: true,
            perPage: 20,
            isPaginated: false,
            isStriped: true,
            isLoading: false,
        }
    },

    methods: {
        closeDialog() {
            this.saleRackLoc = ''
            this.booksInBin = []
            this.$emit('hide-dialog')

        },

        addBookToSale() {
            this.book.saleRackLoc = this.saleRackLoc
            this.saleRackLoc = ''
            this.booksInBin = []
            this.$emit('save-record')

        },

        getBooksInBin() {
            var saleRacLoc = this.saleRackLoc
            this.booksInBin = this.lotteryBooksOnSale.filter(function(elem) {
                if(elem.saleRackLoc === saleRacLoc) {
                    return elem
                }
            })
        },

        closeBook(index) {

            if (this.booksInBin[index].status == "CLOSED") {
                this.booksInBin[index].lotteryBookSale.endingSeq = this.booksInBin[index].ticketEndNum+1
                this.booksInBin[index].completedSalesId = this.salesId
            } else {
                this.booksInBin[index].lotteryBookSale.endingSeq = this.booksInBin[index].lastTicketSold
            }
            this.booksInBin[index].lotteryBookSale.ticketsSold = this.booksInBin[index].lotteryBookSale.endingSeq - this.booksInBin[index].lotteryBookSale.startingSeq

            // console.log(index)
        },
    },

    mounted() {
        
    }
}
</script>