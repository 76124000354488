<template>
    <b-modal v-model="isShowPDFDialogLocal" scroll="keep" @close="closePDFDialog">
        <div class="card popupDialog">
            <div class="card-content">
                    <iframe id="pdf" class="pdfiFrame">
                        Your browser doesn't support iframes
                    </iframe> 
            </div>
        </div>
    </b-modal>        
</template>

<script>

import { PDFDocument, StandardFonts, PageSizes, degrees } from 'pdf-lib'

import moment from 'moment'
import UserStore from "../../../../store/UserStore"

export default {
    props: {
        isShowPDFDialog: Boolean,
        fetchStatus: String,
        filteredRecords: [],
        shiftDate: Date,
        shiftNumber: Number
    },

    data() {
        return {
            sortedRecords: [],
            isShowPDFDialogLocal: false,
            totalBookCount: 0,
            ticketSoldValue: 0,
            ticketSoldCount: 0,  
            dollarUSLocale: Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD'})
        }

    },

    methods: {

        closePDFDialog() {
            this.$emit('close-pdf-dialog')
        },

        createPdf: async(gthis) => {

            const pdfDoc = await PDFDocument.create();
            var page = pdfDoc.addPage(PageSizes.Letter);
            var pageNumber = 1

            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

            await gthis.pageHeader(page,helveticaFont)
            await gthis.veecliLogo(pdfDoc, page)
            var row = 670

            var lotteryCount = 0
            gthis.totalBookCount = 0
            gthis.ticketSoldValue = 0
            gthis.ticketSoldCount = 0               

            gthis.sortedRecords.forEach(function(lottery){

                if (lotteryCount% 35 == 0 && lotteryCount > 0) {

                    gthis.pageFooter(pdfDoc, page, pageNumber)
                    pageNumber++

                    page = pdfDoc.addPage(PageSizes.Letter)
                    gthis.pageHeader(page,helveticaFont)

                    // Set Line to start records
                    row = 670

                }

                var price = gthis.dollarUSLocale.format(lottery.pricePoint)

                gthis.totalBookCount ++
                gthis.ticketSoldCount += lottery.lotteryBookSale.ticketsSold 
                gthis.ticketSoldValue += lottery.pricePoint * lottery.lotteryBookSale.ticketsSold                

                page.drawText("[    ]  " + String(lottery.saleRackLoc).padStart(3, '0'), { 
                    x: 20, 
                    y: row, 
                    size: 10, 
                    })

                page.drawText(lottery.gameNumber.toString(), { 
                    x: 80, 
                    y: row, 
                    size: 10, 
                    })

                page.drawText(lottery.bookNumber.toString(), { 
                    x: 130, 
                    y: row, 
                    size: 10, 
                    })

                if (lottery.name === undefined) {
                    gameName = ""
                } else {
                    var gameName = lottery.name.toString().toLowerCase().substr(0, 25)
                    gameName = gameName.replace(/\b\w/g, function(c) {
                                    return c.toUpperCase();
                                })
                }

                page.drawText(gameName, { 
                    x: 195, 
                    y: row, 
                    size: 10, 
                    })

                textWidth = helveticaFont.widthOfTextAtSize(lottery.lotteryBookSale.startingSeq.toString(), 10)
                page.drawText(lottery.lotteryBookSale.startingSeq.toString(), { 
                    x: 355 - textWidth, 
                    y: row, 
                    size: 10, 
                    })                    


                textWidth = helveticaFont.widthOfTextAtSize(lottery.lotteryBookSale.endingSeq.toString(), 10)
                page.drawText(lottery.lotteryBookSale.endingSeq.toString(), { 
                    x: 395 - textWidth, 
                    y: row, 
                    size: 10, 
                    })

                textWidth = helveticaFont.widthOfTextAtSize(lottery.lotteryBookSale.ticketsSold.toString(), 10)
                page.drawText(lottery.lotteryBookSale.ticketsSold.toString(), { 
                    x: 438 - textWidth, 
                    y: row, 
                    size: 10, 
                    })  

                var textWidth = helveticaFont.widthOfTextAtSize(price, 10);

                page.drawText(price, { 
                    x: 500 - textWidth, 
                    y: row, 
                    size: 10,
                    TextAlignment: 2,
                    })

                var soldValue = lottery.lotteryBookSale.ticketsSold * lottery.pricePoint

                textWidth = helveticaFont.widthOfTextAtSize(soldValue.toString()  + "  [    ]", 10);

                page.drawText(gthis.dollarUSLocale.format(soldValue) + "  [    ]", { 
                    x: 570 - textWidth, 
                    y: row, 
                    size: 10,
                    TextAlignment: 2,
                    })                 

                row -= 15
                lotteryCount++

            });

            if (row < 50) {
                    gthis.pageFooter(pdfDoc, page, pageNumber)
                    pageNumber++

                    page = pdfDoc.addPage(PageSizes.Letter)
                    gthis.pageHeader(page,helveticaFont)

                    // Set Line to start records
                    row = 650                
            }

            gthis.pageTotal(page,row-3)
            gthis.pageFooter(pdfDoc,page,pageNumber)

            const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
            document.getElementById('pdf').src = pdfDataUri;
        },

        pageHeader(page,helveticaFont) {


            var storeName = UserStore.getters.getActiveStore.storeName
            var textWidth = helveticaFont.widthOfTextAtSize(storeName, 15);

            page.drawText(storeName, { 
                x: 300 - (textWidth/2), 
                y: 750, 
                size: 15, 
                })

            var reportHeading = "Shift Instant Lottery Sales"
            textWidth = helveticaFont.widthOfTextAtSize(reportHeading, 13)

            page.drawText(reportHeading, { 
                x: 300 - (textWidth/2), 
                y: 730, 
                size: 13, 
                })

            var reportSubHeading = moment(this.shiftDate).format('MM/DD/YYYY') + " - Shift " + this.shiftNumber.toString()

            textWidth = helveticaFont.widthOfTextAtSize(reportSubHeading, 13)
            
            page.drawText(reportSubHeading , { 
                x: 300 - (textWidth/2), 
                y: 710, 
                size: 12, 
                })


            var row = 690
            // Print Column Heading
            page.drawText('Bin', { 
                x: 40, 
                y: row, 
                size: 11, 
                })

            page.drawText('Game', { 
                x: 80, 
                y: row, 
                size: 11, 
                })

            page.drawText('Book', { 
                x: 130, 
                y: row, 
                size: 11, 
                })

            page.drawText('Name', { 
                x: 195, 
                y: row, 
                size: 11, 
                })

            page.drawText("Start", { 
                x: 330, 
                y: row, 
                size: 10, 
                })                    

            page.drawText('End', { 
                x: 375, 
                y: row, 
                size: 11, 
                })

            page.drawText('Sold', { 
                x: 418, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText('Price', { 
                x: 475, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText('Total', { 
                x: 542, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            row -= 4
            page.drawLine({
                start: { x: 10, y: row },
                end: { x: 600, y: row },
                thickness: 1,
                opacity: 0.75,
            })                
                
        },

        veecliLogo: async(pdfDoc, page) => {
            const pngUrl = '/images/veecli-logo.png'
            const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer())

            const pngImage = await pdfDoc.embedPng(pngImageBytes)
            const pngDims = pngImage.scale(0.08)
            
            page.drawImage(pngImage, {
                x: 300,
                y: 18,
                width: pngDims.width,
                height: pngDims.height,
                rotate: degrees(0),
                // opacity: 0.75,
            })
            page.drawText(" VEECLi.com", { 
                x: 308, 
                y: 20, 
                size: 10,
                TextAlignment: 2
                })                    
        },

        pageFooter(pdfDoc, page, pageNumber) {

            let timeNow = Date()
            timeNow = moment(timeNow).format('MM/DD/YYYY hh:mm a')

            page.drawText(timeNow, { 
                x: 20, 
                y: 20, 
                size: 10,
                TextAlignment: 2
                })

            page.drawText('Page ' + pageNumber, { 
                x: 540, 
                y: 20, 
                size: 10,
                TextAlignment: 2
                })

        },
        
        pageTotal(page,row) {

            page.drawLine({
                start: { x: 10, y: row },
                end: { x: 600, y: row },
                thickness: 1,
                opacity: 0.75,
            })  
            row -= 15

            page.drawText('Book Count: ' + this.totalBookCount.toString(), { 
                x: 180, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText('Tickets Sold: ' + this.ticketSoldCount.toString(), { 
                x: 300, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            page.drawText('Total Sales: ' + this.dollarUSLocale.format(this.ticketSoldValue), { 
                x: 450, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })


            row -= 10
            page.drawLine({
                start: { x: 10, y: row },
                end: { x: 600, y: row },
                thickness: 1,
                opacity: 0.75,
            })  

        },

        loadData() {
            // this.sortedRecords = this.filteredRecords.sort(function(a, b){     // => [ 4, 33, 222, 1111 ]
            //     return (a.gameNumber+a.bookNumber)-(b.gameNumber+b.bookNumber)
            // })
            this.sortedRecords = this.filteredRecords.sort(function(a, b){     // => [ 4, 33, 222, 1111 ]
                return (a.saleRackLoc)-(b.saleRackLoc)
            })            
            this.isShowPDFDialogLocal = true
            this.createPdf(this)
        }
    },
  
}
</script>


<style scoped>

.popupDialog {
    /* position:absolute; */
    width:100%;
    height: 100%; 
    max-height: 1000px;
    min-height: 900px;     
}

.pdfiFrame {
    position:absolute; 
    top:0; 
    left:0; 
    bottom:0; 
    /* right:0;  */
    width:100%; 
    height:100%; 
    /* border:none; */
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
    /* padding:0;  */
    overflow:hidden; 
    /* z-index:999999;    */
}
div{
    height: 100%;
}
</style>