<template>
    <div class="panel has-text-weight-semibold"  id="ReportContent">
        <b-loading v-model="isLoading" >
            <b-icon
                pack="fas"
                icon="sync-alt"
                size="is-large"
                custom-class="fa-spin">
            </b-icon>                
        </b-loading>
        
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-11 has-text-centered">SHIFT &nbsp;&nbsp;PAPERWORK</div>
                <div class="column has-text-right is-1  is-hidden-mobile" v-if="!isPrinting">
                    <b-button  title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="printDaysReport">
                        <i class="fas fa-print"></i>
                    </b-button>
                </div>                    
            </div>
        </div>

        <div class="columns">
            <div class="column is-3" v-if="!isPrinting && userRole != 'ACCOUNTANT'">
                <b-field label="Date"> 
                    <b-datepicker
                        ref="shiftDatePicker"
                        :class="hideDate"
                        :mobile-native="isMobileNativeDatePicker"
                        :disabled="isEditing"
                        :min-date="minShiftDate"
                        :max-date="maxShiftDate"
                        icon="calendar"
                        placeholder="Click to select..."
                        v-model="shiftDate">
                    </b-datepicker>
                    <b-button
                @click="datePickerToggle()"
                icon-left="calendar"
                type="is-default" :class="hideDateButton">{{ shiftDate | formatDate }}</b-button>
                    <tool-tip>
                        <div slot="title">
                        Shift Date
                        </div>
                        <div slot="content">
                        Select the <b>Shift Date</b> to view and modify the income, expenses, cash, lottery information for each shift for the day.
                        </div>
                        <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                    </tool-tip>
                </b-field>
            </div>
            <div class="column is-3">
                <br>
                <b-switch
                    v-if="userRole != 'ACCOUNTANT' && userRole != 'STAFF'"
                    :disabled="isEditing"
                    :true-value="true"
                    :false-value="false"
                    size="is-small"
                    v-model="hideZeroShift"
                    type="is-success">
                        Hide Shifts with 0.00 Sales
                </b-switch>
                <b-switch
                    v-if="userRole != 'ACCOUNTANT' && userRole != 'STAFF'"
                    :disabled="isEditing"
                    :true-value="true"
                    :false-value="false"
                    size="is-small"
                    v-model="showRegisterData"
                    type="is-success">
                        Show Register Details
                </b-switch>
            </div>
            <div class="column is-6" v-if="isEditing">
                &nbsp;
            </div>
        </div>

        <div class="is-size-5 has-text-centered has-text-weight-bold" v-if="dailyShifts.length == 0">
            No Shift Data Found for {{ shiftDate | formatDate }}
        </div>

        <b-tabs  id="ReportContent2" size="is-medium" position="is-left" type="is-boxed" v-model="activeTab" v-if="dailyShifts.length > 0">

            <b-tab-item :disabled="isEditing" v-for="(shift, index) in dailyShifts" :key="index" :label="'Shift ' + shiftNumber(index)">
                <!-- {{ reCalculate(index) }} -->

                <VeeSavePane
                    :row1="formatDate(shiftDate) + ' - SHIFT  ' + shiftNumber(index)"
                    :cancelEnable="isEditing"
                    :saveEnable="isEditing"
                    :isEditing="isEditing"
                    @cancel-edit="cancelEdit()"
                    @save-edit="submitForm(index)"
                    >

                </VeeSavePane>

                <!-- <div class="save-bottons-div has-background-info-light" v-if="isEditing">
                    <div class="save-div-item has-text-white has-background-info">
                        <span class="has-text-centered">{{ getActiveStore.storeName }}</span>
                    </div>
                    <div class="save-div-item is-size-7 has-text-centered">
                        {{ shiftDate | formatDate }} - SHIFT:  {{ shiftNumber(index) }}
                    </div>
                    <div class="save-div-item has-text-weight-bold is-size-7 has-text-centered">
                        UPDATES NOT SAVED
                    </div>
                    <div class="save-div-item has-text-centered">
                        <button class="button is-default" type="button" @click="cancelEdit()" :disabled='! isEditing'>Cancel</button>
                        &nbsp;&nbsp;
                        <button class="button is-success" type="button" @click="submitForm(index)" :disabled='! isEditing || shift.employeeId == 0'>Save</button>
                    </div>
                </div> -->

                <div class="columns">
 
                    <div class="column">
                        

                        <!-- <div class="box"> -->
                           <form action="" @submit.prevent="submitForm">

                            <div class="is-size-5 has-text-centered has-text-weight-bold">
                                DATE: &nbsp;&nbsp;{{ shiftDate | formatDate }} - SHIFT:  {{ shiftNumber(index) }}
                            </div>

                            <div class="has-text-centered">
                                {{ shift.periodBeginDate | formatDateTime }} - {{ shift.periodEndDate | formatDateTime }}
                            </div>

                            <div class="columns" v-if="isEditing && isPrinting">
                                <div class="column has-text-centered">
                                    <footer class="modal-card-foot">
                                        ***&nbsp;CONTENT &nbsp;INCLUDES &nbsp;UNSAVED &nbsp;INFORMATION. &nbsp;&nbsp;PLEASE &nbsp;SAVE &nbsp;CHANGES &nbsp;AND &nbsp;REPRINT.
                                    </footer>                                
                                </div>                            
                            </div>

                            <div class="columns is-fullwidth" :class="{'is-hidden': isPrinting}">
                                <div class="column is-9">
                                    <b-message v-if="shift.shiftSavedBefore && !shift.salesFinalized"
                                        
                                        type="is-warning" 
                                        has-icon
                                        :closable="alertClosable"
                                        >
                                        Shift Updated Previously! <br>
                                        Confirm Date & Closing time before updating. 
                                    </b-message>
                                    <b-message type="is-success" has-icon v-if="!shift.shiftSavedBefore && !shift.salesFinalized">
                                        Shift not updated Previously.<br>
                                        Confirm Date & Closing time before updating.
                                    </b-message>
                                    <b-message type="is-danger" has-icon v-if="shift.salesFinalized">
                                        Shift finalized. <br>Editing not allowed.
                                    </b-message>
                                </div>
                                <div class="column is-3 has-text-right">
                                    <br>
                                    <b-switch
                                        v-if="userRole != 'ACCOUNTANT' && userRole != 'STAFF'"
                                        :true-value="trueValue"
                                        :false-value="falseValue"
                                        @input="reCalculate(index)"
                                        size="is-small"
                                        v-model="shift.salesFinalized"
                                        passive-type="is-success"
                                        type="is-danger">
                                            <span v-if="shift.salesFinalized">Shift Locked</span>
                                            <span v-if="!shift.salesFinalized">Editing Allowed</span>
                                    </b-switch>
                                </div>   
                            </div>

                            <div class="columns is-fullwidth" :class="{'is-hidden': !isPrinting}">
                                <div class="column has-text-centered">
                                    <span v-if="shift.salesFinalized">*** &nbsp;&nbsp;SHIFT &nbsp;&nbsp;FINALIZED &nbsp;&nbsp;***</span>
                                    <span v-if="!shift.salesFinalized">*** &nbsp;&nbsp;SHIFT &nbsp;&nbsp;NOT &nbsp;&nbsp;FINALIZED &nbsp;&nbsp;***</span>                                   
                                </div>
                            </div>

                            <div class="columns is-mobile is-fullwidth">
                                <div class="column has-text-left is-4 ">
                                    <b-field label="Employee">
                                    <b-select  :disabled="!allowEdit || shift.salesFinalized" placeholder="Select Employee" @input="reCalculate(index)" v-model="shift.employeeId">
                                        <option
                                            v-for="option in employees"
                                            :value="option.id"
                                            :key="option.id">
                                            {{ option.firstName }} {{ option.lastName }} 
                                        </option>
                                    </b-select>
                                    </b-field>
                                </div>
                                <!-- 
                                <div class="column is-8">
                                    <b-message v-if="shift.shiftSavedBefore && !shift.salesFinalized"
                                        
                                        type="is-warning" 
                                        has-icon
                                        :closable="alertClosable"
                                        >
                                        Shift Updated Previosly! <br>
                                        Confirm Date & Closing time before updating.
                                    </b-message>
                                    <b-message type="is-success" has-icon v-if="!shift.shiftSavedBefore && !shift.salesFinalized">
                                        Shift not updated previosly.<br>
                                        Confirm Date & Closing time before updating.
                                    </b-message>
                                    <b-message type="is-danger" has-icon v-if="shift.salesFinalized">
                                        Shift finalized. <br>Editing not allowed.
                                    </b-message>
                                </div> -->
                                <!-- <div class="column is-one-quarter has-text-right">
                                </div>                                 
                                -->
                            </div>

                            <div class="columns is-fullwidth is-mobile veecli-table">
                                <div class="column is-fullwidth">
                                    
                                </div>
                            </div>

                            <!-- Other Income -->
                            <shift-income-table
                                v-if="userRole != 'ACCOUNTANT' && userRole != 'STAFF'"
                                :shift="shift"
                                :isPrinting="isPrinting"
                                :index="index"
                                :user="user"
                                :vendors="vendors"
                                :allowEdit="allowEdit && !shift.salesFinalized"
                                :date="shiftDate"
                                @save="reCalculate"
                                >
                            </shift-income-table>                            

                            <!-- Expenses -->
                            <shift-expense-table
                                v-if="userRole != 'ACCOUNTANT' && userRole != 'STAFF'"
                                :shift="shift"
                                :isPrinting="isPrinting"
                                :index="index"
                                :user="user"
                                :vendors="vendors"
                                :employees="employees"
                                :expenseTypes="expenseTypes"                                
                                :allowEdit="allowEdit && !shift.salesFinalized"
                                :date="shiftDate"
                                @save="reCalculate"
                                >
                            </shift-expense-table>

                            <!-- Lottery Sales -->
                            <shift-lottery-table
                                v-if="trackInstantLotteryBooks.value == 1"
                                ref="LotteryContent"
                                :shift="shift"
                                :isPrinting="isPrinting"
                                :shiftBeforeUpdate="shiftsBeforeUpdate[activeTab]"
                                :index="index"
                                :user="user"
                                :allowEdit="allowEdit && !shift.salesFinalized"
                                :shiftDate="shiftDate"
                                @save="reCalculate"
                                @print="startPrinting"
                                >
                            </shift-lottery-table>
                            <div class="panel" v-if="userRole != 'ACCOUNTANT'">
                                <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                                    <div class="columns">
                                        <div class="column is-size-6 has-text-centered"> LOTTERY &nbsp;&nbsp;SUMMARY </div>
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <div class="control columns is-mobile">
                                        <div class="column is-7"> Instant Lottery Sales </div>
                                        <div class="column is-5 has-text-right"  v-if="trackInstantLotteryBooks.value == 0">
                                            <div class="field">
                                                <VeeInput
                                                    type="currency" 
                                                    v-model="shift.instantLottoSales"
                                                    name="instantLottoSales"
                                                    :value="shift.instantLottoSales"
                                                    :countryCurrencyCode="countryCurrencyCode"
                                                    :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                    @input="reCalculate(index)"
                                                    />
                                            </div>
                                        </div>
                                        <div v-else class="column is-5 has-text-right" :class="{'has-text-danger': shift.instantLottoSales < 0.00 }">
                                            {{ shift.instantLottoSales  | formatCurrency }}
                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block">
                                    <div class="control columns is-mobile row-even-background">
                                        <div class="column is-7"> Instant Lottery Payout </div>
                                        <div class="column is-5 has-text-right">
                                            <div class="field">
                                                <VeeInput
                                                    type="currency" 
                                                    v-model="shift.instantLottoPayout"
                                                    name="instantLottoPayout"
                                                    :value="shift.instantLottoPayout"
                                                    :countryCurrencyCode="countryCurrencyCode"
                                                    :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                    @input="reCalculate(index)"
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block">
                                    <div class="control columns is-mobile">
                                        <div class="column is-7"> Online Lotto Sales </div>
                                        <div class="column is-5 has-text-right">
                                            <div class="field">
                                                <VeeInput
                                                    type="currency" 
                                                    v-model="shift.onlineLottoSales"
                                                    name="onlineLottoSales"
                                                    :value="shift.onlineLottoSales"
                                                    :countryCurrencyCode="countryCurrencyCode"
                                                    :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                    @input="reCalculate(index)"
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                                            
                                <div class="panel-block">
                                    <div class="control columns  is-mobile row-even-background">
                                        <div class="column is-7"> Included in POS Cash / Online Cancel  </div>
                                        <div class="column is-5 has-text-right">
                                            <div class="field">
                                                <VeeInput
                                                    type="currency" 
                                                    v-model="shift.onlineLotteryCanceled"
                                                    name="onlineLotteryCanceled"
                                                    :value="shift.onlineLotteryCanceled"
                                                    :countryCurrencyCode="countryCurrencyCode"
                                                    :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                    @input="reCalculate(index)"
                                                    />

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block">
                                    <div class="control columns is-mobile">
                                        <div class="column is-7"> Online Lotto Payout </div>
                                        <div class="column is-5 has-text-right">
                                            <div class="field">
                                                <VeeInput
                                                    type="currency" 
                                                    v-model="shift.onlineLottoPayout"
                                                    name="onlineLottoPayout"
                                                    :value="shift.onlineLottoPayout"
                                                    :countryCurrencyCode="countryCurrencyCode"
                                                    :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                    @input="reCalculate(index)"
                                                    />
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block">
                                    <div class="control columns is-mobile row-even-background">
                                        <div class="column is-7"> Machine Instant Lottery Sales 
                                        <tool-tip>
                                            <div slot="title" class="help-tooltip-header">
                                            Machine Instant Lottery Sales
                                            </div>
                                            <div slot="content" class="help-tooltip-content">
                                                Cash taken from the machine or machine instant lottery sales. This amount will be included in the cash balance  
                                            </div>                                              
                                            <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                        </tool-tip>
                                        </div>
                                        <div class="column is-5 has-text-right">
                                            <div class="field">
                                                <VeeInput
                                                    type="currency" 
                                                    v-model="shift.lotteryMachineWithdraw"
                                                    name="lotteryMachineWithdraw"
                                                    :value="shift.lotteryMachineWithdraw"
                                                    :countryCurrencyCode="countryCurrencyCode"
                                                    :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                    @input="reCalculate(index)"
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="panel-heading has-text-weight-bold">
                                    <div class="control columns is-mobile">
                                        <div class="column is-7"> Net Lottery Cash</div>
                                        <div class="column is-5 has-text-right" :class="{'has-text-danger': netLottery < 0.00 }">

                                           {{ netLottery | formatCurrency }} 

                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block">
                                    <div class="control columns is-mobile row-even-background">
                                        <div class="column is-7"> Machine Lottery Cash Taken 
                                        <tool-tip>
                                            <div slot="title" class="help-tooltip-header">
                                            Machine Lottery Cash Taken
                                            </div>
                                            <div slot="content" class="help-tooltip-content">
                                                Machine Lottery Cash Taken. Amount will not be included in cash balance. Amount will be used for Lottery Audit. 
                                            </div>                                              
                                            <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                        </tool-tip>
                                        </div>
                                        <div class="column is-5 has-text-right">
                                            <div class="field">
                                                <VeeInput
                                                    type="currency" 
                                                    v-model="shift.lotteryMachineSold"
                                                    name="lotteryMachineSold"
                                                    :value="shift.lotteryMachineSold"
                                                    :countryCurrencyCode="countryCurrencyCode"
                                                    :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                    @input="reCalculate(index)"
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Cash Reconciliation -->

                            <div class="panel">
                                <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                                    <div class="columns is-mobile">
                                        <div class="column is-size-6 has-text-centered"> CASH &nbsp;&nbsp;BALANCE </div>
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <div class="control columns is-mobile">
                                        <div class="column is-7"> Cash Sales </div>
                                        <div class="column is-5 has-text-right" :class="{'has-text-danger': shift.cashAmount < 0.00 }">

                                           {{ shift.cashAmount | formatCurrency }}

                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block">
                                    <div class="control columns is-mobile row-even-background">
                                        <div class="column is-7"> Cash Refund </div>
                                        <div class="column is-5 has-text-right" :class="{'has-text-danger': shift.cashRefundAmount > 0.00 }">

                                           {{ (shift.cashRefundAmount > 0.00 ? shift.cashRefundAmount * -1 : shift.cashRefundAmount) | formatCurrency }}

                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="panel-heading">
                                    <div class="control columns is-mobile has-text-weight-bold">
                                        <div class="column is-7"> Net Cash Sales </div>
                                        <div class="column is-5 has-text-right" :class="{'has-text-danger': ( shift.cashAmount - shift.cashRefundAmount ) < 0.00 }">

                                           {{ ( shift.cashAmount - shift.cashRefundAmount ) | formatCurrency }}

                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block" v-if="shift.safedropCashAmount != null && shift.safedropCashAmount != 0">
                                    <div class="control columns is-mobile">
                                        <div class="column is-7">Cash Safe Drop</div>
                                        <div class="column is-5 has-text-right" :class="{'has-text-danger': (shift.safedropCashAmount) > 0.00 }">
                                            {{ shift.safedropCashAmount * -1 | formatCurrency}}
                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block">
                                    <div class="control columns is-mobile row-even-background">
                                        <div class="column is-7"> Net Lottery </div>
                                        <div class="column is-5 has-text-right" :class="{'has-text-danger': netLottery < 0.00 }">

                                           {{ netLottery | formatCurrency }}

                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block" v-if="userRole != 'ACCOUNTANT' && userRole != 'STAFF'">
                                    <div class="control columns is-mobile">
                                        <div class="column is-7"> Cash Received - Income </div>
                                        <div class="column is-5 has-text-right" :class="{'has-text-danger': shift.totalCashIncome < 0.00 }">

                                           {{ cashAdditionalIncome | formatCurrency}}

                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block" v-if="userRole != 'ACCOUNTANT' && userRole != 'STAFF'">
                                    <div class="control columns is-mobile row-even-background">
                                        <div class="column is-7"> Cash Paid - Expenses</div>
                                        <div class="column is-5 has-text-right" :class="{'has-text-danger': cashExpensePayments > 0.00 }">
                                            {{ (cashExpensePayments > 0.00) ? cashExpensePayments * -1 : cashExpensePayments | formatCurrency}}
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="panel-heading" v-if="userRole != 'ACCOUNTANT' && userRole != 'STAFF'">
                                    <div class="control columns is-mobile has-text-weight-bold">
                                        <div class="column is-7">Cash Book Balance
                                            <tool-tip>
                                                <div slot="title" class="help-tooltip-header">
                                                Cash Book Balance
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Expected total cash with the cashier.  
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Total includes cash from sales, lottery and additional income.  Safe drop amount and cash paid for expenses is deducted from the total. 
                                                </div>                                               
                                                <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                            </tool-tip>
                                        </div>
                                        <div class="column is-5 has-text-right" :class="{'has-text-danger': cashBookBalance < 0.00 }">
                                            {{ cashBookBalance | formatCurrency}}
                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block">
                                    <div class="control columns is-mobile">
                                        <div class="column is-7">Non-Cash in Book Balance
                                            <tool-tip>
                                                <div slot="title" class="help-tooltip-header">
                                                Non-Cash Onhand
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Enter actual Non-Cash on hand included in <b>Cash Book Balance</b>.
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Do not include Non-Cash amount not included in <b>Cash Book Balance</b>.
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                     Use notes to document non-cash amounts and details. 
                                                </div>                                                
                                                <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                            </tool-tip>
                                        </div>
                                        <div class="column is-5 has-text-right">
                                            <div class="field">
                                                <div class="control is-clearfix">
                                                    <VeeInput
                                                        type="currency" 
                                                        v-model="shift.checkActualOnhand"
                                                        name="checkActualOnhand"
                                                        :value="shift.checkActualOnhand"
                                                        :countryCurrencyCode="countryCurrencyCode"
                                                        :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                        @input="reCalculate(index)"
                                                        />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block">
                                    <div class="control columns is-mobile row-even-background">
                                        <div class="column is-7">Actual Cash Onhand
                                            <tool-tip>
                                                <div slot="title" class="help-tooltip-header">
                                                Cash Onhand
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Enter actual cash on hand including lottery.
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    If any non-cash amount included in the <b>Cash Book Balance</b>, enter it in <b>Non-Cash in Book Balance</b>.
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Use notes to document non-cash amounts. 
                                                </div>                                                
                                                <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                            </tool-tip>
                                        </div>
                                        <div class="column is-5 has-text-right">
                                            <div class="field">
                                                <div class="control is-clearfix">
                                                    <VeeInput
                                                        type="currency" 
                                                        v-model="shift.cashActualOnhand"
                                                        name="cashActualOnhand"
                                                        :value="shift.cashActualOnhand"
                                                        :countryCurrencyCode="countryCurrencyCode"
                                                        :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                        @input="reCalculate(index)"
                                                        />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel-heading has-text-weight-bold" v-if="userRole != 'ACCOUNTANT' && userRole != 'STAFF'">
                                    <div class="control columns is-mobile is-size-3 has-text-weight-bold is-size-5">
                                        <div class="column is-7 has-text-danger" v-if="cashOverOrShort < 0.00">Cash Short</div>
                                        <div class="column is-7 has-text-info" v-if="cashOverOrShort > 0.00">Cash Over</div>
                                        <div class="column is-7 has-text-success" v-if="cashOverOrShort == 0.00">Cash Balanced</div>
                                        <div class="column is-5 is-size-5 has-text-weight-bold has-text-right"  :class="{'has-text-danger has-background-warning': cashOverOrShort < 0.00, 'has-text-info': cashOverOrShort > 0.00, 'has-text-success': cashOverOrShort == 0.00   }">

                                            {{ cashOverOrShort | formatCurrency}}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel" v-if="userRole != 'ACCOUNTANT' && userRole != 'STAFF'">
                                <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                                    <div class="columns is-mobile">
                                        <div class="column is-size-6 has-text-centered"> CASH DEPOSIT & DISBURSMENTS </div>
                                    </div>
                                </div>

                                <div class="panel-block">
                                    <div class="control columns is-mobile">
                                        <div class="column is-6"> Bank Deposit General
                                            <tool-tip>
                                                <div slot="title" class="help-tooltip-header">
                                                Deposit General
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Enter total Cash Deposits to General Bank Account.
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Include cash deposited from Safe and Cash Onhand.
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Only Cash deposit must be entered since it is used to calculate cash balances.
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Use notes to document non-cash deposits. 
                                                </div>                                                
                                                <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                            </tool-tip>
                                        </div>
                                        <div class="column is-3 has-text-right">
                                            <b-field label="Notes" label-position="on-border">
                                                <VeeInput
                                                    type="text" 
                                                    v-model="shift.depositGeneralNotes"
                                                    name="depositGeneralNotes"
                                                    :maxlength="depositNotesLength"
                                                    :value="shift.depositGeneralNotes"
                                                    :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                    @input="reCalculate(index)"
                                                    />
                                            </b-field>
                                            
                                        </div>
                                        <div class="column is-3 has-text-right">
                                            <div class="field">
                                                <div class="control is-clearfix">
                                                    <VeeInput
                                                        type="currency" 
                                                        v-model="shift.bankDepositGeneral"
                                                        name="bankDepositGeneral"
                                                        :value="shift.bankDepositGeneral"
                                                        :countryCurrencyCode="countryCurrencyCode"
                                                        :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                        @input="reCalculate(index)"
                                                        />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block">
                                    <div class="control columns is-mobile row-even-background">
                                        <div class="column is-6"> Bank Deposit Other
                                            <tool-tip>
                                                <div slot="title" class="help-tooltip-header">
                                                Deposit Other
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Enter total Cash Deposits to Other/Lottery Bank Account.
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Include cash deposited from Safe and Cash Onhand.
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Only Cash deposit must be entered since it is used to calculate cash balances.
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Use notes to document non-cash deposits. 
                                                </div>                                                
                                                <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                            </tool-tip>
                                        </div>
                                        <div class="column is-3 has-text-right">
                                            <b-field label="Notes" label-position="on-border">
                                                <VeeInput
                                                    type="text" 
                                                    v-model="shift.depositLotteryNotes"
                                                    name="depositLotteryNotes"
                                                    :maxlength="depositNotesLength"
                                                    :value="shift.depositLotteryNotes"
                                                    :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                    @input="reCalculate(index)"
                                                    />
                                            </b-field>
                                        </div>
                                        <div class="column is-3 has-text-right">
                                            <div class="field">
                                                <div class="control is-clearfix">
                                                    <VeeInput
                                                        type="currency" 
                                                        v-model="shift.bankDepositLottery"
                                                        name="bankDepositLottery"
                                                        :value="shift.bankDepositLottery"
                                                        :countryCurrencyCode="countryCurrencyCode"
                                                        :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                        @input="reCalculate(index)"
                                                        />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block">
                                    <div class="control columns is-mobile">
                                        <div class="column is-6"> Other Cash Disbursements
                                            <tool-tip>
                                                <div slot="title" class="help-tooltip-header">
                                                Disbursements
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    <b>Other Cash Disbursements</b> may include profit sharing or dividents paid to owners and partners.
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    May also use to report cash loss or theft or any other removal of cash and not deposited in bank.  
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Use notes to document details about the disbursements. 
                                                </div>                                                
                                                <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                            </tool-tip>
                                        </div>
                                        <div class="column is-3 has-text-right">
                                            <b-field label="Notes" label-position="on-border">
                                                <VeeInput
                                                    type="text" 
                                                    v-model="shift.depositAtmNotes"
                                                    name="depositAtmNotes"
                                                    :maxlength="depositNotesLength"
                                                    :value="shift.depositAtmNotes"
                                                    :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                    @input="reCalculate(index)"
                                                    />
                                            </b-field>
                                        </div>
                                        <div class="column is-3 has-text-right">
                                            <div class="field">
                                                <div class="control is-clearfix">
                                                    <VeeInput
                                                        type="currency" 
                                                        v-model="shift.bankDepositAtm"
                                                        name="bankDepositAtm"
                                                        :value="shift.bankDepositAtm"
                                                        :countryCurrencyCode="countryCurrencyCode"
                                                        :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                        @input="reCalculate(index)"
                                                        />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block">
                                    <div class="control columns is-mobile">
                                        <div class="column is-6"> Cash Loaded in ATM
                                            <tool-tip>
                                                <div slot="title" class="help-tooltip-header">
                                                LOADED IN ATM
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Any cash loaded in ATM.  
                                                </div>                                               
                                                <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                            </tool-tip>
                                        </div>
                                        <div class="column is-3 has-text-right">
                                            <!-- <b-field label="Notes" label-position="on-border">
                                                <VeeInput
                                                    type="text" 
                                                    v-model="shift.depositAtmNotes"
                                                    name="depositAtmNotes"
                                                    :maxlength="depositNotesLength"
                                                    :value="shift.depositAtmNotes"
                                                    :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                    @input="reCalculate(index)"
                                                    />
                                            </b-field> -->
                                        </div>
                                        <div class="column is-3 has-text-right">
                                            <div class="field">
                                                <div class="control is-clearfix">
                                                    <VeeInput
                                                        type="currency" 
                                                        v-model="shift.cashLoadedInAtm"
                                                        name="bankDepositAtm"
                                                        :value="shift.cashLoadedInAtm"
                                                        :countryCurrencyCode="countryCurrencyCode"
                                                        :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                        @input="reCalculate(index)"
                                                        />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel-heading">
                                    <div class="control columns is-mobile has-text-weight-bold">
                                        <div class="column is-7" v-if="cashOnHandOrSafe >= 0.00">On Hand after deposit
                                            <tool-tip>
                                                <div slot="title" class="help-tooltip-header">
                                                Cash After Deposit
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Total Cash from the shift left at the store after deposits. Includes cash in Safe.
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Does not include cash held from previous shifts. See <b>Daily Cash</b> from menu for accumulated totals.
                                                </div>
                                                <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                            </tool-tip>
                                        </div>
                                        <div class="column is-7" v-else>Prior day's cash used today</div>
                                        <div class="column is-5 has-text-right" :class="{'has-text-danger': cashOnHandOrSafe < 0.00 }">

                                           {{ cashOnHandOrSafe | formatCurrency }}
                                               
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <!-- Cigarette Tracking -->

                            <div class="panel">
                                <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                                    <div class="columns is-mobile">
                                        <div class="column is-size-6 has-text-centered"> CIGARETTE 
                                        <a href="#" @click="unlockClicked"> <b-icon
                                            v-if="!overrideAllowed && shift.isFutureCigSalesAvailable && !shift.salesFinalized && overridePassword"
                                            pack="fas"
                                            type="is-danger"
                                            icon="unlock"
                                            title="Unlock"
                                            size="is-small"
                                            >
                                        </b-icon></a>
                                        </div>

                                    </div>
                                </div>
                                 
                                <div class="panel-block">
                                    <div class="control columns is-mobile">
                                        <div class="column is-7"> Cigarette Open Count
                                            <tool-tip>
                                                <div slot="title" class="help-tooltip-header">
                                                Open Count
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    <b>Open Count</b> carried forward from previous shift <b>Close Count</b> automatically.
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Please ensure <b>Close Count</b> is entered in every shift.
                                                </div>
                                                
                                                <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                            </tool-tip>
                                        </div>
                                        <div class="column is-5 has-text-right" :class="{'has-text-danger': shift.previousCigSales.closeCount < 0.00 }">

                                           {{ shift.previousCigSales.closeCount }}

                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block">
                                    <div class="control columns is-mobile row-even-background">
                                        <div class="column is-7"> Cigarette Buy Count
                                            <tool-tip>
                                                <div slot="title" class="help-tooltip-header">
                                                Buy Count
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Enter the number of Cigarette Packs purchased during this shift for count calculation.
                                                </div>
                                                <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                            </tool-tip>
                                        </div>
                                        <div class="column is-5 has-text-right">
                                            <div class="field">
                                                <div class="control is-clearfix">
                                                    <VeeInput
                                                        type="number" 
                                                        v-model="shift.cigSales.buyCount"
                                                        name="buyCount"
                                                        :value="shift.cigSales.buyCount"
                                                        :countryCurrencyCode="countryCurrencyCode"
                                                        :editable="(allowEdit && (!shift.isFutureCigSalesAvailable || overrideAllowed) && !isPrinting && !shift.salesFinalized)"
                                                        @input="reCalculate(index)"
                                                        />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block">
                                    <div class="control columns is-mobile">
                                        <div class="column is-7"> Cigarette Sales Count
                                            <tool-tip>
                                                <div slot="title" class="help-tooltip-header">
                                                Sales Count
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    <b>Sales Count</b> only includes sales for current shift.
                                                </div>
                                                <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                            </tool-tip>
                                        </div>
                                        <div class="column is-5 has-text-right" :class="{'has-text-danger': shift.cigaretteSaleCount < 0.00 }">

                                           {{ shift.cigaretteSaleCount }}

                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block">
                                    <div class="control columns is-mobile row-even-background">
                                        <div class="column is-7"> Cigarette Balance Count
                                            <tool-tip>
                                                <div slot="title" class="help-tooltip-header">
                                                Variance
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Shows <b>Open Count</b> + <b>Buy Count</b> - <b>Sales Count</b>
                                                </div>
                                                <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                            </tool-tip>
                                        </div>
                                        <div class="column is-5 has-text-right" :class="{'has-text-danger': cigsCountBalance < 0.00 }">

                                            {{ cigsCountBalance }}

                                        </div>
                                    </div>
                                </div>    

                                <div class="panel-block">
                                    <div class="control columns is-mobile">
                                        <div class="column is-7"> Cigarette Close Count
                                            <tool-tip>
                                                <div slot="title" class="help-tooltip-header">
                                                Close Count
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Enter <b>Close Count</b> after counting cigarette.
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Cigarette data for previous shifts cannot be modified once you enter <b>Close Count</b>
                                                </div>
                                                <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                            </tool-tip>
                                        </div>
                                        <div class="column is-5 has-text-right">
                                            <div class="field">
                                                <div class="control is-clearfix">
                                                    <VeeInput
                                                        type="number" 
                                                        v-model="shift.cigSales.closeCount"
                                                        name="closeCount"
                                                        :value="shift.cigSales.closeCount"
                                                        :countryCurrencyCode="countryCurrencyCode"
                                                        :editable="(allowEdit && (!shift.isFutureCigSalesAvailable || overrideAllowed) && !isPrinting && !shift.salesFinalized)"
                                                        @input="reCalculate(index)"
                                                        />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        
                                <div class="panel-block">
                                    <div class="control columns is-mobile row-even-background">
                                        <div class="column is-7"> Cigarette Count Variance
                                            <tool-tip>
                                                <div slot="title" class="help-tooltip-header">
                                                Cigarette Count Variance
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Shows the difference between <b>Balance Count</b> and <b>Close Count</b>
                                                </div>
                                                <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                            </tool-tip>
                                        </div>
                                        <div class="column is-5 has-text-right" :class="{'has-text-danger': cigsCountVariance < 0.00 }">

                                           {{ cigsCountVariance }}

                                        </div>
                                    </div>
                                </div>

                                <div class="panel-block">
                                    <div class="control columns is-mobile">
                                        <div class="column is-7"> Cigarette Buy Amount
                                            <tool-tip>
                                                <div slot="title" class="help-tooltip-header">
                                                Buy Amount
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Enter the total amount for the Cigarette purchase.
                                                </div>
                                                <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                            </tool-tip>
                                        </div>
                                        <div class="column is-5 has-text-right">
                                            <div class="field">
                                                <div class="control is-clearfix">
                                                    <VeeInput
                                                        type="currency" 
                                                        v-model="shift.cigSales.buyAmount"
                                                        name="buyAmount"
                                                        :value="shift.cigSales.buyAmount"
                                                        :countryCurrencyCode="countryCurrencyCode"
                                                        :editable="(allowEdit && (!shift.isFutureCigSalesAvailable || overrideAllowed) && !isPrinting && !shift.salesFinalized)"
                                                        @input="reCalculate(index)"
                                                        />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <div class="control columns is-mobile row-even-background">
                                        <div class="column is-7"> Cigarette Buy Cost per Pack
                                            <tool-tip>
                                                <div slot="title" class="help-tooltip-header">
                                                Cigarette Buy Cost per Pack
                                                </div>
                                                <div slot="content" class="help-tooltip-content">
                                                    Average cost for each cigarette pack
                                                </div>
                                                <span>&nbsp;<i class="fas fa-info-circle has-text-info"></i></span>
                                            </tool-tip>
                                        </div>
                                        <div class="column is-5 has-text-right" :class="{'has-text-danger': shift.cigaretteSaleAmount < 0.00 }">

                                           {{ (shift.cigSales.buyCount ? (shift.cigSales.buyAmount / shift.cigSales.buyCount) : 0 ) | formatCurrency }}

                                        </div>
                                    </div>
                                </div>
                                <div class="">
                                    
                                    <div class="is-default has-text-info has-text-left is-size-7">
                                        </div>
                                    
                                </div>                                                                 
                            </div>


                            <!-- Car Wash -->
                            <div class="panel">
                                <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                                    <div class="columns is-mobile">
                                        <div class="column is-size-6 has-text-centered"> OTHER </div>
                                    </div>
                                </div>

                                <div class="panel-block">
                                    <div class="control columns is-mobile">
                                        <div class="column is-7"> Car Wash Sales </div>
                                        <div class="column is-5 has-text-right">
                                            <div class="field">
                                                <div class="control is-clearfix">
                                                    <VeeInput
                                                        type="currency" 
                                                        v-model="shift.carWashSold"
                                                        name="carWashSold"
                                                        :value="shift.carWashSold"
                                                        :countryCurrencyCode="countryCurrencyCode"
                                                        :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                        @input="reCalculate(index)"
                                                        />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <div class="control columns is-mobile row-even-background">
                                        <div class="column is-7"> Phone Card Sales </div>
                                        <div class="column is-5 has-text-right">
                                            <div class="field">
                                                <div class="control is-clearfix">
                                                    <VeeInput
                                                        type="currency" 
                                                        v-model="shift.phoneCardSold"
                                                        name="phoneCardSold"
                                                        :value="shift.phoneCardSold"
                                                        :countryCurrencyCode="countryCurrencyCode"
                                                        :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                                        @input="reCalculate(index)"
                                                            />
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>

                            <!-- Shift Notes -->
                        
                            <div class="panel">
                                <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                                    <div class="columns is-mobile">
                                        <div class="column is-size-6 has-text-centered"> NOTES </div>
                                    </div>
                                </div>
                                <div class="shift-notes-textarea">
                                    <VeeInput
                                        name="shiftNotes"
                                        type="textarea"
                                        v-model="shift.shiftNotes"
                                        :value="shift.shiftNotes"
                                        :editable="allowEdit && !isPrinting && !shift.salesFinalized"
                                        width="90%"
                                        @input="reCalculate(index)"
                                        >
                                    </VeeInput>
                                </div>
                            </div>                            

                            </form>

                        </div>
                       
                    <!-- </div> -->
                    <div class="column is-one-third" v-if="showRegisterData&& userRole != 'ACCOUNTANT' && userRole != 'STAFF'">

                        <!-- Register Data -->
                        <shift-register-data v-if="true"
                            :shift="shift"
                            :index="index"
                            :user="user"
                            :date="shiftDate"
                            :showRegisterData="showRegisterData"
                            >
                        </shift-register-data>
                    </div>
                </div>
            </b-tab-item>
        </b-tabs>
        <shift-detail-report-pdf
            ref="pdfReport"
            :isShowPDFDialog="isShowPDFDialog"
            :shiftDate="shiftDate"
            :hideZeroShift="hideZeroShift"
            @close-pdf-dialog="togglePDFDialog"
        >
        </shift-detail-report-pdf>
    </div>
</template>


<script>

import axios from 'axios'

// import Vue from 'vue'
import UserStore from "../../../../store/UserStore"
import moment from 'moment'
import ShiftRegisterData from './ShiftRegisterData'
import ShiftLotteryTable from './ShiftLotteryTable'
import ShiftIncomeTable from './ShiftIncomeTable'
import ShiftExpenseTable from './ShiftExpensesTable'
import ToolTip from '../../../../components/app/ToolTip'
// import jspdf from "jspdf"
import ShiftDetailReportPdf from '../../reports/ShiftDetailReportPDF'
import VeeInput from "../../../../components/app/VeeInput.vue"
import VeeSavePane from "../../../../components/app/VeeSavePane.vue"

export default {

    beforeRouteLeave (to, from, next) {

        if (UserStore.getters.getIsEditing) {
            this.$buefy.dialog.confirm({
                title: 'Leaving without saving!',
                message: 'Are you sure you want to discard all changes and leave?',
                confirmText: 'Discard Changes',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => next()
            })
        } else {
            next()
        }
    },

    metaInfo: {
        title: 'Shift Sales Details and Expenses and Lottery Entry'
    },    

    components: {
        ShiftLotteryTable,
        ShiftExpenseTable,
        ShiftIncomeTable,
        ShiftRegisterData,
        ShiftDetailReportPdf,
        ToolTip,
        VeeInput,
        VeeSavePane
    },

    // mixins: [SessionLogout],

    data() {
        return {
            userRole: "STAFF",
            overrideAllowed: false,
            hideDate: "is-hidden",
            alertClosable: false,
            hideDateButton: "",
            falseValue: false,
            trueValue: true,
            depositNotesLength: 15,
            isShowPDFDialog: false,
            showRegisterData: true,
            expenseTableOpen: false,
            incomeTableOpen: false,
            lotteryTableOpen: false,
            trackInstantLotteryBooks: {},
            totalLottery: [],
            hideZeroShift: true,
            maxShiftDate: new Date(),
            minShiftDate: new Date(Date.now() - ( 3600 * 1000 * 24 * 60)),
            user: [],
            isPaginated: false,
            showDetailIcon: true,
            isShowExpenseDialog: false,
            isShowIncomeDialog: false,
            isMobileNativeDatePicker: false,
            isStriped: true,
            perPage: 20,
            isScrollable: true,
            dailyShifts: [],
            shiftsBeforeUpdate: [],
            shifts: [0,1,2],
            activeTab: 1,
            isPrinting: false,
            isLoading: true,
            isFullPage: true,
            today: new Date(),
            shiftDate: new Date(),
            shiftNum: 0,
            adjustShiftDate: 3,
            countryCurrencyCode: UserStore.getters.getCountryCurrencyCode,
            dollarUSLocale: Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD'}),
            vendors: [],
            employees: [],
            employeesActive: [],
            expenseTypes: [],
            expenseTemp: {
                "id": 0,
                "shiftId": 0,
                "date": this.shiftDate,
                "dueOn": this.shiftDate,
                "description": "",
                "deptMapping": "",
                "amount": 0.00,
                "expenseTypeId": 0,
                "creditTerm": "NET0",
                "vendor": {"id": 0, "name": ""},
                "referenceNumber": "",
                "row": -1,
                "payments": [{
                    "id": 0,
                    "amount": 0.00,
                    "method": "Cash",
                    "referenceNumber": null,
                    "status": "Paid",
                    "clearedOn": this.shiftDate,
                    "paymentDate": this.shiftDate,

                }]
            },
            incomeTemp: {
                        "id": 0,
                        "shiftId": 0,
                        "date": this.shiftDate,
                        "dueOn": this.shiftDate,
                        "description": "",
                        "amount": "0.00",
                        "creditTerm": "Cash",
                        "row": -1,
                        "vendor": {"id": 0, "name": ""},
                        "referenceNumber": ""
                    },
            selectedIncomeRow: null,
            action: '',
            editableFields: [
                "employeeId",
                "onlineLottoSales", 
                "onlineLottoPayout",
                "instantLottoSales",
                "instantLottoPayout",
                "netLottery",
                "lotteryMachineWithdraw",
                "lotteryMachineSold",
                "totalCashExpenses", 
                "totalOtherIncome", 
                "cashierOverShort",
                "netRegisterCash",
                "totalPaymentOut",
                "cigs",
                "cigaretteOpenCount",
                "cigaretteCloseCount",
                "cigaretteBuyCount",
                "cigaretteSoldAmount",
                "totalCashExpense",
                "totalNoncashExpense",
                "totalCashIncome",
                "totalNoncashIncome",
                "previousCashUsed",
                "cashLoadedInAtm",
                "cashActualOnhand",
                "cashOverShort",
                "totalSafeDrop",
                "bankDepositGeneral",
                "bankDepositLottery",
                "bankDepositAtm",
                "cashInvestorDrawn",
                "cashAdjustment",
                "carWashSold",
                "phoneCardSold",
                "shiftNotes",
                "additionalIncomes",
                "expenses",
                "salesFinalized"
            ],
            updatableFields: [
                "employeeId",
                "onlineLottoSales", 
                "onlineLottoPayout",
                "instantLottoSales",
                "instantLottoPayout",
                "lotteryBooksOnSale",
                "netLottery",
                "lotteryMachineWithdraw",
                "lotteryMachineSold",                 
                "totalCashExpenses", 
                "totalOtherIncome", 
                "cashierOverShort",
                "netRegisterCash",
                "cigSales",
                "cigaretteOpenCount",
                "cigaretteCloseCount",
                "cigaretteBuyCount",
                "cigaretteSoldAmount",
                "totalCashExpense",
                "totalNoncashExpense",
                "totalCashIncome",
                "totalNoncashIncome",
                "previousCashUsed",
                "cashLoadedInAtm",
                "cashActualOnhand",
                "cashOverShort",
                "totalSafeDrop",
                "bankDepositGeneral",
                "bankDepositLottery",
                "bankDepositAtm",
                "cashInvestorDrawn",
                "cashAdjustment",
                "carWashSold",
                "phoneCardSold",
                "shiftNotes",
                "additionalIncome",
                "expenses",
                "salesFinalized"
            ],
            masks: {
                phone: { 
                    phone: true,
                    phoneRegionCode: 'US'
                },
                currency: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                    numeralDecimalScale: 2,
                    prefix: '$ ',
                    rawValueTrimPrefix: true
                }
            }
        }
    },
            
    methods: {
        fetchStoreShiftSales() {
            if (UserStore.getters.getActiveStore) {

                this.dailyShifts = []
                this.isLoading = true

                // const loadingComponent = this.$buefy.loading.open({
                //     container: this.isFullPage ? null : this.$refs.element.$el
                // })

                var shiftDateString = this.shiftDate.getFullYear() + '-' + ("0" + (this.shiftDate.getMonth() + 1)).slice(-2) + '-' + ("0" + this.shiftDate.getDate()).slice(-2)

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/sales/shifts?date=" + shiftDateString

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.dailyShifts = JSON.parse(JSON.stringify(response.data.data))

                        this.shiftsBeforeUpdate =  Object.assign({},response.data.data)

                        this.activeTab = this.dailyShifts.length - 1
                        
                        this.removeZeroShift()
                        
                        if (this.dailyShifts.length == 0) {
                            if (this.adjustShiftDate) {
                                this.adjustShiftDate--
                                this.shiftDate.setDate(this.shiftDate.getDate()-1)
                                // this.shiftDate = new Date(shiftDateString) - 1
                                this.fetchStoreShiftSales()
                            }
                        }


                        for (var i=0; i<this.dailyShifts.length; i++) {
                            this.dailyShifts[i].shiftSavedBefore = this.dailyShifts[i].employeeId

                            if (this.dailyShifts[i].cigSales == null) {
                                this.dailyShifts[i].cigSales = {
                                    "buyAmount": 0,
                                    "buyCount": 0,
                                    "closeCount": 0,
                                    "openCount": 0
                                }
                            }
                            if (this.dailyShifts[i].previousCigSales == null) {
                                this.dailyShifts[i].previousCigSales = {
                                    "buyAmount": 0,
                                    "buyCount": 0,
                                    "closeCount": 0,
                                    "openCount": 0
                                }
                            }

                            if (this.dailyShifts[i].cigDeptSales == null) {
                                this.dailyShifts[i].cigDeptSales = {
                                    "storeMapping": "Cigarette",
                                    "netSales": 0,
                                    "netSalesCount": 0,
                                    "netSalesItemCount": 0,
                                    "grossSales": 0,
                                    "type": "SHIFT"
                                }
                            }
                            
                            this.dailyShifts[i].netLottery = this.netLottery

                            if (this.dailyShifts[i].salesFinalized == null) this.dailyShifts[i].salesFinalized = false

                            this.dailyShifts[i].lotteryBooksOnSale.forEach(element => {

                                if (element.lotteryBookSale.startingSeq == null) {
                                    if (element.lotteryBookSale.lastTicketSold > 0) {
                                        element.lotteryBookSale.startingSeq  = element.lotteryBookSale.lastTicketSold
                                    } else {
                                        element.lotteryBookSale.startingSeq  = 0
                                    }
                                    
                                }
                                if (element.lotteryBookSale.endingSeq == null) {
                                    element.lotteryBookSale.endingSeq  = element.lotteryBookSale.startingSeq
                                }
                                if (element.lotteryBookSale.salesId == null) {
                                    element.lotteryBookSale.salesId = this.dailyShifts[i].id
                                }
                                if (element.lotteryBookSale.bookId == null) {
                                    element.lotteryBookSale.bookId = element.id
                                }                                
                                element.lotteryBookSale.ticketsSold = parseInt(element.lotteryBookSale.endingSeq) - parseInt(element.lotteryBookSale.startingSeq)
                                element.savedTicketEndNum = element.lotteryBookSale.endingSeq
                                
                            })

                            this.dailyShifts[i].instantLottoTicketsSold = this.dailyShifts[i].lotteryBooksOnSale.reduce((accum,item) => accum + item.lotteryBookSale.ticketsSold, 0)
                            if (this.trackInstantLotteryBooks.value == 1) {
                                this.dailyShifts[i].instantLottoSales = this.dailyShifts[i].lotteryBooksOnSale.reduce((accum,item) => accum + (item.lotteryBookSale.ticketsSold * item.pricePoint), 0)
                            }

                            this.reCalculate(i)

                        }

                        UserStore.commit("setIsEditing",false)
                        this.isLoading = false

                    })
                    .catch( (err) => {
                        this.dailyShifts = []
                         if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err)
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                        if (err.response.status === 404) {
                            this.$buefy.toast.open({
                                message: 'No shift data found for ' + this.shiftDate.toLocaleDateString(),
                                type: 'is-warning'
                            })

                            if (this.adjustShiftDate) {
                                this.adjustShiftDate--
                                this.shiftDate.setDate(this.shiftDate.getDate()-1)
                                // this.shiftDate = new Date(shiftDateString) - 1
                                this.fetchStoreShiftSales()
                            }
                        }                        
                        UserStore.commit("setIsEditing",false)
                        // console.clear()
                        this.isLoading = false
                        // loadingComponent.close()

                    })
            }
            
        },

        fetchVendors() {
            if (this.user.activeStore) {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/vendors'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.vendors = this.sortArrayByProp(response.data.data,"name")
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        fetchEmployees() {
            if (this.user.activeStore) {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/employees'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.employees = this.sortArrayByProp(response.data.data,"firstName")
                        this.employeesActive = this.employees.filter(emp => emp.active === true)
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        fetchExpenseTypes() {
            if (this.user.activeStore) {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/expense_type'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.expenseTypes = this.sortArrayByProp(response.data.data,"name")
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        fetchLotteryOnSale(shiftIndex) {
            if (UserStore.getters.getActiveStore) {

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/lottery/onsale/" + this.dailyShifts[shiftIndex].id

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.lotteryBooks = response.data.data
                        this.dailyShifts[this.activeTab].lotteryBooksOnSale = response.data.data
                        
                    })
                    .catch( (err) => {
                        this.dailyShifts = []
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err)
                    })
            }
        },                    

        shiftNumber(index) {
            return index + 1
        },

        removeZeroShift() {

            if ( this.hideZeroShift == true) {
                this.dailyShifts = this.dailyShifts.filter(tab => (tab.overallGrand != 0.00 && tab.overallGrand != null && tab.netSales != 0.00 && tab.netSales != null));
            }            
            
            this.activeTab = this.dailyShifts.length - 1
        },

        validShifts() {
            return true;
        },

        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault()
            } else {
                return true;
            }
        },

        addItems(items) {
            var sum = 0
            items.forEach((item) => {
                sum = sum + this.getNumber(item)
            })
            return sum
        },

        substractItem(balance, item) {

            return this.getNumber(balance) - this.getNumber(item)
        },


        reCalculate(index) {
            
            this.dailyShifts[index].expenses.forEach( (expense) => {
                if (expense.expenseTypeId == '1' || expense.expenseTypeId == '139' || expense.expenseTypeId == '140') {
                    var employee = this.employees.filter(item => (item.id == expense.employeeId))
                    if (employee.length > 0) {
                        expense.vendorOrEmployee = employee[0].firstName + " " + employee[0].lastName
                    } else {
                        expense.vendorOrEmployee = "Invalid Employee"
                    }

                    if (expense.vendorId == null) {
                        expense.vendorId = 0
                    }

                } else {
                    var vendor = this.vendors.filter(item => (item.id == expense.vendorId))
                    if (vendor.length > 0) {
                        expense.vendorOrEmployee = vendor[0].name
                    } else {
                        expense.vendorOrEmployee = "Invalid Vendor"
                    }
                }

                
            })

            this.dailyShifts[index].totalCashIncome = this.getNumber(this.cashAdditionalIncome)
            this.dailyShifts[index].totalCashExpense = this.getNumber(this.cashExpensePayments)

            this.dailyShifts[index].netRegisterCash = this.dailyShifts[index].cashAmount + 
                                                      this.dailyShifts[index].totalPaymentIn - 
                                                      this.dailyShifts[index].cashExpensePayments

            this.dailyShifts[index].netRegisterCash = this.dailyShifts[index].cashAmount + 
                                                      this.dailyShifts[index].totalPaymentOut - 
                                                      this.dailyShifts[index].payOut

            // this.dailyShifts[index].cashOverShort = this.cashOverOrShort.toFixed(2)

            this.dailyShifts[index].cashOverShort = this.shiftCashOverShort(index)

            // this.cigaretteSoldCount = this.getNumber(this.dailyShifts[index].cigaretteOpenCount) + 
            //                           this.getNumber(this.dailyShifts[index].cigaretteBuyCount) - 
            //                           this.getNumber(this.dailyShifts[index].cigaretteCloseCount)

            if (this.trackInstantLotteryBooks.value == 1) {
                this.dailyShifts[index].instantLottoSales = this.dailyShifts[index].lotteryBooksOnSale.reduce((accum,item) => accum + (item.lotteryBookSale.ticketsSold * item.pricePoint), 0)
            }

            this.dailyShifts[index].netLottery = this.netLottery
            if (this.dailyShifts[index].cigSales.buyCount == "") {
               this.dailyShifts[index].cigSales.buyCount = 0 
            } 
            if (this.dailyShifts[index].cigSales.closeCount == "") {
               this.dailyShifts[index].cigSales.closeCount = 0 
            }
            
            
            this.tabDataChanged(index)
        },

        totalPayments(payments) {

            var total = 0.00
            payments.forEach((payment) => {

                total = total +this. getNumber(payment.amount)

            })

            return total

        },

        combinePayMethod(payments) {

            var paytypes = ""
            payments.forEach((payment) => {
                var str = payment.method + " " + payment.status
                var n = paytypes.match(str)
                if (! n) {
                    paytypes = paytypes + str
                }
            })

            return paytypes
        },

        submitForm(index) {
            var shiftData = {}

            this.updatableFields.forEach((field) => {
                shiftData[field] = this.dailyShifts[index][field]

                if (field == "lotteryBooksOnSale") {
                    shiftData.lotteryBooksOnSale.forEach((ticket) => {
                        if (ticket.activatedDate == null) {
                            ticket.activatedDate = this.shiftDate.toISOString().split('T')[0]
                        }
                    })
                }
            })

            if (this.dailyShifts[index]["employeeId"] == 0 || this.dailyShifts[index]["employeeId"] == null) {
                this.$buefy.toast.open({
                    message: 'Please select the Shift Employee!',
                    type: 'is-danger'
                })
            } else {
                // this.saveShift(this.dailyShifts[index].id, this.dailyShifts[index])
                this.saveShift(index)
            }

        },

        // saveShift(shiftId, shiftData) {
        saveShift(index) {
            var shiftId = this.dailyShifts[index].id
            var shiftData = this.dailyShifts[index]

            if (UserStore.getters.getActiveStore) {

                this.isLoading = true

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/sales/shift/" + shiftId

                axios.put(url,
                    shiftData,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.$buefy.toast.open({
                            message: 'Shift information saved successfully!',
                            type: 'is-success'
                        })
                        this.sendShiftEmail(index)
                        this.fetchStoreShiftSales()
                        this.isLoading = false
                        this.overrideAllowed = false
                        UserStore.commit("setIsEditing",false)
                    })
                    .catch( (err) => {
                        // console.log(err)
                        this.isLoading = false
                        //if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err)
                        //if (err.response.status === 403) {
                        //    alert('Session Expired. Please log in again!')
                        //    UserStore.commit('userLogout')
                       // }
                       console.log(err)
                        this.$buefy.toast.open({
                            message: 'Shift updates failed to save!',
                            type: 'is-danger'
                        })
                    })
            }


        },

        sendShiftEmail(index) {
            var emailAddresses = this.getSettingEmails("shiftUpdateNotificationEmailAddresses")
            // console.log("email", emailAddresses)

            if (emailAddresses && emailAddresses.length > 0 && this.getSetting("shiftNumberToEmailUpdate") > 0) {
                var sendNotification = false
                var shiftIndexToSend = this.getSetting("shiftNumberToEmailUpdate") - 1
                // console.log("Shift",shiftIndexToSend)
                if (this.getSetting("shiftNumberToEmailUpdate")) {
                    if (this.dailyShifts.length >= shiftIndexToSend + 1) {
                        if (this.dailyShifts[shiftIndexToSend].employeeId) {
                            if (emailAddresses) {
                                sendNotification = true
                            }
                        }
                    }
                }

                var subject = "Paperwork - " + moment(this.shiftDate).format("MM/DD/YYYY") + " - " + UserStore.getters.getActiveStore.storeName + " Updated"
                // if (this.getSetting("shiftNumberToEmailUpdate") <= this.shiftNumber(index) && emailAddresses) {
                if (sendNotification) {
                    var htmlEmail = "<head><meta content='text/html'; charset='utf-8' http-equiv='Centent-Type'>"
                    htmlEmail += '<meta name="viewport" content="width=device-width, initial-scale=1" />'
                    htmlEmail += '<title>Daily Summary Report</title></head>'
                    htmlEmail += "<head><style>'body {background-color: rgb(253, 253, 253)}'</style></head>"
                    htmlEmail += "<h3>"+ UserStore.getters.getActiveStore.storeName + " - " + moment(this.shiftDate).format("MM/DD/YYYY") + "</h3>"
                    
                    htmlEmail += "<body style='font-family: Arial, sans-serif; font-size: 15px; font-weight: 400; overflow-x: scroll;'"
                    htmlEmail += "<div style='overflow-x:auto;'>"
                    htmlEmail += "<table style='background-color: rgb(253, 253, 253);'>"
                    htmlEmail += "<tr style='background-color: rgb(235, 235, 235); font-size: 12px'><td>Updated by: " + this.user.name + "</td><td style='text-align: right;'> Updated Shift: "+ (index+1) + "</td></tr>"
                    htmlEmail += "<tr style='background-color: rgb(235, 235, 235);'>"
                    htmlEmail += "<td style='border-left: 2px; border-color: rgb(18, 18, 18);  text-align: left;width: 250px;'>Description</td>"
                    htmlEmail += "<td style='border-right: 1px; border-color: rgb(18, 18, 18);  text-align: right;width: 100px;'>Day Total</td>"
                    htmlEmail += "</tr>"

                    htmlEmail += this.htmlEmailBlankRow(2)

                    var total = 0
                    this.dailyShifts.forEach((item) => {
                        total += parseFloat((item.merchSales ? item.merchSales : 0))
                    })
                    htmlEmail += this.htmlEmailRow("Merchandise Sales", total)    

                    total = 0
                    this.dailyShifts.forEach((item) => {
                        total += parseFloat((item.fuelSales ? item.fuelSales : 0))
                    })
                    htmlEmail += this.htmlEmailRow("Fuel Sales", total)    

                    total = 0
                    this.dailyShifts.forEach((item) => {
                        var fuelVol = item.storeFuelSales.reduce((accum,fuel) => accum + parseFloat(fuel.volume), 0)
                        total += fuelVol 
                    })
                    htmlEmail += this.htmlEmailRow("Fuel Volume (gal)", total.toFixed(3), "gal")
                    htmlEmail += this.htmlEmailBlankRow(2)

                    total = 0
                    this.dailyShifts.forEach((item) => {
                        total += parseFloat((item.cashAmount ? item.cashAmount : 0))
                    })
                    htmlEmail += this.htmlEmailRow("Cash Sales Amount", total)                   

                    total = 0
                    this.dailyShifts.forEach((item) => {
                        var itemValue = (item.cashRefundAmount > 0.00 ? item.cashRefundAmount * -1 : 0)
                        total += itemValue
                    })
                    htmlEmail += this.htmlEmailRow("Cash Refund", total)

                    total = 0
                    var cashAtStore = 0 
                    this.dailyShifts.forEach((item) => {
                        var itemValue = (item.totalSafeDrop > 0.00 ? item.totalSafeDrop : 0)
                        total += itemValue
                        cashAtStore += itemValue
                    })
                    htmlEmail += this.htmlEmailRow("Safe Drop", total * -1)
                    

                    total = 0
                    this.dailyShifts.forEach((item) => {
                        var netLotterySales = item.instantLottoSales - 
                            item.instantLottoPayout + 
                            item.onlineLottoSales - 
                            item.onlineLotteryCanceled -
                            item.onlineLottoPayout +
                            item.lotteryMachineWithdraw

                        total += netLotterySales
                    })
                    htmlEmail += this.htmlEmailRow("Net Lottery", total)
                    
                    total = 0
                    this.dailyShifts.forEach((item, index) => {
                        var filteredArray = this.dailyShifts[index].additionalIncomes.filter(item => (item.paymentMethod == "Cash"));
                        var cashIncome = filteredArray.reduce((accum,item) => accum + parseFloat(item.amount), 0)
                        total += cashIncome 
                    })
                    htmlEmail += this.htmlEmailRow("Cash Other Income", total)

                    total = 0
                    this.dailyShifts.forEach((item) => {

                        var filteredExpenses = item.expenses.filter(itemExp => (itemExp.payments.length > 0))
                        var totCashExp = 0
                        filteredExpenses.forEach(expense => {
                            var cashExpArray = expense.payments.filter(itemPay => (itemPay.method == "Cash"))
                            totCashExp = totCashExp + cashExpArray.reduce((accum, itemPay) => accum + parseFloat(itemPay.amount), 0)
                        })
                        var itemValue = (totCashExp > 0.00 ? totCashExp * -1 : 0)
                        total += itemValue
                    })
                    htmlEmail += this.htmlEmailRow("Cash Expense/Inventory", total)

                    total = 0
                    this.dailyShifts.forEach((item) => {
                        var itemValue = (item.checkActualOnhand > 0.00 ? item.checkActualOnhand : 0)
                        total += itemValue
                    })
                    htmlEmail += this.htmlEmailRow("Non-Cash in Book Balance", total)
                    // cashAtStore = cashAtStore - total

                    total = 0
                    this.dailyShifts.forEach((item) => {
                        total += parseFloat((item.cashActualOnhand ? item.cashActualOnhand : 0))
                    })
                    htmlEmail += this.htmlEmailRow("Actual Cash Onhand", total)
                    htmlEmail += this.htmlEmailBlankRow(2)
                    cashAtStore += total

                    total = 0
                    this.dailyShifts.forEach((item,idx) => {
                        var shiftcash = this.shiftCashOverShort(idx)
                        var itemValue = (shiftcash ? shiftcash  : 0)
                        total += itemValue
                    })
                    if (this.dollarUSLocale.format(total) === "-$0.00") {
                        total = 0.00
                    }
                    if (total < 0) {
                        htmlEmail += this.htmlEmailRow("Cash Short", total, 'currency', "font-weight: 900; font-size: 12pt; color: rgb(255, 87, 51);" )
                    } else if ( total == 0) { 
                        htmlEmail += this.htmlEmailRow("Cash Over/Short", total, 'currency', "font-weight: 900; font-size: 12pt; color: rgb(10, 188, 10);",true )
                    } else {
                        htmlEmail += this.htmlEmailRow("Cash Over", total, 'currency', "font-weight: 900; font-size: 12pt; color: rgb(10, 188, 10);" )
                    }
                    htmlEmail += this.htmlEmailBlankRow(2)
                    
                    total = 0
                    this.dailyShifts.forEach((item) => {
                        var itemValue = (item.bankDepositGeneral > 0.00 ? item.bankDepositGeneral : 0)
                        total += itemValue
                    })
                    htmlEmail += this.htmlEmailRow("Bank Deposit - General", total)
                    cashAtStore = cashAtStore - total
                    
                    total = 0
                    this.dailyShifts.forEach((item) => {
                        var itemValue = (item.bankDepositLottery > 0.00 ? item.bankDepositLottery : 0)
                        total += itemValue
                    })
                    htmlEmail += this.htmlEmailRow("Bank Deposit - Other", total)
                    cashAtStore = cashAtStore - total

                    total = 0
                    this.dailyShifts.forEach((item) => {
                        var itemValue = (item.bankDepositAtm > 0.00 ? item.bankDepositAtm : 0)
                        total += itemValue
                    })
                    htmlEmail += this.htmlEmailRow("Other Cash Disbursement", total)  
                    cashAtStore = cashAtStore - total

                    total = 0
                    this.dailyShifts.forEach((item) => {
                        var itemValue = (item.cashLoadedInAtm > 0.00 ? item.cashLoadedInAtm : 0)
                        total += itemValue
                    })
                    htmlEmail += this.htmlEmailRow("Loaded in ATM", total)
                    cashAtStore = cashAtStore - total

                    // htmlEmail += this.htmlEmailBlankRow(2) 
                    var daysCashText = (cashAtStore >= 0.00 ? "Cash On Hand after deposit" : "Prior Day's Cash Used")
                    htmlEmail += this.htmlEmailRow(daysCashText, cashAtStore)                    
                    htmlEmail += this.htmlEmailBlankRow(2) 

                    total = 0
                    this.dailyShifts.forEach((item) => {

                        var itemValue = item.cigSales.closeCount - (item.previousCigSales.closeCount + item.cigSales.buyCount - item.cigaretteSaleCount)
                        total += itemValue
                    })
                    if (total < 0) {
                        htmlEmail += this.htmlEmailRow("Cigarette Count Short", total, 'count', "font-weight: 900; font-size: 12pt; color: rgb(255, 87, 51);" )
                    } else if ( total == 0) { 
                        htmlEmail += this.htmlEmailRow("Cigarette Count Over/Short", total, 'count', "font-weight: 900; font-size: 12pt; color: rgb(10, 188, 10);",true )
                    } else {
                        htmlEmail += this.htmlEmailRow("Cigarette Count Over", total, 'count', "font-weight: 900; font-size: 12pt; color: rgb(10, 188, 10);" )
                    }                  
                    htmlEmail += this.htmlEmailBlankRow(2)                     

                    total = 0
                    this.dailyShifts.forEach((item) => {
                        total += parseFloat((item.voidTicketCount ? item.voidTicketCount : 0))
                    })
                    htmlEmail += this.htmlEmailRow("Void Ticket Count", total, 'count')

                    total = 0
                    this.dailyShifts.forEach((item) => {
                        total += parseFloat((item.voidTicketAmount ? item.voidTicketAmount * -1 : 0))
                    })
                    htmlEmail += this.htmlEmailRow("Void Ticket Amount", total)
                    
                    total = 0
                    this.dailyShifts.forEach((item) => {
                        total += parseFloat((item.voidLineCount ? item.voidLineCount : 0))
                    })
                    htmlEmail += this.htmlEmailRow("Void Line Count", total, 'count')

                    total = 0
                    this.dailyShifts.forEach((item) => {
                        total += parseFloat((item.payOut ? item.payOut * -1 : 0))
                    })
                    htmlEmail += this.htmlEmailBlankRow(2)
                    htmlEmail += this.htmlEmailRow("Register Payout", total)

                    htmlEmail += "</table></div>"  
                    
                    htmlEmail += "<br><div style='width: 100% border: 0 cellspacing: 0 cellpadding: 0;'>"
                    htmlEmail += "<hr><p>Email Sent by <a href='https://www.veecli.com'><span style='font-size: 12px; font-weight: 900'>VEECLi Software</span></a><br>"
                    htmlEmail += "<span style='font-size: 11px; font-weight: 400'><i>The Best Gas Station Accounting and Tank Monitoring Software!</i></span></p><hr>"
                    htmlEmail += "</div><body>"

                    this.sendEmail(
                        emailAddresses,
                        subject,
                        htmlEmail)

                }
            }
        },

        htmlEmailBlankRow(rows) {
            if (rows < 1) rows = 1
            var htmlBlankEmailRow = "<tr style='height: " + 3 * rows + "px;'>"
            htmlBlankEmailRow += "<td style='text-align: left;width: 250px;'></td>"
            htmlBlankEmailRow += "<td style='border-right: 1px; text-align: right;width: 100px;'></td>"
            htmlBlankEmailRow += "</tr>"
            return htmlBlankEmailRow
        },

        htmlEmailRow(label, amount, type, style, force) {
            if (type === undefined) type = "currency"
            if (force === undefined) force = false
            if (type === 'gal') amount = this.formatNumberWithCommas(this.getNumber(amount),3)
            if (type === 'count') amount = this.formatNumberWithCommas(this.getNumber(amount),0)            
            if (style === undefined) style = ""
            var amountStyle = "border-right: 1px; text-align: right; width: 100px; " + style
            var htmlEmailRow = ""
            if ((amount !== 0 && amount !== null) || force) {
                htmlEmailRow += "<tr style='background-color: rgb(235, 235, 235);'>"
                htmlEmailRow += "<td style='text-align: left;width: 250px;" + style + "'>" + label + "</td>"

                if (amount < 0.00) {
                    amountStyle += "color: rgb(255, 87, 51);"
                }

                htmlEmailRow += "<td style='" + amountStyle +"'>" +
                        ( type === 'currency' ? this.dollarUSLocale.format(amount) : amount  ) + "</td>"
                htmlEmailRow += "</tr>"
            }
            return htmlEmailRow
        },

        sendShiftEmailWithShifts(index) {

            var emailAddresses = this.getSettingEmails("shiftUpdateNotificationEmailAddresses")

            // console.log("email", emailAddresses)
            if (emailAddresses && emailAddresses.length > 0 && this.getSetting("shiftNumberToEmailUpdate") > 0) {
                
                var subject = "Paperwork - " + moment(this.shiftDate).format("MM/DD/YYYY") + " - " + UserStore.getters.getActiveStore.storeName + " Updated"
                if (this.getSetting("shiftNumberToEmailUpdate") <= this.shiftNumber(index) && emailAddresses) {
                    var htmlEmail = "<head><meta content='text/html'; charset='utf-8' http-equiv='Centent-Type'>"
                    // htmlEmail += "meta content='width=device-width', intial-scale='1' name='viewport'>"
                    htmlEmail += '<meta name="viewport" content="width=device-width, initial-scale=1" />'
                    htmlEmail += '<title>Daily Summary Report</title></head>'
                    htmlEmail += "<head><style>'body {background-color: rgb(253, 253, 253)}'</style></head>"
                    htmlEmail += "<h3>"+ UserStore.getters.getActiveStore.storeName + " - " + moment(this.shiftDate).format("MM/DD/YYYY") +"<h3>"
                    htmlEmail += "<div style='overflow-x:auto;'>"
                    htmlEmail += "<table style='background-color: rgb(253, 253, 253);'>"
                    htmlEmail += "<tr style='background-color: rgb(235, 235, 235);'>"
                    htmlEmail += "<td style='border-left: 2px; border-color: rgb(18, 18, 18);  text-align: left;width: 250px;'>Description</td>"
                    this.dailyShifts.forEach((item, index) => {
                        htmlEmail += "<td style='text-align: right;width: 100px;'>Shift " + this.shiftNumber(index) + "</td>"
                    })
                    htmlEmail += "<td style='border-right: 1px; border-color: rgb(18, 18, 18);  text-align: right;width: 100px;'>Daily</td>"
                    htmlEmail += "</tr>"

                    total = 0
                    htmlEmail += "<tr>"
                    htmlEmail += "<td style='text-align: left;width: 250px;'>Fuel Sales</td>"
                    this.dailyShifts.forEach((item) => {
                        htmlEmail += "<td style='text-align: right;width: 100px;'>" + this.dollarUSLocale.format(item.fuelSales) + "</td>"
                        total += parseFloat((item.fuelSales ? item.fuelSales : 0))
                    })
                    htmlEmail += "<td style='border-right: 1px; text-align: right;width: 100px;'>" + this.dollarUSLocale.format(total) + "</td>"
                    htmlEmail += "</tr>"

                    var total = 0
                    htmlEmail += "<tr>"
                    htmlEmail += "<td style='border-left: 2px; text-align: left;width: 250px;'>Merchand Sales</td>"
                    this.dailyShifts.forEach((item) => {
                        htmlEmail += "<td style='text-align: right;width:100px;'>" + this.dollarUSLocale.format(item.merchSales) + "</td>"
                        total += parseFloat((item.merchSales ? item.merchSales : 0))
                    })
                    htmlEmail += "<td style='border-right: 1px; text-align: right;width: 100px;'>" + this.dollarUSLocale.format(total) + "</td>"
                    htmlEmail += "</tr>"

                    total = 0
                    htmlEmail += "<tr>"
                    htmlEmail += "<td style='text-align: left;width: 250px;'></td>"
                    this.dailyShifts.forEach(() => {
                        htmlEmail += "<td style='text-align: right;width: 100px;'></td>"
                    })
                    htmlEmail += "<td style='border-right: 1px; text-align: right;width: 100px;'></td>"
                    htmlEmail += "</tr>"

                    total = 0
                    htmlEmail += "<tr>"
                    htmlEmail += "<td style='text-align: left;width: 250px;'></td>"
                    this.dailyShifts.forEach(() => {
                        htmlEmail += "<td style='text-align: right;width: 100px;'></td>"
                    })
                    htmlEmail += "<td style='border-right: 1px; text-align: right;width: 100px;'></td>"
                    htmlEmail += "</tr>"

                    total = 0
                    htmlEmail += "<tr>"
                    htmlEmail += "<td style='text-align: left;width: 250px;'>Void Ticket Count</td>"
                    this.dailyShifts.forEach((item) => {
                        htmlEmail += "<td style='color: rgb(255, 87, 51);text-align: right;width: 100px;'>" + item.voidTicketCount + "</td>"
                        total += parseFloat((item.voidTicketCount ? item.voidTicketCount : 0))
                    })
                    htmlEmail += "<td style='color: rgb(255, 87, 51);border-right: 1px; text-align: right;width: 100px;'>" + total + "</td>"
                    htmlEmail += "</tr>"

                    total = 0
                    htmlEmail += "<tr>"
                    htmlEmail += "<td style='text-align: left;width: 250px;'>Void Ticket Amount</td>"
                    this.dailyShifts.forEach((item) => {
                        htmlEmail += "<td style='color: rgb(255, 87, 51);text-align: right;width: 100px;'>" + this.dollarUSLocale.format(item.voidTicketAmount) + "</td>"
                        total += parseFloat((item.voidTicketAmount ? item.voidTicketAmount * -1 : 0))
                    })
                    htmlEmail += "<td style='color: rgb(255, 87, 51);border-right: 1px; text-align: right;width: 100px;'>" + this.dollarUSLocale.format(total) + "</td>"
                    htmlEmail += "</tr>"

                    total = 0
                    htmlEmail += "<tr>"
                    htmlEmail += "<td style='text-align: left;width: 250px;'>Void Lice Count</td>"
                    this.dailyShifts.forEach((item) => {
                        htmlEmail += "<td style='color: rgb(255, 87, 51);text-align: right;width: 100px;'>" + item.voidLineCount + "</td>"
                        total += parseFloat((item.voidLineCount ? item.voidLineCount * -1 : 0))
                    })
                    htmlEmail += "<td style='color: rgb(255, 87, 51);border-right: 1px; text-align: right;width: 100px;'>" + total + "</td>"
                    htmlEmail += "</tr>"                

                    total = 0
                    htmlEmail += "<tr>"
                    htmlEmail += "<td style='text-align: left;width: 250px;'></td>"
                    this.dailyShifts.forEach(() => {
                        htmlEmail += "<td style='text-align: right;width: 100px;'></td>"
                    })
                    htmlEmail += "<td style='border-right: 1px; text-align: right;width: 100px;'></td>"
                    htmlEmail += "</tr>"                

                    total = 0
                    htmlEmail += "<tr>"
                    htmlEmail += "<td style='text-align: left;width: 250px;'></td>"
                    this.dailyShifts.forEach(() => {
                        htmlEmail += "<td style='text-align: right;width: 100px;'></td>"
                    })
                    htmlEmail += "<td style='border-right: 1px; text-align: right;width: 100px;'></td>"
                    htmlEmail += "</tr>"

                    total = 0
                    htmlEmail += "<tr style='background-color: rgb(235, 235, 235);'>"
                    htmlEmail += "<td style='border-left: 2px; text-align: left;width: 250px;'>Cash Amount</td>"
                    this.dailyShifts.forEach((item) => {
                        htmlEmail += "<td style='text-align: right;width:100px;'>" + this.dollarUSLocale.format(item.cashAmount) + "</td>"
                        total += parseFloat((item.cashAmount ? item.cashAmount : 0))
                    })
                    htmlEmail += "<td style='border-right: 1px; text-align: right;width: 100px;'>" + this.dollarUSLocale.format(total) + "</td>"
                    htmlEmail += "</tr>"

                    

                    total = 0
                    htmlEmail += "<tr style='background-color: rgb(235, 235, 235);'>"
                    htmlEmail += "<td style='border-left: 2px; text-align: left;width: 250px;'>Cash Refund</td>"
                    this.dailyShifts.forEach((item) => {
                        var itemValue = (item.cashRefundAmount > 0.00 ? item.cashRefundAmount * -1 : 0)
                        if (itemValue < 0)  {
                            htmlEmail += "<td style='color: rgb(255, 87, 51);text-align: right;width:100px;'>" + this.dollarUSLocale.format(itemValue) + "</td>"
                        } else {
                            htmlEmail += "<td style='text-align: right;width:100px;'>" + this.dollarUSLocale.format(itemValue) + "</td>"
                        }
                        total += itemValue
                    })
                    if (total < 0)  {
                            htmlEmail += "<td style='color: rgb(255, 87, 51);text-align: right;width:100px;'>" + this.dollarUSLocale.format(total) + "</td>"
                    } else {
                        htmlEmail += "<td style='text-align: right;width:100px;'>" + this.dollarUSLocale.format(total) + "</td>"
                    }
                    htmlEmail += "</tr>"

                    total = 0
                    htmlEmail += "<tr style='background-color: rgb(235, 235, 235);'>"
                    htmlEmail += "<td style='border-left: 2px; text-align: left;width: 250px;'>Safe Drop</td>"
                    this.dailyShifts.forEach((item) => {
                        var itemValue = (item.totalSafeDrop > 0.00 ? item.totalSafeDrop * -1 : 0)
                        htmlEmail += "<td style='color: rgb(255, 87, 51); text-align: right;width:100px;'>" + this.dollarUSLocale.format(itemValue) + "</td>"
                        total += itemValue
                    })
                    htmlEmail += "<td style='color: rgb(255, 87, 51); border-right: 1px; text-align: right;width: 100px;'>" + this.dollarUSLocale.format(total) + "</td>"
                    htmlEmail += "</tr>"

                    total = 0
                    htmlEmail += "<tr style='background-color: rgb(235, 235, 235);'>"
                    htmlEmail += "<td style='border-left: 2px; text-align: left;width: 250px;'>Net Lottery</td>"
                    this.dailyShifts.forEach((item) => {
                        var netLotterySales = item.instantLottoSales - 
                            item.instantLottoPayout + 
                            item.onlineLottoSales - 
                            item.onlineLotteryCanceled -
                            item.onlineLottoPayout +
                            item.lotteryMachineWithdraw

                        // var itemValue = (netLotterySales == null ? 0 : netLotterySales)
                        if (netLotterySales < 0.00) {
                            htmlEmail += "<td style='color: rgb(255, 87, 51); text-align: right;width:100px;'>" + this.dollarUSLocale.format(netLotterySales) + "</td>"
                        } else {
                            htmlEmail += "<td style='text-align: right;width:100px;'>" + this.dollarUSLocale.format(netLotterySales) + "</td>"
                        }
                        total += netLotterySales
                    })
                    if (total < 0.00) {
                        htmlEmail += "<td style='color: rgb(255, 87, 51); border-right: 1px; text-align: right;width: 100px;'>" + this.dollarUSLocale.format(total) + "</td>"
                    } else {
                        htmlEmail += "<td style='border-right: 1px; text-align: right;width: 100px;'>" + this.dollarUSLocale.format(total) + "</td>"
                    }
                    htmlEmail += "</tr>"

                    total = 0
                    htmlEmail += "<tr style='background-color: rgb(235, 235, 235);'>"
                    htmlEmail += "<td style='border-left: 2px; text-align: left;width: 250px;'>Cash Income</td>"
                    this.dailyShifts.forEach((item) => {
                        htmlEmail += "<td style='text-align: right;width:100px;'>" + this.dollarUSLocale.format(item.totalCashIncome) + "</td>"
                        total += parseFloat((item.totalCashIncome ? item.totalCashIncome : 0))
                    })
                    htmlEmail += "<td style='border-right: 1px; text-align: right;width: 100px;'>" + this.dollarUSLocale.format(total) + "</td>"
                    htmlEmail += "</tr>"

                    total = 0
                    htmlEmail += "<tr style='background-color: rgb(235, 235, 235);'>"
                    htmlEmail += "<td style='border-left: 2px; text-align: left;width: 250px;'>Cash Expense</td>"
                    this.dailyShifts.forEach((item) => {

                        var filteredExpenses = item.expenses.filter(itemExp => (itemExp.payments.length > 0))
                        var totCashExp = 0
                        filteredExpenses.forEach(expense => {
                            var cashExpArray = expense.payments.filter(itemPay => (itemPay.method == "Cash"))
                            totCashExp = totCashExp + cashExpArray.reduce((accum, itemPay) => accum + parseFloat(itemPay.amount), 0)
                        })
                        var itemValue = (totCashExp > 0.00 ? totCashExp * -1 : 0)
                        if (itemValue < 0)  {
                            htmlEmail += "<td style='color: rgb(255, 87, 51);text-align: right;width:100px;'>" + this.dollarUSLocale.format(itemValue) + "</td>"
                        } else {
                            htmlEmail += "<td style='text-align: right;width:100px;'>" + this.dollarUSLocale.format(itemValue) + "</td>"
                        }
                        total += itemValue
                    })
                    if (total < 0)  {
                            htmlEmail += "<td style='color: rgb(255, 87, 51);text-align: right;width:100px;'>" + this.dollarUSLocale.format(total) + "</td>"
                    } else {
                        htmlEmail += "<td style='text-align: right;width:100px;'>" + this.dollarUSLocale.format(total) + "</td>"
                    }
                    htmlEmail += "</tr>"

                    total = 0
                    htmlEmail += "<tr style='background-color: rgb(235, 235, 235);'>"
                    htmlEmail += "<td style='border-left: 2px; text-align: left;width: 250px;'>Actual Cash Onhand</td>"
                    this.dailyShifts.forEach((item) => {
                        htmlEmail += "<td style='text-align: right;width:100px;'>" + this.dollarUSLocale.format(item.cashActualOnhand) + "</td>"
                        total += parseFloat((item.cashActualOnhand ? item.cashActualOnhand : 0))
                    })
                    htmlEmail += "<td style='border-right: 1px; text-align: right;width: 100px;'>" + this.dollarUSLocale.format(total) + "</td>"
                    htmlEmail += "</tr>"                

                    total = 0
                    htmlEmail += "<tr style='background-color: rgb(235, 235, 235);'>"
                    htmlEmail += "<td style='border-left: 2px; text-align: left;width: 250px;'>Over/Short</td>"
                    this.dailyShifts.forEach((item,idx) => {
                        var shiftcash = this.shiftCashOverShort(idx)
                        var itemValue = (shiftcash ? shiftcash  : 0)
                        if (itemValue < 0)  {
                            htmlEmail += "<td style='color: rgb(255, 87, 51);text-align: right;width:100px;'>" + this.dollarUSLocale.format(itemValue) + "</td>"
                        } else {
                            htmlEmail += "<td style='text-align: right;width:100px;'>" + this.dollarUSLocale.format(itemValue) + "</td>"
                        }
                        total += itemValue
                    })
                    if (total < 0)  {
                        htmlEmail += "<td style='color: rgb(255, 87, 51);text-align: right;width:100px;'>" + this.dollarUSLocale.format(total) + "</td>"
                    } else {
                        htmlEmail += "<td style='text-align: right;width:100px;'>" + this.dollarUSLocale.format(total) + "</td>"
                    }
                    htmlEmail += "</tr>"

                    htmlEmail += "</table></div>"  
                    
                    htmlEmail += "<br>Email Sent from <a href='https://www.veecli.com'><h3>VEECLi Software</h3></a><br>"
                    htmlEmail += "Gas Station Accounting and Tank Monitoring Software"

                    this.sendEmail(
                        emailAddresses,
                        subject,
                        htmlEmail)
                }
            } else {
                console.log("No email")
            }
        },

        cancelEdit() {
            this.fetchStoreShiftSales()
            this.overrideAllowed = false
            UserStore.commit("setIsEditing",false)
        },

        cancelEditOld() {
            this.$buefy.dialog.confirm({
                title: 'Discard changes!',
                message: 'All changes to the shift will be lost.  Are you sure?',
                type: 'is-danger',
                hasIcon: true,
                cancelText: "Keep Changes",
                confirmText: "Discard Changes",
                onConfirm: () => {
                    this.fetchStoreShiftSales()
                    UserStore.commit("setIsEditing",false)
                    this.$buefy.toast.open({
                        message: 'Shift changes have been discarded and loaded saved data', 
                        type: 'is-info'
                    })
                }
            })
        },

        tabDataChanged(index) {
            this.editableFields.forEach((field) => {
                if (this.shiftsBeforeUpdate[index][field] != this.dailyShifts[index][field]) {
                    UserStore.commit("setIsEditing",true)
                }
            })
        },
        
        leavingPage() {
            this.$buefy.dialog.confirm({
                message: 'Leave Shift Page?',
                onConfirm: () => this.$buefy.toast.open('User confirmed')
            })
        },

        allowOnlyNumbers(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault()
            } else {
                return true;
            }
        },

        helpTooltip(key) {
            var head, body
            switch (key) {
                case "shiftDate":
                    head="Shift Date"
                    body="Select the Shift Date"
                    break
                case "hideZeroShift":
                    head="Hide Zero Shift"
                    body="Hide the Shift with Zero Sales"
                    break
                case "showRegisterData":
                    head="Show Register Data"
                    body="You hide the right panel with Register data"
                    break                                                    
            }

            return '<div class="notification is-info is-light"><div class="is-small has-text-centered is-capitalized">'+head+'</div><hr>'+body+'</div>'
        },

        togglePagination() {
            this.isPaginated = !this.isPaginated
        },

        startPrinting() {
            this.isPaginated = false
            this.isPrinting = true
            this.incomeTableOpen = true
            this.expenseTableOpen = true
            this.lotteryTableOpen = true

            this.sleep(2000).then(() => {
                this.endPrinting()
            })            
        },

        endPrinting() {
            this.isPaginated = true
            this.isPrinting = false
            this.incomeTableOpen = false
            this.expenseTableOpen = false
            this.lotteryTableOpen = false
        },

        sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        }, 
        
        printDaysReport() {
            this.togglePDFDialog()
        },
        
        togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                this.$refs.pdfReport.loadData()
            }
        },         

        // generatePDF() {
        //     this.startPrinting()

        //     const html = document.querySelector("#ReportContent")
        //     let srcwidth = html.scrollWidth

        //     const doc = new jspdf('p','pt','a4')

        //     doc.html(html, {
        //         html2canvas: {
        //             scale: 595.26 /srcwidth,
        //             scrollY: 0
        //         },
        //         filename: 'pandlreport',
        //         x: 0,
        //         y: 10,
        //         callback: function(pdf) {
        //             window.open(pdf.output("bloburl"))
        //         }
        //     })

        // },

        datePickerToggle() {
            this.hideDate = ""
            this.hideDateButton = "is-hidden"
            this.$refs.shiftDatePicker.toggle()
        },

        beforeLeavingPage(event) {
       
            if (UserStore.getters.getIsEditing) {
                
                event.preventDefault()
                event.returnValue = ""
            }
        },

        unlockClicked() {
            this.$buefy.dialog.prompt({
                message: `Enter Password?`,
                inputAttrs: {
                    placeholder: 'e.g. Walter',
                    type: 'password',
                    maxlength: 10
                },
                trapFocus: true,
                onConfirm: (value) => {
                    if (value == this.getSetting('ownerOverridePassword')) {
                        this.overrideAllowed = true
                        this.$buefy.toast.open({
                            message: "Unlocked Cigarette Counts",
                            type: 'is-success'
                        })
                    } else {
                        this.overrideAllowed = false
                        this.$buefy.toast.open({
                            message: "Incorrect Password",
                            type: 'is-danger'
                        })
                    }
                }

            })
        },

        shiftCashOverShort(index) {

            var filteredArray = this.dailyShifts[index].additionalIncomes.filter(item => (item.paymentMethod == "Cash"))
            var cashAddIncome = filteredArray.reduce((accum,item) => accum + parseFloat(item.amount), 0)

            var filteredExpenses = this.dailyShifts[index].expenses.filter(item => (item.payments.length > 0))
            var totCashExp = 0
            filteredExpenses.forEach(expense => {
                var cashExpArray = expense.payments.filter(item => (item.method == "Cash"))
                totCashExp = totCashExp + cashExpArray.reduce((accum, item) => accum + parseFloat(item.amount), 0)
            })

            // return  this.getNumber(this.dailyShifts[index].cashActualOnhand) +
            //         this.getNumber(this.dailyShifts[index].checkActualOnhand) -
            return  this.getNumber(this.dailyShifts[index].cashActualOnhand) -
                    (this.getNumber(this.dailyShifts[index].cashAmount) - 
                    this.getNumber(this.dailyShifts[index].safedropCashAmount) -
                    this.getNumber(this.dailyShifts[index].cashRefundAmount) +
                    this.getNumber(this.dailyShifts[index].onlineLottoSales) -
                    this.getNumber(this.dailyShifts[index].onlineLottoPayout) +
                    this.getNumber(this.dailyShifts[index].instantLottoSales) -
                    this.getNumber(this.dailyShifts[index].onlineLotteryCanceled) -
                    this.getNumber(this.dailyShifts[index].instantLottoPayout) +
                    this.getNumber(this.dailyShifts[index].lotteryMachineWithdraw) -
                    this.getNumber(this.dailyShifts[index].checkActualOnhand) +
                    this.getNumber(cashAddIncome) -
                    this.getNumber(totCashExp))
                    
        },
 
    },

    watch: {
        shiftDate: function() {
            this.hideDate = "is-hidden"
            this.hideDateButton = ""
            this.fetchStoreShiftSales()
        },

        hideZeroShift: function() {
            this.fetchStoreShiftSales()
            // if (this.hideZeroShift) {
            //     this.removeZeroShift()
            // }
        },

    },

    computed: {
        allowEdit() {
            if (UserStore.getters.getActiveStore.role == 'STAFF') {
                if (this.shiftDate < this.minShiftDate) {
                    return false
                }
            } 
            return true
        },

        overridePassword() {
          if (UserStore.getters.getStoreSettingsValue('ownerOverridePassword')) {
            return UserStore.getters.getStoreSettingsValue('ownerOverridePassword').value
          } else {
            return null
          }
        },

        getSelectedShiftEmployeeName() {
            var employee = this.employees.find((item) => item.id === this.dailyShifts[this.activeTab].employeeId)
            console.log(employee,this.dailyShifts[this.activeTab].employeeId)
            if (employee) {
                return employee.firstName + " " + employee.lastName
            } else {
                return "N/A"
            }
        },

        storeName() {
           return UserStore.getters.getActiveStore.storeName
        },

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

        safeDropAmount() {
            return (this.getNumber(this.dailyShifts[this.activeTab].totalPaymentOut) - this.getNumber(this.dailyShifts[this.activeTab].payOut)) * -1
        },

        netLottery() {
            return this.getNumber(this.dailyShifts[this.activeTab].onlineLottoSales) -
                    this.getNumber(this.dailyShifts[this.activeTab].onlineLottoPayout) +
                    this.getNumber(this.dailyShifts[this.activeTab].instantLottoSales) -
                    this.getNumber(this.dailyShifts[this.activeTab].onlineLotteryCanceled) -
                    this.getNumber(this.dailyShifts[this.activeTab].instantLottoPayout) +
                    this.getNumber(this.dailyShifts[this.activeTab].lotteryMachineWithdraw)
        },

        cashBookBalance() {
            return this.getNumber(this.dailyShifts[this.activeTab].cashAmount) - 
                    this.getNumber(this.dailyShifts[this.activeTab].safedropCashAmount) -
                    this.getNumber(this.dailyShifts[this.activeTab].cashRefundAmount) +
                    this.getNumber(this.netLottery) +
                    this.getNumber(this.cashAdditionalIncome) -
                    this.getNumber(this.cashExpensePayments)
                    
        },
        
        cashOverOrShort() {
            return (this.getNumber(this.dailyShifts[this.activeTab].cashActualOnhand) +
                    this.getNumber(this.dailyShifts[this.activeTab].checkActualOnhand)) -
                    this.getNumber(this.cashBookBalance).toFixed(2)
        },

        cashOnHandOrSafe() {
            return this.getNumber(this.dailyShifts[this.activeTab].cashActualOnhand) +
                    this.getNumber(this.dailyShifts[this.activeTab].checkActualOnhand) -
                    this.getNumber(this.safeDropAmount) -
                    this.getNumber(this.dailyShifts[this.activeTab].bankDepositAtm) -
                    this.getNumber(this.dailyShifts[this.activeTab].bankDepositGeneral) -
                    this.getNumber(this.dailyShifts[this.activeTab].bankDepositLottery) -
                    this.getNumber(this.dailyShifts[this.activeTab].cashLoadedInAtm)
        },

        cashAdditionalIncome() {

            var filteredArray = this.dailyShifts[this.activeTab].additionalIncomes.filter(item => (item.paymentMethod == "Cash"));
            var total = filteredArray.reduce((accum,item) => accum + parseFloat(item.amount), 0)
            return total

        },

        nonCashAdditionalIncome() {

            var filteredArray = this.dailyShifts[this.activeTab].additionalIncomes.filter(item => (item.paymentMethod != "Cash"));
            var total = filteredArray.reduce((accum,item) => accum + parseFloat(item.amount), 0)
            return total

        },

        totalAdditionalIncome() {
            return this.nonCashAdditionalIncome + this.cashAdditionalIncome
        },

        cashExpensePayments() {

            var filteredExpenses = this.dailyShifts[this.activeTab].expenses.filter(item => (item.payments.length > 0))
            var total = 0.00
            filteredExpenses.forEach(expense => {
                var noncash = expense.payments.filter(item => (item.method == "Cash"))
                total = total + noncash.reduce((accum, item) => accum + parseFloat(item.amount), 0)
            })

            return total

        },

        cashierOverShort() {
            var variance = parseFloat(this.dailyShifts.cashBalance) + parseFloat(this.dailyShifts.netLottery) - parseFloat(this.dailyShifts.cashActualOnhand)
            if (Number(variance)) {
                return variance
            }
            return 0
        },

        cigsCountBalance() {

            // {{ (parseFloat(shift.previousCigSales.closeCount) +  parseFloat(shift.cigSales.buyCount) - parseFloat(shift.cigDeptSales.netSalesItemCount))  }}

            return this.getNumber(this.dailyShifts[this.activeTab].previousCigSales.closeCount) + 
                    this.getNumber(this.dailyShifts[this.activeTab].cigSales.buyCount) -
                    this.getNumber(this.dailyShifts[this.activeTab].cigaretteSaleCount)
        },        

        cigsCountVariance() {
            return this.getNumber(this.dailyShifts[this.activeTab].cigSales.closeCount) -
                    this.getNumber(this.cigsCountBalance) 
        },

        isEditing() {
            return UserStore.getters.getIsEditing
        },

        savePaneRow1() {
            return moment(this.shiftDate).format("MM/DD/YYYY") +" - SHIFT: " +  1
        }

    },

    mounted() {
    
        this.user = this.getUser()

        this.trackInstantLotteryBooks = UserStore.getters.getTrackInstantLotteryBooks
        if (this.trackInstantLotteryBooks.value === null) this.trackInstantLotteryBooks.value = 1

        var storeUser = this.user.storeUsers.filter(item => (item.storeId == this.user.activeStore));

        this.userRole = storeUser[0].role
        // this.userRole = 'STAFF'

        this.shiftDate = new Date()


        this.fetchEmployees()
        this.fetchVendors()
        this.fetchExpenseTypes()
        this.minShiftDate = new Date(Date.now() - ( 3600 * 1000 * 24 * 90))
        if (UserStore.getters.getActiveStore.role === "STAFF") {
            if (UserStore.getters.getShiftStaffAllowDays.value) {
                this.minShiftDate = new Date(Date.now() - ( 3600 * 1000 * 24 * parseInt(UserStore.getters.getShiftStaffAllowDays.value)))
            } else {
                this.minShiftDate = new Date(Date.now() - ( 3600 * 1000 * 24 * 3))                
            }
        }
        if (UserStore.getters.getActiveStore.role === "ADMIN") {
            if (UserStore.getters.getShiftManagerAllowDays.value) {
                this.minShiftDate = new Date(Date.now() - ( 3600 * 1000 * 24 * parseInt(UserStore.getters.getShiftManagerAllowDays.value)))
            } else {
                this.minShiftDate = new Date(Date.now() - ( 3600 * 1000 * 24 * 30))                
            }
        }
    },

    created () {

        window.addEventListener('beforeunload',this.beforeLeavingPage);

    },
    
    beforeDestroy () {

        window.removeEventListener('beforeunload',this.beforeLeavingPage);

    },   
}
</script>

<style>
.veecli-table {
    border-bottom: 0.5px solid gray;
    padding-bottom: 2.5em;
    height: 2em;
}
.veecli-form-entry {
    border-bottom: 0.5px solid gray;
    padding-bottom: 4.25em;
    height: 2em;
}
.veecli-form-entry-with-label {
    /* border-bottom: 0.5px solid gray; */
    padding-bottom: 0em;
    /* height: 2em; */
}
.veecli-table-heading {
    border-bottom: 0.5px;
    padding-bottom: 2.5em;
    height: 2em;
    background-color: #f2f2f2d7;
    font-weight: bold;
    font-size: larger;
    text-align: center;
    vertical-align: middle;
}
.veecli-table-subhead {
    border-bottom: 0.5px;
    padding-bottom: 2.5em;
    height: 2em;
    background-color: #f2f2f2;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
}
.veecli-table-footer {
    border-bottom: 0.5px;
    padding-bottom: 2.5em;
    height: 2em;
    background-color: #f2f2f2;
    font-weight: bold;
    vertical-align: middle;
}
.number-input {
  text-align: right; 
}
/* Remove Number Spinner Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove Number Spinner Firefox */
input[class=number-input] {
  text-align: right;
}

.padding-30-top {
   padding-top: 30px;
}

.v-popper--tooltip-loading .v-popper__inner {
  color: #77aaff;
}

.number-only-input input[type=number][disabled] {
    background-color: whitesmoke;
    font-weight:normal;
    color: gray;
    border-color: gray;
    border-width: 1px;
}

.save-buttons-overlay {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 10;
  top: 0;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.help-tooltip-header {
  background: #2084d6;
  color: rgb(255, 255, 255);
  margin: 5px;
  border: 2px;
  font-size: 0.75em;
  text-justify: auto;
  text-align: center;
}

.help-tooltip-content {
  background: #cce5ff;
  color: darkblue;
  margin: 5px;
  border: 2px;
  text-justify: auto;
  text-align: center;
}

.row-even-background {
    background-color: rgb(249, 249, 249);
}

.shift-notes-textarea {
    margin: 10px;
}

.underlined-div {
    background-color: rgb(10, 188, 10);
}

/* .save-bottons-div {
    position: fixed;
    top: 4em;
    right: 3em;
    z-index: 20;
    background-color: #edf0f2;
    border: 1px solid rgb(213, 211, 211);
    border-width: 3px;
    margin: 10px;
    padding: 10px;
    border-radius: 25px;
    box-shadow: 1px 1px 1px rgb(145, 144, 144);
    text-align: center;
}

.save-div-item {
    text-align: center;
    margin: 10px;
} */

</style>