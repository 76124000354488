<template>
<article class="columns">
  <section class="column">
    <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowDialog }">
    <div class="modal-background"></div>
    <div class="modal-card scrollable" style="width: auto">
        <!-- <form action="" class="box" @submit.prevent="submitForm"> -->
        <header class="modal-card-head">
        <p class="modal-card-title has-text-centered"><span v-if="!expense.expenseTypeId">ADD </span><span v-else>MODIFY </span>EXPENSE</p>
        <button class="delete is-danger" type="button" @click="cancelForm">Cancel</button>
        </header>
        <section class="modal-card-body">

                <div class="columns">
                    <div class="column">
                        <span class="has-text-weight-bold">Date: {{ expense.dueOn | formatDate }}</span>
                    </div>
                    <div class="column">
                        <span  class="has-text-weight-bold">Shift: {{ shiftNum }}</span>
                    </div>
                
                </div>

                <div class="columns">
                    <div class="column">
                        <!-- <b-field label="Type of Expense/Payment" label-for="expense.expenseType"> -->
                            Expense/Payment Type: 
                            <tool-tip v-if="!expense.expenseTypeId > 0">
                                <div slot="title" class="help-tooltip-header">
                                Expense Type:
                                </div>
                                <div slot="content" class="help-tooltip-content">
                                    Select the Expense Type. <p>Cannot be empty.</p>
                                </div>
                                <span>&nbsp;<i class="fas fa-info-circle has-text-warning"></i></span>
                            </tool-tip>
                            <b-select class="veecli-select" v-model="expense.expenseTypeId">
                                <option v-for="item in expenseTypes" :key="item.id" :value="item.id">{{ item.name }}</option>
                            </b-select>
                        <!-- </b-field> -->
                    </div>
                    <div class="column">
                        <div v-if="expense.expenseTypeId == '2'">
                            Inventory Department: 
                            <tool-tip v-if="expense.expenseTypeId == '2' && !expense.deptMapping > 0">
                                <div slot="title" class="help-tooltip-header">
                                Inventory Department:
                                </div>
                                <div slot="content" class="help-tooltip-content">
                                    Select the Inventory Department. <p>Cannot be empty.</p>
                                </div>
                                <span>&nbsp;<i class="fas fa-info-circle has-text-warning"></i></span>
                            </tool-tip>
                            <b-select class="veecli-select" v-model="expense.deptMapping">
                                <option v-for="item in storeMappings" :key="item.value" :value="item.value">{{ item.name }}</option>
                            </b-select>                    
                            
                        </div>
                    </div>
                </div>

                <!-- <div class="columns"  v-if="expense.expenseTypeId == '1' || expense.expenseTypeId == '139' || expense.expenseTypeId == '140'"> -->
                    <div class="columns"  v-if="isPayrollExpenseType(expense.expenseTypeId)">
                        <div class="column">
                        <div required label="Employee" label-for="employeeId">
                            Employee: 
                            <tool-tip v-if="isPayrollExpenseType(expense.expenseTypeId) && !expense.employeeId > 0">
                                <div slot="title" class="help-tooltip-header">
                                Inventory Department:
                                </div>
                                <div slot="content" class="help-tooltip-content">
                                    Select the Inventory Department. <p>Cannot be empty.</p>
                                </div>
                                <span>&nbsp;<i class="fas fa-info-circle has-text-warning"></i></span>
                            </tool-tip>
                            <b-select class="veecli-select" v-model="expense.employeeId" @input="calculatePayroll" >
                                <option v-for="employee in employees" :key="employee.id" :value="employee.id">{{ toTitleCase(employee.firstName) }} {{ toTitleCase(employee.lastName) }}</option>
                            </b-select>
                        </div>
                    </div>

                    <div class="column is-vcentered" v-if="expense.expenseTypeId == '1'">
                        <b-field required label="Rate" label-for="rate">
                         Hourly {{ employeePayRate |formatCurrency }}
                        </b-field>
                    </div>
                </div>

                <div class="columns"  v-if=" ! isPayrollExpenseType(expense.expenseTypeId)">
                    <div class="column">
                        <div>
                            Vendor: 
                            <tool-tip v-if="! isPayrollExpenseType(expense.expenseTypeId) && !expense.vendorId > 0">
                                <div slot="title" class="help-tooltip-header">
                                Vendor:
                                </div>
                                <div slot="content" class="help-tooltip-content">
                                    Select the Vendor. <p>Cannot be empty.</p>
                                </div>
                                <span>&nbsp;<i class="fas fa-info-circle has-text-warning"></i></span>
                            </tool-tip>
                            <b-select class="veecli-select" v-model="expense.vendorId"  v-if="expense.expenseTypeId != '1'">
                                <option v-for="vendor in vendors" :key="vendor.id + Math.random()" :value="vendor.id">{{ vendor.name }}</option>
                            </b-select>
                        </div>
                    </div>
                </div>    

                <div class="columns">

                    <div class="column" v-if="expense.expenseTypeId == '1'">

                        Hours Worked: 
                        <tool-tip v-if="expense.expenseTypeId == '1' && expense.employeePayrollHours == 0">
                            <div slot="title" class="help-tooltip-header">
                            Hours Worked:
                            </div>
                            <div slot="content" class="help-tooltip-content">
                                Hours Worked. <p>Cannot be 0.</p>
                            </div>
                            <span>&nbsp;<i class="fas fa-info-circle has-text-warning"></i></span>
                        </tool-tip>
                        <VeeInput
                            type="number" 
                            v-model="expense.employeePayrollHours"
                            name="employeePayrollHours"
                            :required="expense.employeePayrollHours == 0"
                            :value="expense.employeePayrollHours"
                            :editable="trueValue"
                            @input="calculatePayroll" 
                            />
                    </div>
                            <!-- 
                                <b-field required label="Hours" label-for="employeePayrollHours" v-if="expense.expenseTypeId == '1'"> 

                                <b-input  
                                    @focus="$event.target.select()"
                                    custom-class="has-text-right" 
                                    type="text" step="any" 
                                    v-model="expense.employeePayrollHours"
                                    @input="calculatePayroll" 
                                    >
                            </b-input> -->
                        <!-- </b-field>  -->

                    <div class="column" v-if="expense.expenseTypeId == '2' && (expense.deptMapping == 'Fuel')">
                        Fuel Volume: 
                            <tool-tip v-if="expense.deptMapping == 'Fuel' && expense.expenseTypeId == '2' && expense.fuelVolume == 0">
                                <div slot="title" class="help-tooltip-header">
                                Fuel Volume:
                                </div>
                                <div slot="content" class="help-tooltip-content">
                                    Fuel Volume. <p>Cannot be 0.</p>
                                </div>
                                <span>&nbsp;<i class="fas fa-info-circle has-text-warning"></i></span>
                            </tool-tip>
                            <VeeInput
                                type="number" 
                                v-model="expense.fuelVolume"
                                name="fuelVolume"
                                :required="expense.fuelVolume == 0"
                                :value="expense.fuelVolume"
                                :editable="trueValue"
                                @input="calculatePayroll" 
                                />
                        <!-- <b-field required label="Volume" label-for="fuelVolume" v-if="expense.deptMapping == 'Fuel' && expense.expenseTypeId == '2'">
                            <b-input  
                                    @focus="$event.target.select()"
                                    custom-class="has-text-right" 
                                    type="text" step="any" 
                                    required
                                    v-model="expense.fuelVolume"
                                    >
                            </b-input>
                        </b-field>   -->
                    </div>
                    <div class="column">
                        <!-- <b-field label="Amount" label-for="expense.amount"> -->
                            <!-- <b-input @focus="$event.target.select()"
                                    :disabled="expense.expenseTypeId == '1'" 
                                    custom-class="has-text-right" 
                                    type="text" step="any"
                                    required
                                    validation-message="Please enter Amoount" 
                                    v-model="expense.amount">
                            </b-input> -->
                            Total Amount: 
                            <tool-tip v-if="expense.amount == 0">
                                <div slot="title" class="help-tooltip-header">
                                Total Amount:
                                </div>
                                <div slot="content" class="help-tooltip-content">
                                    Total Expense/Inventory Invoice Amount. <p>Cannot be 0.</p>
                                </div>
                                <span>&nbsp;<i class="fas fa-info-circle has-text-warning"></i></span>
                            </tool-tip>
                            <VeeInput
                                type="currency" 
                                v-model="expense.amount"
                                name="expenseAmount"
                                :required="expense.amount == 0"
                                :value="expense.amount"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="trueValue"
                                />

                        <!-- </b-field> -->
                        <!-- <div class="has-text-danger is-size-7" v-if="expense.amount==0">Expense Amount is Required</div>                        -->
                    </div>
                </div>
                <!-- <b-field label="Invoice #/Description" label-for="expense.description">

                    <b-input required type="text" v-model="expense.description"></b-input>

                </b-field> -->

                Invoice #/Description:
                    <tool-tip v-if="expense.description == ''">
                        <div slot="title" class="help-tooltip-header">
                        Expense/Inventory Invoice #/Description
                        </div>
                        <div slot="content" class="help-tooltip-content">
                            Enter Expense/Inventory Invoice # or Description. <p>Cannot be empty.</p>
                        </div>
                        <span>&nbsp;<i class="fas fa-info-circle has-text-warning"></i></span>
                    </tool-tip>
                    <VeeInput
                        type="textarea" 
                        v-model="expense.description"
                        name="expensedescription"
                        :required="expense.description == ''"
                        :value="expense.description"
                        :editable="trueValue"
                        />
                <br>
                <div class="block">
                    <b-radio v-model="expense.payments[0].method"
                        name="creditTerm"
                        native-value="Cash">
                        Paid Cash
                    </b-radio>                   
                    <b-radio v-model="expense.payments[0].method"
                        name="creditTerm"
                        native-value="Check">
                        Paid Check
                    </b-radio>
                    <b-radio v-model="expense.payments[0].method"
                        name="creditTerm"
                        native-value="EFT">
                        EFT
                    </b-radio>
                    <!-- <b-radio v-model="expense.payments[0].method"
                        name="creditTerm"
                        native-value="CCDC">
                        Credit/Debit Card
                    </b-radio>  -->
                </div>

                <div class="columns"  v-if="expense.payments[0].method != 'Cash' && expense.payments[0].method != 'Not Paid'">
                    <div class="column is-2">
                        <b-field label="Cleared" label-for="dueOn">
                            <b-switch v-model="expense.payments[0].status"  v-if="expense.payments[0].method != 'Cash' && expense.payments[0].method != 'Not Paid'" 
                                true-value="Cleared"
                                @input="expenseCleared">
                            </b-switch>
                        </b-field>
                    </div>
                    <div class="column is-5">
                        <!-- <b-field label="Check/Due Date" label-for="dueOn"> -->
                            Date Expected at Bank:
                            <tool-tip v-if="expense.description == ''">
                                <div slot="title" class="help-tooltip-header">
                                    Date Expected
                                </div>
                                <div slot="content" class="help-tooltip-content">
                                    Date Check/EFT expected to clear at the Bank. <p>Cannot be empty.</p>
                                </div>
                                <span>&nbsp;<i class="fas fa-info-circle has-text-warning"></i></span>
                            </tool-tip>
                            <b-datepicker v-model="expense.payments[0].expectedClearingDateObj"
                                custom-class="date-required-field"
                                :first-day-of-week="1"
                                position="is-top-right"
                                locale="en-US"
                                placeholder="Click to select...">
                            </b-datepicker>
                            <!-- <b-input v-model="expense.dueOn"></b-input> -->
                             <!-- :disabled="expense.payments[0].status=='Cleared'" -->

                        <!-- </b-field> -->
                    </div>
                    <div class="column is-5">
                        Payment Ref #/Check #: 
                            <VeeInput
                                type="text" 
                                v-model="expense.payments[0].referenceNumber"
                                name="referenceNumber"
                                :value="expense.payments[0].referenceNumber"
                                :editable="trueValue"
                                />
                        <!-- <b-field label="Payment Ref#/Check#" label-for="referenceNumber" v-if="expense.payments[0].method != 'Cash' && expense.payments[0].method != 'Not Paid'">

                             <b-input required type="text" v-model="expense.payments[0].referenceNumber"></b-input>

                        </b-field>                       -->
                    </div>
                </div>

        </section>

        <footer class="modal-card-foot">
        <button class="button is-success" type="button" @click="submitForm" :disabled='isDisabled'>Save</button>
        <button class="button" type="button" @click="cancelForm">Cancel</button>
        <span class='is-danger'></span>
        </footer>
    <!-- </form> -->

    </div>
    </div>
  </section>
</article>
</template>


<script>

// import axios from 'axios'
import UserStore from "../../../../store/UserStore"
import moment from 'moment'
import VeeInput from "../../../../components/app/VeeInput.vue"
import ToolTip from '../../../../components/app/ToolTip'

export default {
    props: {
        shiftNum: Number,
        expense: {},
        shift: {},
        vendors: [],
        employees: [],
        expenseTypes: [],
        expenseBefore: {},
        date:{},
        action: String,
        isShowDialog: Boolean
    },

    components: {
        VeeInput,
        ToolTip
    },

    data() {
        return {
            trueValue: true,
            falseValue: false,
            email: '',
            role: '',
            dateoptions: { year: 'numeric', month: 'short', day: 'numeric' },
            openOnFocus: true,
            keepFirst: true,
            clearable: false,
            data: [],
            isFetching: false,
            vendor: {},
            vendorName: '',
            employeeName: '',
            employeePayrollHours: 0.00,
            FuelVolume: 0.000,
            employeePayRate: 0.00,
            employeeFirstName:'',
            showNumberControls: false,
            name: '',
            page: 1,
            totalPages: 1,
            paymentMethod: "Cash",
            user: [],
            creditNotAllowed: false,
            countryCurrencyCode: UserStore.getters.getCountryCurrencyCode,
            referenceNumber: '',
            // deptMappings: UserStore.getters.getDeptMappings,
            storeMappings: UserStore.getters.getStoreMappings,

        }
    },

    methods: {
    
        closeDialog() {
            this.vendor = {}
            this.vendorName = ""
            this.$emit('hide-dialog')
            this.email = ""
            this.role = ""
        },

        cancelForm() {
            this.expense.vendorsId = this.expenseBefore.vendorId
            this.expense.expenseTypeId = this.expenseBefore.expenseTypeId
            this.expense.deptMapping = this.expenseBefore.deptMapping
            this.expense.employeeId = this.expenseBefore.employeeId
            this.expenseTypes.employeePayrollHours = this.expenseBefore.employeePayrollHours
            this.expense.description = this.expenseBefore.description
            this.expense.amount = this.expenseBefore.amount
            this.expense.creditTerm  = this.expenseBefore.creditTerm
            this.expense.date  = this.expenseBefore.date
            
            this.expense.fuelVolume  = this.expenseBefore.fuelVolume
            if (typeof this.expenseBefore.payments !== 'undefined' && this.expenseBefore.payments.length > 0) {
                this.expense.payments[0].expectedClearingDate = this.expenseBefore.payments[0].expectedClearingDate
                this.expense.payments[0].referenceNumber = this.expenseBefore.payments[0].referenceNumber
                this.expense.payments[0].amount = this.expenseBefore.payments[0].amount
                this.expense.payments[0].userId = this.expenseBefore.payments[0].userId
                this.expense.payments[0].method = this.expenseBefore.payments[0].method
                this.expense.payments[0].status = this.expenseBefore.payments[0].status
                this.expense.payments[0].clearedOn = this.expenseBefore.payments[0].clearedOn
                this.expense.payments[0].paymentDate = this.expenseBefore.payments[0].paymentDate
            // } else {
            //     this.expense.payments  = []
            }
            // if (this.expenseBefore.payments !== undefined) {
            //     this.expense.payments  = this.expenseBefore.payments
            // }
            // this.expense = this.expenseBefore            
            this.closeDialog()
        },

        submitForm() {

            if (this.expense.expenseTypeId == 25 && this.shift.creditCardFees > 0.00) {
                this.$buefy.toast.open({
                    message: "Please use Credit/Debit Charges option from left menu",
                            type: 'is-danger'
                }) 
                return
            }             

            if (! this.isPayrollExpenseType(this.expense.expenseTypeId)) {
                this.expense.employeeId = null
                this.expense.employeePayrollHours = null
            } else {
                this.expense.vendorId = null
            }

            if (this.expense.expenseTypeId != 2) {
                this.expense.deptMapping = null
            }

            // if (this.expense.deptMapping != 'Fuel') {
            //     this.expense.fuelVolume = null
            // }
            if (this.expense.expenseTypeId != 2) {
                this.expense.fuelVolume = null
            } else {
                this.expense.expenseTypeName = "Inventory"
            }

            if (this.expense.payments.length > 0) {
                switch (this.expense.payments[0].method) {
                    case "Cash": {
                        this.expense.creditTerm = 'NET0'
                        this.expense.dueOn = this.date.toISOString().slice(0, 10) 
                        this.expense.payments[0].paymentDate = this.expense.dueOn 
                        this.expense.payments[0].status = "Paid"
                        this.expense.payments[0].expectedClearingDate = null
                        this.expense.payments[0].clearedOn = this.expense.dueOn
                        this.expense.payments[0].amount = this.expense.amount
                        this.expense.payments[0].clearedAmount = this.expense.amount
                        // this.expense.payments[0].date = this.date
                        // console.log("Due",this.expense.dueOn, this.date)
                        break
                    }

                    case "Check": {
                        this.expense.creditTerm = 'NET30'
                        this.expense.dueOn = this.expense.payments[0].expectedClearingDateObj.toISOString().slice(0, 10) 
                        this.expense.payments[0].paymentDate = this.date.toISOString().slice(0, 10) 
                        this.expense.payments[0].amount = this.expense.amount
                        if (this.expense.payments[0].status != 'Cleared') {
                            this.expense.payments[0].status = "Open"
                            this.expense.payments[0].expectedClearingDate = this.expense.dueOn
                            this.expense.payments[0].clearedOn = null
                            this.expense.payments[0].clearedAmount = null
                        } else {
                            this.expense.payments[0].expectedClearingDate = this.expense.dueOn
                            this.expense.payments[0].clearedOn = this.expense.dueOn
                            this.expense.payments[0].clearedAmount = this.expense.amount
                        }
                        break
                    }

                    case "EFT": {
                        this.expense.creditTerm = 'NET30'
                        this.expense.dueOn = this.expense.payments[0].expectedClearingDateObj.toISOString().slice(0, 10) 
                        this.expense.payments[0].paymentDate = this.date.toISOString().slice(0, 10) 
                        this.expense.payments[0].amount = this.expense.amount
                        this.expense.payments[0].clearedAmount = null
                        if (this.expense.payments[0].status != 'Cleared') {
                            this.expense.payments[0].status = "Open"
                            this.expense.payments[0].expectedClearingDate = this.expense.dueOn
                            this.expense.payments[0].clearedOn = null
                            this.expense.payments[0].clearedAmount = null
                        } else {
                            this.expense.payments[0].expectedClearingDate = this.expense.dueOn
                            this.expense.payments[0].clearedOn = this.expense.dueOn
                            this.expense.payments[0].clearedAmount = this.expense.amount
                        }
                        break
                    }
                }                
            }
            this.$emit('save-record')
            this.closeDialog()

        },

        alertMessage(message) {
            alert(message)
        },

        parseDate(stringDate) {
            return new Date(stringDate)
        },
        
        dateToMysql(dt) {
            return dt.getFullYear() + '-' + String(dt.getMonth() + 1).padStart(2, '0') + '-' + String(dt.getDate()).padStart(2, '0')
        },

        dateFormatter(dt){
            return dt.toLocaleDateString('en-US', this.dateoptions);
        },

        calculatePayroll() {
            var employee = this.employees.filter(item => (item.id == this.expense.employeeId))
            if (employee[0]) {
                this.employeePayRate = employee[0].rate
            } else {
                this.employeePayRate = 0
            }
            
            this.expense.amount = this.employeePayRate  * this.expense.employeePayrollHours
        },

        expenseCleared() {
            if (this.expense.payments[0].status=='Cleared') {
                if (this.expense.date !== undefined) {
                    this.expense.payments[0].expectedClearingDateObj=moment(this.expense.date, 'YYYY-MM-DD').toDate()
                } else {
                    if (this.expense.dueOn !== undefined) {
                        this.expense.payments[0].expectedClearingDateObj=moment(this.expense.dueOn, 'YYYY-MM-DD').toDate()
                    } else {
                        this.expense.payments[0].expectedClearingDateObj=new Date()
                    }
                }
                
            }
        },

        isPayrollExpenseType(expId) {
            return UserStore.getters.getIsPayrollExpenseType(expId)
        }

    },

    watch: {
        date() {

        }


    },

    computed: {

        isDisabled() {

            if (
                this.date &&
                (this.expense.expenseTypeId != '1' && this.expense.expenseTypeId != '139' && this.expense.expenseTypeId != '140') ? this.expense.vendorId : true &&
                this.expense.expenseTypeId &&
                this.expense.creditTerm &&
                this.expense.dueOn
                ) 

                    {

                        if (this.expense.description == "") {
                            return true
                        }

                        if (this.expense.amount == 0.00) {
                            return true
                        }

                        if (this.expense.expenseTypeId === 1) {
                            if (this.expense.employeeId < 1 || this.expense.employeePayrollHours < 1 ) {
                                return true
                            }                     
                        }

                        if (this.isPayrollExpenseType(this.expense.expenseTypeId)) {
                            if (this.expense.employeeId < 1 ) {
                                return true
                            }                     
                        }

                        if (this.expense.expenseTypeId === 2) {
                            if (this.expense.deptMapping == "" || this.expense.deptMapping == null) {
                                return true
                            }

                            if (this.expense.deptMapping == "Fuel" && this.expense.fuelVolume < 1) {
                                return true
                            }
                        }

                        if (this.expense.payments.length > 0) {
                            if (this.expense.payments[0].method != 'Cash') {

                                if (this.expense.payments[0].expectedClearingDateObj == null || this.expense.payments[0].referenceNumber == "") {
                                    return true
                                } else {
                                    return false
                                }

                            } else {
                                return false
                            }
                        } else {
                            return false
                        }

                    
                    } else {
                        return true
                    }

        },

        isAmountNonZero() {
            if (this.expense.amount != 0.00) {
                return false
            }
            return true
        },        

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },        

        filteredVendors() {
            return this.vendors.filter(option => {
                return (
                    option.name
                        .toString()
                        .toLowerCase()
                        .indexOf(this.vendorName.toLowerCase()) >= 0
                )
            })
        },       

        filteredEmployees() {

            return this.employees.filter(option => {
                var fullName = option.firstName + " " + option.lastName
                return (
                    (fullName)
                        .toString()
                        .toLowerCase()
                        .indexOf(this.employeeName.toLowerCase()) >= 0
                )
            })
        }, 

        fullName(employee) {
            return employee.firstName + ' ' + employee.lastName
        },  

    },

    mounted() {
        this.user = this.getUser()
        // this.deptMappings = UserStore.getters.getDeptMappings
    }

}
</script>

<style>
.date-required-field {
    font-weight:bold;
    text-align: center;
    font-size: 1.0em;
    font-size-adjust: calc(500%);
    color: rgb(0, 0, 0);
    width: 7em;
    max-width: 100%;
    height: 30px;
    border-width: 1px;
    padding-right: 6px;
    border-color: rgb(255, 73, 7);
    border-style:solid;
    box-shadow: 1px 1px 6px rgb(234, 210, 199);
    background-color: rgb(250, 250, 242);

}

.veecli-select select { 
    /* Whatever color  you want */
    background-color: rgb(250, 250, 242);
}

/* 
.scrollable {
    max-height: 200vw;
    width: 400px;
    overflow: auto; 
} */



</style>